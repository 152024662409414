import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

import { resetPatient, setInfoSection, setSection } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';

// Components 

import Box from '@mui/material/Box';
import { getPatientFolderByPatientId } from '../../../../../../routes/doctor/files';
import { setSearchSection } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setDashboardSection } from '../../../../../../redux/features/general/navigation';
import { setEditedPatient } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

type Props = {
    children?: React.ReactNode,
    iconColor?: string,
    section: string
    hoverColor: string,

};

const OptionRow: React.FC<Props> = ({ children, iconColor, section, hoverColor,  }) => {

  const dispatch = useAppDispatch(); 

  const colors = useAppSelector(state => state.theme.colors);
  const infoSection = useAppSelector(state => state.patient.infoSection); 
  const patient = useAppSelector(state => state.patient.patient); 

  // -- 

  const handleAction = async () => { 

      if (section === 'overview') { 

        dispatch(resetPatient()); 

        return; 

      };

      if (patient) { 

        if (section === 'info') { 

          dispatch(setInfoSection('info')); 

        } else if (section === 'editPatient') { 

          dispatch(setEditedPatient(patient));
          dispatch(setSection('createPatient'));

        } else if (section === 'files') { 

          const res = await getPatientFolderByPatientId(patient._id) as any; 

          if (res.data.status === 200) { 

              dispatch(setSearchSection(res.data.folder?._id)); 

              dispatch(setDashboardSection('documents')); 

          }; 

        } else if (section === 'notes') { 

          dispatch(setInfoSection('notes')); 

        } else if (section === 'directives') { 

          dispatch(setInfoSection('directives')); 

        } else if (section === 'patientLogs') { 

          dispatch(setInfoSection('patientLogs')); 

        } else if (section === 'settings') { 

          dispatch(setInfoSection('settings'));

        }; 

      }; 

  }; 

  // -- 

  return (

    <Box 
      onClick={handleAction}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: 50,
        height: 50,
        fontSize: 25, 
        marginTop: 2,
        borderRadius: '50%',
        cursor: patient ? 'pointer' : 'default',
        color: patient ? iconColor : colors?.sidebarUnselectedItem,
        backgroundColor: (patient && (infoSection === section)) ? hoverColor : 'transparent', 
        '&:hover': { backgroundColor: patient ? hoverColor : 'transparent' }
    }}>

        {children}

    </Box>
  );
};

export default OptionRow;