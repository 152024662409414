import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface NoteState {
  note: any
}

// ---------------

const initialState: NoteState = {
  note: null,
};

// ---------------


export const adminNoteSlice = createSlice({

  name: 'adminNote',

  initialState,

  reducers: {

    setNote: (state, action: PayloadAction<any>) => {
        state.note = action.payload
    },
    setResetNote: (state) => { 
        
      state = initialState; 

    } 
  },

}); 

// ---------------

export const { setNote, setResetNote } = adminNoteSlice.actions;


export default adminNoteSlice.reducer;