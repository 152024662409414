import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface RefreshFilesState {
  refreshFiles: boolean
}

// ---------------

const initialState: RefreshFilesState = {
  refreshFiles: false,
};

// ---------------


export const refreshFilesSlice = createSlice({

  name: 'refreshFiles',

  initialState,

  reducers: {

    refreshFiles: (state, action: PayloadAction<boolean>) => {
        state.refreshFiles = action.payload
    },
  },

}); 

// ---------------

export const { refreshFiles } = refreshFilesSlice.actions;


export default refreshFilesSlice.reducer;