import React, { useState, useEffect} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

import { setTemplatesSection } from '../../../../../../redux/features/doctor/Dashboard/templates/templates';

// Components 

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';


type Props = {
    index: number,
    number: number;
};

const TemplateExample: React.FC<Props> = ({ index, number }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const handleNavigation = () => { 

        dispatch(setTemplatesSection('createTemplate'));

    };

  return (

    <>

    { index <= 2 &&
    
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 150,
            height: 200,
            paddingTop: 2, 
            paddingBottom: 2, 
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 5,
            marginLeft: 2, 
            cursor: 'pointer',
            color: colors?.templateBoxText,
            fontSize: 12,
            '&:hover': { backgroundColor: colors?.templateHover, color: colors?.templateTextHover }
        }}>
        <Box
            component="img"
            sx={{
            height: '100%',
            width: '100%',
            borderRadius: 2,
            }}
            alt="Template Preview"
            src={require(`../../../../../../assets/templates/template_${number}.png`)}
        />
        <div style={{ marginTop: 2 }}>{`Example ${number}`}</div>

    </Box>}

    { index === 3 && 
    
        <Box 
            onClick={handleNavigation}
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 130,
            height: 200,
            paddingTop: 2, 
            paddingBottom: 2, 
            paddingLeft: 1,
            paddingRight: 1,
            borderRadius: 5,
            marginLeft: 3,
            cursor: 'pointer',
            color: colors?.templateBoxText,
            fontSize: 12,
        }}>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    minHeight: '95%',
                    width: 130,
                    borderStyle: 'dashed',
                    borderWidth: 1, 
                    borderColor: colors?.templateBoxBorder,
                    borderRadius: 4, 
                    marginBottom: 2,
                    color: colors?.templateBoxIcon,
                    '&:hover': { backgroundColor: colors?.templateHover, color: colors?.templateTextHover }
                }}
            >
                <AddCircleOutlineIcon sx={{ fontSize: 30 }} /> 
            </Box>

            <Box sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '5%'}}>{`Create`}</Box>

        </Box>}

    </>
  );
};

export default TemplateExample;