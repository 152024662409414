import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface NotificationState {
  message: string
}

// ---------------

const initialState: NotificationState = {
  message: '',
};

// ---------------


export const notificationSlice = createSlice({

  name: 'notification',

  initialState,

  reducers: {

    setNotificationMessage: (state, action: PayloadAction<string>) => {
        state.message = action.payload
    },
    setResetNotification: (state) => { 
        
      state = initialState; 

    } 
  },

}); 

// ---------------

export const { setNotificationMessage, setResetNotification } = notificationSlice.actions;


export default notificationSlice.reducer;