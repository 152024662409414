import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { useTranslation } from 'react-i18next';
import { setNotificationMessage } from '../../../../redux/features/general/notification';
import { setIsRefreshPatientsList, setSelectedPatient } from '../../../../redux/features/hacker/users/patientsAccess';

// Routes

import { deletePatient } from '../../../../routes/hacker/users';

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NotesIcon from '@mui/icons-material/Notes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  positionX: number,
  positionY: number,
};


const Dropdown: React.FC<Props> = ({positionX, positionY }) => {

    const { t } = useTranslation(); 

    const dispatch = useAppDispatch(); 

    const selectedPatient = useAppSelector((state) => state.patientsAccess.selectedPatient); 

    const [isLoading, setIsLoading] = useState(false); 

    // -- 

    const handleDeletion = async () => { 

        setIsLoading(true); 

        const res = await deletePatient(selectedPatient._id); 

        setIsLoading(false); 

        if (res?.data.status === 200) { 

            dispatch(setIsRefreshPatientsList(true)); 
            dispatch(setSelectedPatient(null));
            dispatch(setNotificationMessage('Patient account successfully deleted.')); 

        }; 

    };  
    
    // -- 
    
    return (


    <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 200,
            left: positionX - 170, 
            top: positionY, 
            position: 'absolute',
            backgroundColor: 'rgb(82,84,89)',
            borderRadius: 4,
            cursor: 'pointer',
            zIndex: 5, 
        }}
    >

            <Box
                    onClick={handleDeletion}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 50,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.7)'}
                    }}
                >

                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                    >
                        { isLoading ? <CircularProgress /> : <DeleteOutlineIcon />}  
                        <Box sx={{ marginLeft: 3 }}>Delete Patient</Box>
                </Box>

            </Box>

        </Box>

    ); 

};

export default Dropdown;
