import React from 'react';
import Box from '@mui/material/Box';
import ContactPageIcon from '@mui/icons-material/ContactPage';

// Components 

import CurrentData from './documentStates/CurrentData';
import ArchivedData from './documentStates/ArchivedData';

const TeamNumbersOverview: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '95%',
        height: '55%', 
        borderRadius: 5,
        backgroundColor: '#313338',
    }}>

        <CurrentData />
        
        <ArchivedData />

    </Box>

  );

};

export default TeamNumbersOverview;