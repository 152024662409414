import { CompressOutlined } from '@mui/icons-material';
import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// Utils 

// ---------------
 
interface CreatePatientState {

    // Meta 

    categories: string[],
    section: string,
    completedFields: string[], 
    completedCategories: string[],
    incompletedCategories: string[], 
    erroredCategories: string[], 
    erroredFields: string[], 
    requiredFields: string[], 
    nextSection: string,
    previousSection: string,
    sectionSelected : boolean

    // If In Edit mode 

    editedPatient: any, 
    editedPatientChange: boolean

    // Patient Info

    firstName: string,
    lastName: string,
    gender: any,
    dateOfBirth: string,

    // Contact Info 

    healthCardNumber: string,
    medicalHistory: string,
    phone: string,
    email: string, 
    address: string, 

    // Emergency Contact 

    emergencyContactName: string, 
    emergencyContactRelation: string, 
    emergencyContactPhone: string, 

    // Health Information 

    currentMedications: string,     // Previously an array 

    // Medical History 

    familyMedicalHistory: string,  // Previously an array 
    mentalHealthHistory: string,  // Previously an array 
    allergies: string,  // Previously an array 
    bloodType: string, 
    knownConditions: string, // Previously an array 
    surgeries: string, // Previously an array 
    immunizations: string, // Previously an array 
    geneticDisorders: string, // Previously an array 
    disabilities: string, // Previously an array 

    // Insurance Details 

    provider: string,
    policyNumber: string,
    groupNumber: string, 

    // Social History 

    occupation: string,
    maritalStatus: string,
    housingStatus: string,
    financialStatus: string,
    supportSystem: string,

    // Lifestyle 

    smokingStatus: string,
    alcoholConsumption: string,
    exerciseFrequency: string,
    diet: string,

    // Extra Information x

    languagePreference: any;
    primaryDoctor: string;
    advancedDirectives: string;   // Previously an array 
    notes: string;

};

// ---------------

const initialState: CreatePatientState = {

    // Meta 

    categories: [],
    section: '',
    completedFields: [], 
    completedCategories: [],
    incompletedCategories: [], 
    erroredCategories: [], 
    erroredFields: [], 
    requiredFields: [], 
    previousSection: '',
    nextSection: '',
    sectionSelected : false,

    editedPatient: null, 
    editedPatientChange: false, 

    // Patient Info

    firstName: '', 
    lastName: '',
    gender: null, 
    dateOfBirth: '',

    // Contact Info

    healthCardNumber: '', 
    medicalHistory: '', 
    phone: '', 
    email: '', 
    address: '', 

    // Emergency Contact

    emergencyContactName: '', 
    emergencyContactRelation: '', 
    emergencyContactPhone: '', 

    // Health Information 

    currentMedications: '', // Previously an array 

    // Medical History 

    familyMedicalHistory: '',  // Previously an array 
    mentalHealthHistory: '',  // Previously an array 
    allergies: '',  // Previously an array 
    bloodType: '', 
    knownConditions: '', // Previously an array 
    surgeries: '', // Previously an array 
    immunizations: '', // Previously an array 
    geneticDisorders: '', // Previously an array 
    disabilities: '', // Previously an array 

    // Insurance Details 

    provider: '',
    policyNumber: '',
    groupNumber: '',

    // Social History 

    occupation: '',
    maritalStatus: '',
    housingStatus: '',
    financialStatus: '',
    supportSystem: '',

    // Lifestyle

    smokingStatus: '',
    alcoholConsumption: '',
    exerciseFrequency: '',
    diet: '',

    // Extra Information 

    languagePreference: '',
    primaryDoctor: '',
    advancedDirectives: '',  // Previously an array 
    notes: '',
    
};

// ---------------

export const createPatientSlice = createSlice({

  name: 'createPatient',

  initialState,

  reducers: {

    setSectionSelected : (state, action: PayloadAction<any>) => {

        state.sectionSelected = action.payload; 

    },

    setEditedPatient : (state, action: PayloadAction<any>) => {

        state.editedPatient = action.payload; 

    },
    setEditedPatientChange : (state, action: PayloadAction<any>) => {

        state.editedPatientChange = action.payload; 

    },
    setCategories: (state, action: PayloadAction<any>) => {

        state.categories = action.payload; 
        state.section = action.payload[0]; 
        state.nextSection = action.payload[1]; 

    },
    setSection: (state, action: PayloadAction<any>) => {

        state.section = action.payload; 

        const currentSectionIndex = state.categories.indexOf(action.payload); 

        if (currentSectionIndex === 0) { 

            state.previousSection = ''; 
            state.nextSection = state.categories[1]; 

        } else if (currentSectionIndex === (state.categories.length - 1)) { 

            state.previousSection = state.categories[currentSectionIndex -1]; 
            state.nextSection = 'confirmation'; 
        
        } else { 

            if (action.payload === 'confirmation') { 

                state.previousSection = state.categories[state.categories.length - 1]; 
                state.nextSection = ''; 

            } else { 

                state.previousSection = state.categories[currentSectionIndex -1]; 
                state.nextSection = state.categories[currentSectionIndex + 1]; 

            }; 

        }; 

    },
    setCompletedField: (state, action: PayloadAction<any>) => {

        !state.completedFields.includes(action.payload) && state.completedFields.push(action.payload); 
    },
    setCompletedCategory: (state, action: PayloadAction<any>) => {

        !state.completedCategories.includes(action.payload) && state.completedCategories.push(action.payload); 

    },
    removeCompletedCategory: (state, action: PayloadAction<string>) => {

        const currentCompletedCategories = state.completedCategories; 

        const categoryToBeRemoved = currentCompletedCategories.indexOf(action.payload); 

        if (categoryToBeRemoved > -1) { 

            currentCompletedCategories.splice(categoryToBeRemoved, 1); 

            state.completedCategories = currentCompletedCategories;

        };

    },
    setIncompletedCategory: (state, action: PayloadAction<any>) => {

        !state.incompletedCategories.includes(action.payload) && state.incompletedCategories.push(action.payload); 

    },
    removeIncompletedCategory: (state, action: PayloadAction<string>) => {

        const currentIncompletedCategories = state.incompletedCategories; 

        const categoryToBeRemoved = currentIncompletedCategories.indexOf(action.payload); 

        if (categoryToBeRemoved > -1) { 

            currentIncompletedCategories.splice(categoryToBeRemoved, 1); 

            state.incompletedCategories = currentIncompletedCategories; 

        };

    },
    setErroredCategory: (state, action: PayloadAction<any>) => {

        !state.erroredCategories.includes(action.payload) && state.erroredCategories.push(action.payload); 

    },
    removeErroredCategory: (state, action: PayloadAction<string>) => {

        const currentErroredCategories = state.erroredCategories; 

        const categoryToBeRemoved = currentErroredCategories.indexOf(action.payload); 

        if (categoryToBeRemoved > -1) { 

            currentErroredCategories.splice(categoryToBeRemoved, 1); 

            state.erroredCategories = currentErroredCategories; 

        };

    },
    setErroredField: (state, action: PayloadAction<any>) => {

        !state.erroredFields.includes(action.payload) && state.erroredFields.push(action.payload); 

    }, 
    removeErroredField: (state, action: PayloadAction<string>) => {

        const currentErroredFields = state.erroredFields; 

        const fieldToBeRemoved = currentErroredFields.indexOf(action.payload); 

        if (fieldToBeRemoved > -1) { 

            currentErroredFields.splice(fieldToBeRemoved, 1); 

            state.erroredFields = currentErroredFields; 

        };

    },
    setFirstName: (state, action: PayloadAction<any>) => {

        state.firstName = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('firstName') && state.completedFields.push('firstName'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'firstName') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

            // t.splice(t.indexOf('B'), 1); // will return ['B'] and t is now equal to ['A', 'C', 'B'], removing only one occurence. 

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('firstName'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setLastName: (state, action: PayloadAction<any>) => {

        state.lastName = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('lastName') && state.completedFields.push('lastName'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'lastName') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('lastName'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setGender: (state, action: PayloadAction<any>) => {

        state.gender = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('gender') && state.completedFields.push('gender'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'gender') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('gender'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setDateOfBirth: (state, action: PayloadAction<any>) => {

        state.dateOfBirth = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('dateOfBirth') && state.completedFields.push('dateOfBirth'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'dateOfBirth') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('dateOfBirth'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setHealthCardNumber: (state, action: PayloadAction<any>) => {

        state.healthCardNumber = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('healthCardNumber') && state.completedFields.push('healthCardNumber'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'healthCardNumber') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('healthCardNumber'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setPhone: (state, action: PayloadAction<any>) => {

        state.phone = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('phone') && state.completedFields.push('phone'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'phone') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('phone'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setEmail: (state, action: PayloadAction<any>) => {

        state.email = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('email') && state.completedFields.push('email'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'email') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('email'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setAddress: (state, action: PayloadAction<any>) => {

        state.address = action.payload; 
    
        if (action.payload) { 

            !state.completedFields.includes('address') && state.completedFields.push('address'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'address') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };
        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('address'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setEmergencyContactName: (state, action: PayloadAction<any>) => {

        state.emergencyContactName = action.payload; 
    
        if (action.payload) { 

            !state.completedFields.includes('emergencyContactName') && state.completedFields.push('emergencyContactName'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'emergencyContactName') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('emergencyContactName'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setEmergencyContactRelation: (state, action: PayloadAction<any>) => {

        state.emergencyContactRelation = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('emergencyContactRelation') && state.completedFields.push('emergencyContactRelation'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'emergencyContactRelation') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('emergencyContactRelation'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setEmergencyContactPhone: (state, action: PayloadAction<any>) => {

        state.emergencyContactPhone = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('emergencyContactPhone') && state.completedFields.push('emergencyContactPhone'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'emergencyContactPhone') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('emergencyContactPhone'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setProvider: (state, action: PayloadAction<any>) => {

        state.provider = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('provider') && state.completedFields.push('provider'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'provider') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('provider'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setPolicyNumber: (state, action: PayloadAction<any>) => {

        state.policyNumber = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('policyNumber') && state.completedFields.push('policyNumber'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'policyNumber') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('policyNumber'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setGroupNumber: (state, action: PayloadAction<any>) => {

        state.groupNumber = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('groupNumber') && state.completedFields.push('groupNumber'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'groupNumber') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('groupNumber'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setLanguagePreference: (state, action: PayloadAction<any>) => {

        state.languagePreference = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('languagePreference') && state.completedFields.push('languagePreference'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'languagePreference') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('languagePreference'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setPrimaryDoctor: (state, action: PayloadAction<any>) => {

        state.primaryDoctor = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('primaryDoctor') && state.completedFields.push('primaryDoctor'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'primaryDoctor') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('primaryDoctor'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setAdvancedDirectives: (state, action: PayloadAction<any>) => {

        state.advancedDirectives = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('advancedDirectives') && state.completedFields.push('advancedDirectives'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'advancedDirectives') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('advancedDirectives'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setNotes: (state, action: PayloadAction<any>) => {

        state.notes = action.payload; 

        if (action.payload) { 

            !state.completedFields.includes('notes') && state.completedFields.push('notes'); 

            let currentErroredFields = state.erroredFields; 

            let erroredFieldIndex = -1; 

            state.erroredFields.map((item: any, index) => { 

                if (item.field === 'notes') { 

                    erroredFieldIndex = index; 
                };

            }) as any; 
    
            if (erroredFieldIndex > -1) { 
    
                currentErroredFields.splice(erroredFieldIndex, 1); 
    
                state.erroredFields = currentErroredFields; 
    
            };

        } else { 

            const currentCompletedFields = state.completedFields; 

            const fieldToBeRemoved = currentCompletedFields.indexOf('notes'); 
    
            if (fieldToBeRemoved > -1) { 
    
                currentCompletedFields.splice(fieldToBeRemoved, 1); 
    
                state.completedFields = currentCompletedFields; 
    
            };

        }; 

    },
    setResetCreatePatient: (state) => { 
        
        state.categories = []; 
        state.section = ''; 
        state.completedFields = []; 
        state.completedCategories = [];     
        state.incompletedCategories = []; 
        state.erroredCategories = [];     
        state.erroredFields = [];
        state.sectionSelected = false;

        state.editedPatient = null; 
        state.editedPatientChange = false; 

        state.firstName = ''; 
        state.lastName = ''; 
        state.gender = null; 
        state.dateOfBirth = ''; 

        state.healthCardNumber = ''; 
        state.phone = '';
        state.email = ''; 
        state.address = '';

        state.emergencyContactName = '';
        state.emergencyContactRelation = ''; 
        state.emergencyContactPhone = '';

        state.provider = ''; 
        state.policyNumber = ''; 
        state.groupNumber = ''; 

        state.languagePreference = null; 
        state.primaryDoctor = ''; 
        state.advancedDirectives = '';   // Still uncertain
        state.notes = ''; 

    } 
  },

}); 

// ---------------

export const { setSectionSelected, setEditedPatient, setEditedPatientChange, setCategories, setSection, setCompletedCategory, setIncompletedCategory, 
    setErroredCategory, removeCompletedCategory, setErroredField, 
    removeIncompletedCategory, removeErroredCategory, removeErroredField, 
    setFirstName,  setLastName, setDateOfBirth, 
    setGender, setResetCreatePatient, setHealthCardNumber, 
    setPhone, setEmail, setAddress,
    setEmergencyContactName, setEmergencyContactRelation, setEmergencyContactPhone,
    setProvider, setPolicyNumber, setGroupNumber, setLanguagePreference, setPrimaryDoctor,
    setAdvancedDirectives, setNotes, setCompletedField  } = createPatientSlice.actions;

export default createPatientSlice.reducer;