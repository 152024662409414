import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setSupportTicketDescription } from '../../../../redux/features/general/createSupportTicket';

// Components 

import TextField from '@mui/material/TextField';

const Category: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const description = useAppSelector(state => state.createSupportTicket.description);

  const [text, setText] = useState(''); 

  // Redux 

  useEffect(() => {

    dispatch(setSupportTicketDescription(text)); 

  },[text]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginBottom: '3vh',
    }}>

        <TextField
            sx={{
                width: '50%',
                backgroundColor: '#27292b'
            }}
            onChange={(e) => setText(e.target.value)}
            id="outlined-multiline-static"
            label='Add a description to your ticket'
            multiline
            value={description}
            rows={4}
        />

    </Box>

  );

};

export default Category;