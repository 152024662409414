import React from 'react';
import Box from '@mui/material/Box';
import LanguageSelector from './LanguageSelector';
import ThemeToggle from './ThemeToggle';

type Props = {
    children?: React.ReactNode;
};
  

const ItemsList: React.FC<Props> = ({ children }) => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
        height: '100%',
        top: 0,
      }}
    >
      {children}
    </Box>
  );
};

export default ItemsList;