import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';


// Components 

import OptionsList from './OptionsList';
import Button from '@mui/material/Button';

// Redux 

type TitleProps = {
    children: React.ReactNode, 
    icon: React.ReactNode,
    iconColor: string, 
    isMultiline?: boolean, 
    required?: boolean, 
    dataList?: any, 
    action?: string,
    isFocused?: boolean, 
    value?: any, 
    error: string,
    isDisabled?: boolean
};

const InputRow: React.FC<TitleProps> = ({ children, icon, iconColor, isMultiline, required, dataList, action, isFocused, value, error, isDisabled }) => {

    const { t } = useTranslation();

    const [isOptionsListOpened, setIsOptionsListOpened] = useState(false); 

    const colors = useAppSelector(state => state.theme.colors);

    const editedPatient = useAppSelector(state => state.createPatient.editedPatient);  

    useEffect(() => { 

        if (value) { 

            setIsOptionsListOpened(false); 

        }; 

    },[value]); 

    return (

        <>

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            width: '100%',
            minHeight: isMultiline? 150 : 60, 
            borderRadius: 2,
            marginBottom: error ? 1 : 3,
            overflowY: 'hidden',
            backgroundColor: isDisabled ? colors?.createPatientDisabledIcon : colors?.createPatientEnabledIcon,
            color: iconColor ? iconColor: colors?.text,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',  
        }}>
            {icon}
            {children}

            { dataList && 

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Button 
                        onClick={() => setIsOptionsListOpened(true)}
                        sx={{ 
                            textTransform: 'none',
                            width: 'auto', 
                            height: 40, 
                            paddingLeft: 2, 
                            paddingRight: 2, 
                            marginRight: 2,
                            backgroundColor: colors?.createPatientSelect }} 
                        variant="contained">{t('select')}
                    </Button>
                
                </Box>}

            { (required && !editedPatient) &&
                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row', 
                        alignItems: 'center', 
                        position: 'relative',
                        marginRight: 2, 
                        color: error ? colors?.createPatientRequired : colors?.text,
                        fontSize: 12,
                        zIndex: 10
                    }}
                >Required</Box>}

                {/* { isDisabled && 
                <Box 
                    sx={{ 
                        width: '100%', 
                        height: 60, 
                        borderRadius: 3,
                        backgroundColor: 'rgba(46, 45, 46, 0.7)', 
                        // position: 'absolute', 
                    }}
                >
                </Box>} */}

           </Box>

        { error && 
            <Box 
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    width: '100%',
                    fontSize: 12, 
                    marginBottom: 3,
                    color: colors?.createPatientError
                }}
                >{t(error)}
            </Box>}

        {(dataList && isOptionsListOpened) && <OptionsList dataList={dataList} action={action} />}

        </>
  );
};

export default InputRow;