import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';

// Components 

import Box from '@mui/material/Box';
import { serveFile } from '../../routes/doctor/files';
import { getTemplateTextFields, saveTemplate, serveTemplate } from '../../routes/doctor/templates';
import getPdfUrl from '../../util/template/getPdfUrl';
import { setCurrentPdfBytes, setCurrentPdfUrl, setInitialRendering, setIsModificationLoading, setTemplateId, setFields, setTextFields } from '../../redux/features/template/template';
import Sidebar from './sidebar/Sidebar';
import Navbar from './navbar/Navbar';
import Document from './document/Document';
import EventLimiter from '../../util/events/EventLimiter';

// -- 

const Container: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 
    const navigate = useNavigate(); 

    const { templateId } = useParams() as any; 

    const initialRendering = useAppSelector(state => state.template.initialRendering); 

    const [timeLeft, setTimeLeft] = useState(0); 

    // -- 
    
    const handleTemplateData = async () => {

      dispatch(setTemplateId(templateId)); 

      const template = await serveFile(templateId, 'template') as any; 
    
      const fileBlob = new Blob([template.data], { type: template.headers['content-type'] });
  
      dispatch(setCurrentPdfUrl((URL.createObjectURL(fileBlob)))); 

      const res = await getTemplateTextFields(templateId) as any; 

      if (res.data.status === 200) { 

        dispatch(setTextFields(res.data.templateTextFields));

      }; 

    }; 

    // --

    useEffect(()=>{

      let myInterval = setInterval(() => {

          if (timeLeft > 0) {

              setTimeLeft(timeLeft - 1);

          }; 

          if (timeLeft === 1) { 

              handleTemplateData(); 

          }; 

          if (timeLeft === 0) {

              if (timeLeft === 0) {
                  clearInterval(myInterval)
              };
          }; 

      }, 300); 

      return ()=> {
          clearInterval(myInterval);
      };

    },[timeLeft]);

    // --

    useEffect(() => { 

      if (initialRendering) { 

        dispatch(setIsModificationLoading(true)); 

        setTimeLeft(2);  
        
        dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // -- 

    return (

      <Box
        sx={{ 
          width: '100%', 
          height: '100%', 
        }}
      >

        <Sidebar /> 

        <Document />

        <Navbar /> 

      </Box>

  );

};

export default Container;