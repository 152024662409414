import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { getNumberOfPatientsByDate } from '../../../../routes/hacker/users';

// Components 

import Box from '@mui/material/Box';
import { LineChart } from '@mui/x-charts/LineChart';
import EventLimiter from '../../../../util/events/EventLimiter';
import CircularProgress from '@mui/material/CircularProgress';
import { setPatientsChartXAxis, setPatientsChartData } from '../../../../redux/features/hacker/data/hackerData';

const PatientsChart: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const data = useAppSelector(state => state.hackerData.data); 
    const patientsChartData = useAppSelector(state => state.hackerData.patientsChartData); 
    const patientsChartXAxis = useAppSelector(state => state.hackerData.patientsChartXAxis); 

    const [allow, setAllow] = useState(false); 

    // -- 

    const getDate = async (date: any) => { 

        const res = await getNumberOfPatientsByDate(date) as any;  

        if (res.data.status === 200) { 

            dispatch(setPatientsChartData(res.data.accountsTotal)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (data && patientsChartData) { 

            if ((data?.xAxis.length === 14) && (patientsChartData.length === 0)) { 

                data?.xAxis.map((item, index) => { 
    
                    dispatch(setPatientsChartXAxis(item.slice(0, 5))); 
    
                }); 
    
                data?.xAxis.forEach(async (date) => { 
    
                    try {
            
                        await EventLimiter.schedule(() => getDate(date));
                
                    } catch (err) {
                        console.log(err);
                    };
            
                });
    
            }; 

        };

    },[data, patientsChartData]); 

    // -- 

    useEffect(() => { 

        if ((patientsChartData?.length === 14) && (patientsChartXAxis?.length === 14)) { 

            setAllow(true); 

        }; 

    },[patientsChartData, patientsChartXAxis]); 

    // --

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            marginTop: 8,
        }}>

            { (data && allow) ?  
            <LineChart
                width={window.innerWidth * 0.7}
                height={300}
                series={[
                { data: patientsChartData, label: 'Patients', color: 'rgb(45, 207, 62)' },
                ]}
                xAxis={[{ scaleType: 'point', data: patientsChartXAxis }]}
            />:<CircularProgress size={60}/>}

        </Box>

  );

};

export default PatientsChart;