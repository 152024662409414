import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface NotificationsState {
  notifications: any[], 
  currentPatientId: any, 
  isRefreshNotifications: boolean, 
  initialRendering: boolean, 
};

// ---------------

const initialState: NotificationsState = {
  notifications: [], 
  currentPatientId: null, 
  isRefreshNotifications: false,  
  initialRendering: true, 
};

// ---------------

export const notificationsSlice = createSlice({

  name: 'notifications',

  initialState,

  reducers: {

    setNotifications: (state, action: PayloadAction<any>) => {

      state.notifications = action.payload; 

    },
    setCurrentPatientId: (state, action: PayloadAction<any>) => {

      state.currentPatientId = action.payload; 

    }, 
    setIsRefreshNotifications: (state, action: PayloadAction<boolean>) => {

        state.isRefreshNotifications = action.payload; 
  
    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetNotifications: (state) => { 
        
        state.notifications = [];

        state.currentPatientId = null; 
        state.initialRendering = true; 

        // Refreshers purposely omitted. 

    } 
  },

}); 

// ---------------

export const { 
    setNotifications, 
    setCurrentPatientId, 
    setIsRefreshNotifications, 
    setInitialRendering, 
    resetNotifications
 } = notificationsSlice.actions;

export default notificationsSlice.reducer;