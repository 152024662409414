import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 


// --- 

export const getAvailableTeams = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/createUser/teams`, getConfig()); 

        if (res.status === 200) { 

            const availableTeams = res.data.availableTeams as Array<object>; 

            return availableTeams; 
              
        };

    } catch (error) {
        console.error('Error getting a list of available teams:', error);
    };

};

// -- 



export const createUser = async (firstName: string, lastName: string, email: string, team: string, accountType: string) => {

    try {

        const content = { 
            firstName: firstName,
            lastName: lastName,
            email: email,
            team: team,
            accountType: accountType
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/createUser`, content, getConfig()); 

        if (res.status === 200) { 

            const newUser = res.data.newUser as Array<object>; 

            const response = { 
                status: 200, 
                newUser: newUser
            }; 

            return response; 
              
        };

    } catch (error) {
        console.error('Error creating a team:', error);
    };

};

// --

export const getAllUserAccess = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/users`, getConfig()); 

        if (res.status === 200) { 

            const users = res.data.users as Array<object>; 

            return users; 
              
        };

    } catch (error) {
        console.error('Error getting a list of all users access:', error);
    };

};

// --

export const revokeUserAccess = async (employeeId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/users/revoke/${employeeId}`, getConfig()); 

        if (res.status === 200) { 

            return res.status; 
              
        };

    } catch (error) {
        console.error('Error getting a list of all users access:', error);
    };

};