import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

import CssBaseline from '@mui/material/CssBaseline';

// Components

import NavBar from '../../general/navBar/NavBar';
import Sidebar from '../../general/navigation/Sidebar';

// Redux 

import { setSideBarMenu } from '../../../redux/features/general/navigation';

// Admin sidebar menu 

import menuItems from '../../../util/navigation/sidebarMenu/admin';

// Navbar

import Settings from '../navigation/navBar/Settings';

// Inner pages 

import DocumentInterface from './DocumentInterface';
import TeamInterface from './TeamInterface';
import CreateTeamInterface from './CreateTeamInterface';
import UsersInterface from './UsersInterface';
import CreateUserInterface from './CreateUserInterface';
import Notification from '../../general/Notification';

const Container: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const section = useAppSelector(state => state.navigation.section);

  useEffect(() => { 

    dispatch(setSideBarMenu(menuItems)); 

  },[]); 

  return (

    <React.Fragment>

        <CssBaseline />

        <Box component="main" sx={{ 
            display: 'flex', 
            height: '100vh', 
            width: '100vw',
            left: 0, 
            overflowX: 'hidden',
            position: 'absolute',
            color: 'white' }}>

          {/* General Components */}

          <NavBar>
            <Settings />
          </NavBar>
          
          <Sidebar />

          { (section === 'documents' || section!) && <DocumentInterface />}

          { section === 'teams' && <TeamInterface />}

          { section === 'createTeam' && <CreateTeamInterface />}
          
          { section === 'createUser' && <CreateUserInterface />}
          
          { section === 'users' && <UsersInterface />}

          <Notification />

        </Box>

    </React.Fragment>

  );

};

export default Container;