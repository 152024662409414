import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components 

import TextField from '@mui/material/TextField';

interface Props { 
    title?: string
}

const ListHeader: React.FC<Props> = ({title}) => {

  const usersListLength = useAppSelector(state => state.adminUserAccess.usersListLength); 

  return (

      <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '7.5vh',
        width: '100%',
        paddingLeft: '1%',
        backgroundColor: '#2B2D31',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        padding: '0 1vw',
    }}>

      <>

        <div style={{  width: '13%', marginLeft: '2%' }}>User</div>

        <Box sx={{ width: '20%' }}>Status</Box>

        <Box sx={{ width: '20%' }}>Email</Box>

        <Box sx={{ width: '35%' }}>Creation</Box>

        <Box sx={{ width: '10%' }}>More</Box>

      </>


  </Box>

  );

};

export default ListHeader;