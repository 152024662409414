import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../redux/hooks';

// Routes 

import { getFiles } from './../../../../routes/admin/documents';

// Redux 

import { setFile } from './../../../../redux/features/admin/dashboard/documents/file';
import { refreshFiles } from './../../../../redux/features/admin/dashboard/documents/refreshFiles';

// Icons 

import DescriptionIcon from '@mui/icons-material/Description';

const DocsList: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const file = useAppSelector(state => state.adminFile.file); 
    const refreshFilesList = useAppSelector(state => state.adminRefreshFiles.refreshFiles); 

    const [files, setFiles] = useState(Array<any>);

    // -- 

    const handleGetFiles = async () => { 
      
      const filesList = await getFiles() as Array<any>;  

      setFiles(filesList); 

    }; 

    const selectFile = (file: any) => { 

      dispatch(setFile(file)); 

    }; 

    // -- 

    useEffect(() => { 

      handleGetFiles();   

    },[]);

    useEffect(() => { 

      if (refreshFilesList) { 

        handleGetFiles(); 

        dispatch(refreshFiles(false)); 

      };

    },[refreshFilesList]); 
      
    return (

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '20%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#313338',
      }}>
  
          { files?.length >= 1 && 
          <Box sx={{ 
            overflow: 'auto',
            width: '95%',
            marginTop: '2vh' }}>
    
            <List dense>
    
              {files?.map((item, index) => (
    
                <ListItem onClick={() => selectFile(item)} button key={index}>
    
                    { file?._id === item._id ? 
                      <ListItemIcon>
                        <DescriptionIcon sx={{ color: '#0AC5CF' }} />
                      </ListItemIcon>:
                      <ListItemIcon>
                        <DescriptionIcon sx={{ color: '#C2C5C8' }} />
                      </ListItemIcon>}
    
                    { file?._id === item._id ? 
                    <ListItemText sx={{ color: '#0AC5CF' }} primary={item.title} />:
                    <ListItemText sx={{ color: '#C2C5C8' }} primary={item.title} />}
    
                </ListItem>
              ))}
    
            </List>
    
          </Box>} 

          {files?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '30%',
              width: '100%',
              fontSize: 15,
              color: '#C2C5C8'
            }}>

              <DescriptionIcon sx={{ fontSize: 50 }} />

              <div>No files detected.</div>

          </Box>}


        </Box>
    );

};

export default DocsList;