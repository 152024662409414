import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import {  addTextField, setIsCreatingTextField } from '../../../../../redux/features/template/template';

// Routes

import { createTemplateTextField } from '../../../../../routes/doctor/templates';

// Components 

import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const CreateForm: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const isCreatingTextField = useAppSelector(state => state.template.isCreatingTextField);
    
    // -- 

    const handleTextFieldCreation = () => { 

        if (isCreatingTextField) { 

            dispatch(setIsCreatingTextField(false));

        } else if (!isCreatingTextField) { 

            dispatch(setIsCreatingTextField(true));

        }; 

    }; 

    // -- 

    const handleClose = () => { 

        dispatch(setIsCreatingTextField(false));

    }; 

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%', 
                marginBottom: 3, 
                height: 'auto',
                fontSize: 12
            }}
        >

            <Box
                onClick={handleTextFieldCreation}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    width: '95%', 
                    borderRadius: 3, 
                    marginBottom: 1, 
                    height: 50,
                    cursor: 'pointer', 
                    backgroundColor: colors?.formRow,
                    '&:hover': { backgroundColor: colors?.formRowHover },
                    transition: 'height 0.3s',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
            >

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                    }}
                >

                    { isCreatingTextField ? 
                        <FormatShapesIcon sx={{ color: 'rgb(100, 100, 100)', marginLeft: 2 }} />: 
                        <AddCircleOutlineIcon sx={{ color: 'rgb(45, 207, 196)', marginLeft: 2 }} />}
                    
                    { isCreatingTextField ? 
                    <Box sx={{ marginLeft: 2 }} >{t('drawBox')}</Box>:
                    <Box sx={{ marginLeft: 2 }} >{t('addnewField')}</Box>}

                </Box>

                <Box
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                    }}
                >

                    { isCreatingTextField ? 
                        <HighlightOffOutlinedIcon 
                            onClick={handleClose}
                            sx={{ 
                                color: 'rgb(150, 150, 150)', 
                                marginRight: 1, 
                                '&:hover': { color: colors?.deleteIcon }
                            }} />:
                        <Box sx={{ width: 25, marginRight: 1 }}></Box>}

                </Box>
                
            </Box>

        </Box>


  );

};

export default CreateForm;