import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { resetSearchPatient, setAddress, setDateOfBirth, setEmail, setFirstName, setHealthCardNumber, setIsFiltersDropdownOpened, setLastName, setPatientId, setPatientsList, setPhone } from '../../../../../../../../redux/features/doctor/Dashboard/patients/searchPatient';

// Routes

import { searchPatient } from '../../../../../../../../routes/doctor/patients';

// Components 

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Filters from './Filters';
import PatientsList from './PatientsList';
import Button from '@mui/material/Button';
import FiltersDropdown from './FiltersDropdown';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PatientFound from './PatientFound';
import TuneIcon from '@mui/icons-material/Tune';

const SearchBar: React.FC = () => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    const filters = useAppSelector((state) => state.searchPatient.filters); 
    const isFiltersDropdownOpened = useAppSelector((state) => state.searchPatient.isFiltersDropdownOpened); 
    const firstName = useAppSelector((state) => state.searchPatient.firstName); 
    const lastName = useAppSelector((state) => state.searchPatient.lastName); 
    const dateOfBirth = useAppSelector((state) => state.searchPatient.dateOfBirth); 
    const email = useAppSelector((state) => state.searchPatient.email); 
    const phone = useAppSelector((state) => state.searchPatient.phone); 
    const patientId = useAppSelector((state) => state.searchPatient.patientId); 
    const healthCardNumber = useAppSelector((state) => state.searchPatient.healthCardNumber); 
    const address = useAppSelector((state) => state.searchPatient.address); 
    const patientsList = useAppSelector((state) => state.searchPatient.patientsList); 

    const patientFound = useAppSelector((state) => state.searchPatient.patientFound); 

    const [placeholder, setPlaceholder] = useState(''); 

    const [text, setText] = useState(''); 

    const [dropdownPositionX, setDropdownPositionX] = useState(0); 
    const [dropdownPositionY, setDropdownPositionY] = useState(0); 

    const handleSearch = async () => { 

        const content = { 
            firstName: firstName,
            lastName: lastName,
            dateOfBirth: dateOfBirth,
            email: email,
            phone: phone,
            patientId: patientId,
            healthCardNumber: healthCardNumber,
            address: address, 
        }; 

        const res = await searchPatient(content) as any; 

        if (res.data.status === 200) { 

            dispatch(setPatientsList(res.data.patientsList)); 

        }; 

    }; 

    // -- 

    const hanleEnterOption = (e: any) => { 

        if (e.key === 'Enter') { 

            handleSearch(); 

        }; 

    }; 

    // -- 

    const handleQuit = () => { 

        dispatch(resetSearchPatient()); 
        setText('');

    }; 

    // -- 

    const handleFiltersDropdown = (e: any) => { 

        if (isFiltersDropdownOpened) { 

            dispatch(setIsFiltersDropdownOpened(false)); 

        } else { 

            setDropdownPositionX(e.pageX); 
            setDropdownPositionY(e.pageY); 

            dispatch(setIsFiltersDropdownOpened(true)); 

        };

    }; 

    // -- 

    useEffect(() => { 

        if (filters.length > 0) { 

            setPlaceholder(`${String(t('searchPatient'))} ${t('by')} ${t(filters[filters.length - 1])}`);

        } else { 

            setPlaceholder(`${String(t('searchPatient'))} ${t('by')} ${t('lastName')}`);

        }; 

    },[filters]); 

    // --

    useEffect(() => { 

        if (filters.length > 0) { 

            filters[filters.length - 1] === 'firstName' && dispatch(setFirstName(text)); 
            filters[filters.length - 1] === 'lastName' && dispatch(setLastName(text)); 
            filters[filters.length - 1] === 'dateOfBirth' && dispatch(setDateOfBirth(text));
            filters[filters.length - 1] === 'email' && dispatch(setEmail(text));
            filters[filters.length - 1] === 'phone' && dispatch(setPhone(text));
            filters[filters.length - 1] === 'patientId' && dispatch(setPatientId(text));
            filters[filters.length - 1] === 'address' && dispatch(setAddress(text));
            filters[filters.length - 1] === 'healthCardNumber' && dispatch(setHealthCardNumber(text));
            
        } else { 

            dispatch(setLastName(text)); 

        }; 

    },[text]); 

    // -- 

    useEffect(() => { 

        if (firstName || lastName || dateOfBirth || email || phone || patientId || address || healthCardNumber) { 

            handleSearch(); 

        };

    },[firstName, lastName, dateOfBirth, email, phone, patientId, address, healthCardNumber]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: 100,
            marginBottom: '1%',
            fontSize: 12, 
        }}>

            <Filters /> 

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                width: '100%',
                height: 50, 
                borderRadius: 4,
                // backgroundColor: '#383A40',
            }}>

                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '90%',
                    height: '100%', 
                    borderRadius: 4,
                    backgroundColor: colors?.patientSearchRow,
                    // backgroundColor: '#383A40',
                }}>
                    <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: colors?.patientSearchColor }}/>
                    <TextField
                        onKeyDown={hanleEnterOption}
                        id="standard-search"
                        placeholder={placeholder}
                        variant="standard"
                        autoComplete='off'
                        value={text}
                        InputProps={{
                            style: {fontSize: 12, color: colors?.patientSearchColor },
                            disableUnderline: true, 
                        }}
                        onChange={(e) => setText(e.target.value)}
                        sx={{
                            marginLeft: 2,
                            fontSize: 12,
                            width: '100%',
                            borderBottomColor: colors?.patientSearchColor
                        }}
                    />

                    <Box
                        onClick={handleQuit}
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            alignItems: 'center', 
                            justifyContent: 'space-evenly',
                            width: 30,
                            height: 30, 
                            cursor: text ? 'pointer' : 'default',
                            color: colors?.text,
                            borderRadius: '50%',
                            marginRight: 2, 
                            '&:hover': { color: colors?.highlightedText},
                        }}
                    >
                        { (text || filters.length > 0) && <HighlightOffIcon sx={{ '&:hover': { color: colors?.highlightedText }}}/>} 
                    </Box>

                    <Box
                        onClick={handleFiltersDropdown}
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            alignItems: 'center', 
                            justifyContent: 'space-evenly',
                            width: 30,
                            height: 30, 
                            cursor: 'pointer',
                            color: colors?.text,
                            borderRadius: '50%',
                            marginRight: 2, 
                            '&:hover': { color: colors?.highlightedText},
                        }}
                    >
                        <TuneIcon sx={{ '&:hover': { color: colors?.highlightedText }}}/>
                    </Box>

                </Box>

            </Box>

            { isFiltersDropdownOpened && <FiltersDropdown positionX={dropdownPositionX} positionY={dropdownPositionY} />} 

            { patientFound && <PatientFound />}  

            { patientsList.length > 0 && <PatientsList />} 
    
     </Box>

    );

};

export default SearchBar;