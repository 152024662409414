import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '@mui/material/Button';

// Routes

import { createSupportTicket } from '../../../../routes/hacker/users'; 

// Redux 

import { setResetCreateSupportTicket } from '../../../../redux/features/general/createSupportTicket';
import { setNotificationMessage } from '../../../../redux/features/general/notification';


const SubmitButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const author = useAppSelector(state => state.createSupportTicket.author); 
    const status = useAppSelector(state => state.createSupportTicket.status); 
    const category = useAppSelector(state => state.createSupportTicket.category); 
    const description = useAppSelector(state => state.createSupportTicket.description); 

  const [allowUserCreation, setAllowUserCreation] = useState(false); 

  useEffect(() => { 

    if (author && status && category) { 

        setAllowUserCreation(true); 

    } else { 

        setAllowUserCreation(false); 

    };

  },[author, status, category]); 

  // -- 

  const handleCreateSupportTicket = async () => { 

     const res = await createSupportTicket(author, status, category, description) as any; 

     if (res.status === 200) { 

      // Redux is reset. 

      dispatch(setResetCreateSupportTicket()); 

      dispatch(setNotificationMessage('Support Ticket Successfully Created')); 

     } else { 



    }; 

  }; 

  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '5vh',
        height: '5vh',
        marginBottom: '3vh',
    }}>

        { allowUserCreation ? 
        <Button onClick={() => handleCreateSupportTicket()} variant="contained">Create Ticket</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">Create Ticket</Button>}


    </Box>

  );

};

export default SubmitButton;