import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface InitialState {
  doctor: any
}

// ---------------

const initialState: InitialState = {
  doctor: null,
};

// ---------------


export const doctorAccountSlice = createSlice({

  name: 'doctorAccount',

  initialState,

  reducers: {

    setDoctorAccount: (state, action: PayloadAction<any>) => {
        state.doctor = action.payload
    },
    setResetDoctorAccount: (state) => { 
        
      state = initialState; 

    } 
  },

}); 

// ---------------

export const { setDoctorAccount, setResetDoctorAccount } = doctorAccountSlice.actions;


export default doctorAccountSlice.reducer;