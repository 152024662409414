import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

// Routes

import { createPatient } from '../../../../../../routes/doctor/patients';

// Redux 

import { setErroredCategory, setErroredField, setResetCreatePatient } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setNotificationMessage } from '../../../../../../redux/features/general/notification';
import { setInfoSection, setPatient, setSection } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';

// Utils 

import requiredFields from '../../../../../../util/dashboard/doctor/patient/requiredFields';

const CreatePatientButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector(state => state.createPatient.section) as string; 
    const patient = useAppSelector((state) => state.patient) as any; 
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const completedFields = useAppSelector((state) => state.createPatient.completedFields);

    const [allowSubmitButton, setAllowSubmitButton] = useState(true); 

  

    // -- 

    const handleCreatePatient = async () => { 

        dispatch(setPatient(null)); 
        dispatch(setSection('createPatient')); 
        dispatch(setInfoSection('overview'));

    }; 

    // -- 

    useEffect(() => { 

        // if (patient) { 

        //     setAllowSubmitButton(false); 

        // } else { 

        //     setAllowSubmitButton(true); 
        // }; 

    },[patient]); 


  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginTop: 1,
        marginBottom: 2, 
        height: '5vh',
        width: '100%',
    }}>

        { allowSubmitButton ? 
        <Button 
          onClick={() => handleCreatePatient()}
          sx={{ width: '75%', backgroundColor: colors?.createPatient }} 
          variant="contained">{t('createPatient')}</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            width: '75%',
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">{t('createPatient')}</Button>}

    </Box>

  );

};

export default CreatePatientButton;