import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 
import { StringMap } from 'i18next';

// ---------------
 
interface MetaState {
    accountId: string, 
    accountType: string,
    accountInfo: any,
}

// ---------------

const initialState: MetaState = {
  accountId: '', 
  accountType: '', 
  accountInfo: null,
};

// ---------------

interface Account { 
  _id: string,
  accountType: string,
}


export const metaSlice = createSlice({

  name: 'meta',

  initialState,

  reducers: {

    setAccount: (state, action: PayloadAction<Account>) => {
      
        state.accountId = action.payload._id; 
        state.accountType = action.payload.accountType; 
    },

    setAccountType: (state, action: PayloadAction<string>) => {
        state.accountType = action.payload; 
    },
    setAccountInfo: (state, action: PayloadAction<any>) => { 
      state.accountInfo = action.payload; 
    },
    setResetMeta: (state) => { 
        
      state.accountId = ''; 
      state.accountType = ''; 
      state.accountInfo = null;

    } 
  },

}); 

// ---------------

export const { setAccount, setAccountType, setAccountInfo, setResetMeta } = metaSlice.actions;

// export const selectCount = (state: RootState) => state.counter.value; 

export default metaSlice.reducer;