import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

interface Props { 
    patientId: string,
    fullName: string
}; 

const PatientRow: React.FC<Props> = ({ patientId, fullName }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100%',
            height: 40, 
            color: colors?.text
        }}>

            <Box sx={{ marginLeft: 2}}>{fullName}</Box>
            <Box sx={{ marginRight: 3 }}>{patientId}</Box>

        </Box>

    );

};

export default PatientRow;