import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../redux/hooks';

// Components 

import SectionHeader from './formItems/SectionHeader';

import FirstName from './formItems/FirstName';
import LastName from './formItems/LastName';
import Email from './formItems/Email';
import AccountType from './formItems/AccountType';
import TeamsList from './formItems/TeamsList';
import SubmitButton from './formItems/SubmitButton';

const FormContainer: React.FC = () => {

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '90%',
        width: '95%',
        marginTop: '2vh',
        overflowY: 'scroll',
        scrollbarWidth: 'none'
    }}>

        <SectionHeader title='Create User' />

        <FirstName />

        <LastName />

        <Email />

        <AccountType />

        <SectionHeader title='Assign to a team' />

        <TeamsList />

        <SubmitButton />

    </div>

  );

};

export default FormContainer;