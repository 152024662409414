import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface SupportTicketState {
  author: string, 
  firstName: string,
  lastName: string,
  email: string,
  status: string,
  category: string, 
  description: string, 
  ticketId: string, 
  createdAt: string,
}

// ---------------

const initialState: SupportTicketState = {
    author: '', 
    firstName: '',
    lastName: '',
    email: '',
    status: '',
    category: '', 
    description: '', 
    ticketId: '', 
    createdAt: '', 
};

// ---------------


export const supportTicketSlice = createSlice({

  name: 'supportTicket',

  initialState,

  reducers: {

    setSupportTicket: (state, action: PayloadAction<any>) => {

      state.author = action.payload.author; 
      state.status = action.payload.status; 
      state.category = action.payload.category; 
      state.description = action.payload.description; 
      state.ticketId = action.payload._id; 
      state.createdAt = action.payload.createdAt; 
    },
    setAuthorPersonalInfo: (state, action: PayloadAction<any>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName; 
      state.email = action.payload.email; 
    }, 
    setResetSupportTicket: (state) => { 
        
        state.firstName = '';
        state.lastName = '';
        state.email =  '';
        state.status =  '';
        state.category =  '';
        state.description =  '';
        state.ticketId =  '';
        state.createdAt = ''; 
    } 

  },

}); 

// ---------------

export const { 
    setSupportTicket,
    setAuthorPersonalInfo,
    setResetSupportTicket } = supportTicketSlice.actions;

export default supportTicketSlice.reducer;