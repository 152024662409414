import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import moment from 'moment';

// Redux 

import { setResetSupportTicket } from '../../../redux/features/support/supportTicket';
import { setNotificationMessage } from '../../../redux/features/general/notification';

// Routes 

import { archiveSupportTicket } from '../../../routes/hacker/users';

// Utils 

import getStatusColor from '../../../util/style/getStatusColor';

// Components 

import Button from '@mui/material/Button';
import { setIsRefreshSupportTicketsList } from '../../../redux/features/hacker/users/supportTicketsAccess';
import { setClosePopup } from '../../../redux/features/general/popup';

const TicketDetails: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const firstName = useAppSelector(state => state.supportTicket.firstName); 
    const lastName = useAppSelector(state => state.supportTicket.lastName); 
    const email = useAppSelector(state => state.supportTicket.email); 
    const ticketId = useAppSelector(state => state.supportTicket.ticketId); 
    const status = useAppSelector(state => state.supportTicket.status); 
    const category = useAppSelector(state => state.supportTicket.category); 
    const description = useAppSelector(state => state.supportTicket.description); 
    const createdAt = useAppSelector(state => state.supportTicket.createdAt); 

    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 

    // -- 

    const handleArchive = async () => { 

        const res = await archiveSupportTicket(ticketId) as any; 
        
        if (res.data.status === 200) { 

            dispatch(setNotificationMessage('Support Ticket successfully archived.')); 
            dispatch(setResetSupportTicket()); 
            dispatch(setIsRefreshSupportTicketsList(true)); 
            dispatch(setClosePopup()); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (!isPopupOpened) { 

            dispatch(setResetSupportTicket()); 

        }; 

    },[isPopupOpened]);

    // -- 

    return (

    <div 

    style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
    }}>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
                marginTop: 10, 
            }}>
                <div>Author</div>
                <div>{firstName} {lastName}</div>

        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>Email</div>
                <div>{email}</div>

        </div>

        <div
            style={{ 
                width: '90%',
                height: 1,
                backgroundColor: 'grey',
                marginTop: 10, 
                marginBottom: 15,
            }}>

        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>Status</div>
                <div>
                    <strong style={{ 
                        padding: 5,
                        borderRadius: 7,
                        backgroundColor: getStatusColor(status), 
                    }}>
                        {status}
                    </strong>
                </div>

        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>Ticket ID</div>
                <div>{ticketId}</div>

        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-between',
                width: '90%',
                height: 50,
            }}>
                <div>Creation</div>
                <div>{moment.utc(createdAt).format('LL')}</div>

        </div>

        <div
            style={{ 
                width: '20%',
                marginRight: '70%',
                height: 1,
                backgroundColor: 'grey',
                marginTop: 10, 
                marginBottom: 15,
            }}>

        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '90%',
                height: 50,
            }}>
                <div>#</div>
                <div style={{ marginLeft: 10 }}>{category}</div>

        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '90%',
                height: 50,
            }}>
                <div>Description</div>

        </div>

        <div style={{
                flex: 1,
                flexWrap: 'wrap',
                flexFlow: 'column',
                width: '90%',
                overflowWrap: 'normal',
                color: '#C2C5C8',
                fontSize: 12,
                marginTop: 10,
                marginBottom: 10, 
        }}>{description}</div>

        <div
            style={{ 
                width: '90%',
                height: 1,
                backgroundColor: 'grey',
                marginTop: 10, 
                marginBottom: 15,
            }}>

        </div>

        <div
            style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '90%',
                height: 50,
            }}>
                <div>Team Logs</div>

        </div>

        <div style={{
                width: '90%',
                overflowWrap: 'normal',
                color: '#C2C5C8',
                fontSize: 12,
                marginTop: 10,
                marginBottom: 10, 
        }}>No logs have been detected.</div>

        { status !== 'archived' && 
        
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 10,
                    marginBottom: 30, 
                }}
            >

                <Button 
                    onClick={handleArchive} 
                    sx={{ 
                        marginLeft: 5, 
                        backgroundColor: 'rgb(199, 119, 80)',
                        '&:hover': { backgroundColor: 'rgb(227, 96, 32)'}
                    }} 
                variant="contained">Archive Ticket</Button>

            </Box>}





    </div>

    );

};

export default TicketDetails;