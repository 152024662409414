import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../redux/hooks';

// Routes 

import { getAllUserAccess } from './../../../../routes/admin/users';

// Redux 

import { setRefreshUsersList, setSelectedUser, setUsersListLength } from './../../../../redux/features/admin/dashboard/users/userAccess';

// Components 

import ListHeader from './listItems/ListHeader';
import UserRow from './listItems/UserRow';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const UsersList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const refreshUsersList = useAppSelector(state => state.adminUserAccess.refreshUsersList); 

    const [users, setUsers] = useState(Array<any>);

    const getUsers = async () => { 

      const usersList = await getAllUserAccess() as Array<any>; 

      if (usersList) { 

        setUsers(usersList); 
    
        dispatch(setUsersListLength(usersList.length)); 
  
        dispatch(setRefreshUsersList(false)); 

      };

    }; 

    const selectUser = (user: any) => { 

      dispatch(setSelectedUser(user)); 

    };
    
    useEffect(() => { 

      getUsers(); 

    },[]); 

    useEffect(() => { 

      if (refreshUsersList) { 
  
        getUsers(); 

      }; 

    },[refreshUsersList]); 
    
      return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '75%',
            height: '95%', 
            borderRadius: 5,
            backgroundColor: '#313338',
          }}>

            <ListHeader />

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '85%',
              width: '95%',
              borderBottomLeftRadius: 10,
              borderBottomRightRadius: 10,
              backgroundColor: '#383A40',
            }}>


            { users?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2vh' }}>
        
                <List dense>
        
                {users?.map((item, index) => (
        
                    <ListItem onClick={() => selectUser(item._doc)} button key={index}>

                        <UserRow 
                          firstName={item._doc.firstName}
                          lastName={item._doc.lastName}
                          type={item.type}
                          email={item._doc.email}
                          team={item.teamName}
                          createdAt={moment.utc(item._doc.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {users?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              color: '#C2C5C8'
            }}>

              <PersonAddAlt1Icon sx={{ fontSize: 70 }} />

              <div>No user account detected.</div>

          </Box>}
        
        

            </Box>

        </Box>
    
      );

};

export default UsersList;