
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setEmergencyContacts, setInfoSection, setInitialRendering, setPatient, setPatientIdFromFileExplorer, setPatientsList, setRefreshPatientsList } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';

// Routes

import { getMetaPatientsList, getPatient } from '../../../../../../routes/doctor/patients';

// Components

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PatientRow from './PatientRow';
import { setNewlyAttachedPatientId } from '../../../../../../redux/features/doctor/Dashboard/patients/searchPatient';

const PatientsList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const patientsList = useAppSelector(state => state.patient.patientsList); 
    const patientIdFromFileExplorer = useAppSelector(state => state.patient.patientIdFromFileExplorer); 
    const refreshPatientsList = useAppSelector(state => state.patient.refreshPatientsList); 
    const newlyAttachedPatientId = useAppSelector(state => state.searchPatient.newlyAttachedPatientId); 
    const initialRendering = useAppSelector(state => state.patient.initialRendering); 

    // -- 

    const selectMetaPatient = async (metaPatient: any) => { 

        const res = await getPatient(metaPatient.patientId) as any; 

        if (res.data.status === 200) { 

            dispatch(setPatient(res.data.patient)); 
            dispatch(setEmergencyContacts(res.data.emergencyContacts)); 
            dispatch(setInfoSection('info')); 

        } else if (res.data.status === 401) { 


        }; 

        dispatch(setNewlyAttachedPatientId(null)); 

    }; 

    // -- 

    const handlePatientsList = async () => { 

        const res = await getMetaPatientsList() as any; 

        if (res.data.status === 200) { 

            dispatch(setPatientsList(res.data.metaPatientsList)); 

            if (newlyAttachedPatientId) {

                selectMetaPatient(newlyAttachedPatientId); 

            }; 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (initialRendering) { 

            handlePatientsList(); 

            dispatch(setInitialRendering(false)); 

        }; 

    },[initialRendering]); 

    // -- 

    useEffect(() => { 

        if (refreshPatientsList) { 

            handlePatientsList(); 

            dispatch(setRefreshPatientsList(false)); 
        }; 

    },[refreshPatientsList]); 

    // -- 

    useEffect(() => { 

        if (patientIdFromFileExplorer) { 

            const metaPatient = { 
                patientId: patientIdFromFileExplorer
            }; 

            selectMetaPatient(metaPatient); 

            dispatch(setPatientIdFromFileExplorer(null)); 
        };

    },[patientIdFromFileExplorer]);

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%',
                height: '80%', 
                marginTop: 1,
            }}
        >

        { patientsList?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                scrollbarWidth: 'none' }}>
        
                <List dense disablePadding>
        
                {patientsList?.map((item, index) => (

                    item && (
                        <ListItem onClick={() => selectMetaPatient(item)} button key={index} disablePadding>
                            <PatientRow 
                                _id={item.patientId}
                                fullName={item.fullName}
                            />
                        </ListItem>
                    )
                ))}
        
                </List>
        
            </Box>}

          </Box>

    );

};

export default PatientsList;