import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


const HomePage: React.FC = () => {

  const navigate = useNavigate();

  const getToken = () => { 

    const token = Cookies.get('jwt');

    if (token) { 

      navigate('/dashboard'); 

    } else { 

      navigate('/login'); 

    }; 

  }; 

  // -- 

  useEffect(() => {

    getToken(); 

  }, []);

  return null;
};

export default HomePage;