import axios from 'axios';
import Cookies from 'js-cookie';


// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 


// -- 

export const createSupportEmail = async (category: string, description: string) => {

    try {

        const content = { 
            category: category, 
            description: description,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/support/email`, content, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error creating a support email.', error);
    };

}; 

// -- 

export const getSupportTickets = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/doctor/support/tickets`, getConfig()); 

        return res; 

    } catch (error) {
        console.error('Error getting a list of doctors:', error);
    };

};

// -- 

export const getTicketDetails = async (author: string) => { 

    try { 

        const content = { 
            author: author
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/doctor/support/ticketInfo`, content, getConfig()); 
        
        return res; 

    } catch (error) {
        console.error('Error getting the ticket details:', error);
    }; 

}; 