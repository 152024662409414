import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components 

import DocsList from './documents/DocsList';

import DocumentViewer from './documents/documentInfo/DocumentViewer';
import DocumentNotes from './documents/documentInfo/team/DocumentNotes';
import TeamContainer from './documents/documentInfo/team/TeamContainer';
import CreateNote from './documents/documentInfo/team/notes/CreateNote';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const DocumentInterface: React.FC = () => {

  const file = useAppSelector(state => state.adminCreateNote.file); 
  const theme = useTheme();
  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '60px' : '10%';

  return (

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '90%',
      height: '95%', 
      left: leftOffset,
      bottom: 0,
      position: 'absolute',
      backgroundColor: '#201F1E',
    }}>

      <DocsList />

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '70%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#313338',
      }}>


        <DocumentViewer />

        <DocumentNotes />

        <TeamContainer />

      </Box>

      {file && <CreateNote />}

      </Box>

  );

};

export default DocumentInterface;