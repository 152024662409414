import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 
import { any } from 'prop-types';

// ---------------
 
interface DoctorsAccessState {
  doctors: any[],
  selectedDoctor: any, 
  isRefreshDoctorsList: boolean, 
  initialRendering: boolean,
  deletedAccountId: any,  
}

// ---------------

const initialState: DoctorsAccessState = {
    doctors: [], 
    selectedDoctor: null, 
    isRefreshDoctorsList: false, 
    initialRendering: true, 
    deletedAccountId: null, 
};

// ---------------


export const doctorsAccessSlice = createSlice({

  name: 'doctorsAccess',

  initialState,

  reducers: {

    setDoctors: (state, action: PayloadAction<any>) => { 

        state.doctors = action.payload; 

    },
    setSelectedDoctor: (state, action: PayloadAction<any>) => { 

      state.selectedDoctor = action.payload; 

    },
    setIsRefreshDoctorsList: (state, action: PayloadAction<boolean>) => { 

      state.isRefreshDoctorsList = action.payload; 

    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    setDeletedAccountId: (state, action: PayloadAction<any>) => {
  
      state.deletedAccountId = action.payload; 

    }, 
    resetDoctorsAccess: (state) => { 
        
      state.doctors = []; 
      state.selectedDoctor = null;
      state.isRefreshDoctorsList = false;
      state.initialRendering = true; 
      state.deletedAccountId = null; 
    } 

  },

}); 

// ---------------

export const { 
  setDoctors, 
  setSelectedDoctor, 
  setIsRefreshDoctorsList, 
  setInitialRendering, 
  setDeletedAccountId, 
  resetDoctorsAccess } = doctorsAccessSlice.actions;

export default doctorsAccessSlice.reducer;