import React, { useState, useEffect} from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useDropzone } from 'react-dropzone';

// Redux

import { setBlobFileUrl, setFileExtension, setFileName } from '../../../../../redux/features/doctor/Dashboard/documents/fileViewer';
import { setMode, selectFile, selectFolder, setCurrentFolder, setIsDropdownOpened, setRefreshFileExplorer, setSearchSection, setFolderView } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setIsOpened } from '../../../../../redux/features/doctor/Dashboard/documents/createFolder';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { setEditedFile, setEditedFileTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFile';

// Routes 

import { serveFile, pinFolder, removePinnedFolder, detachFileFromPatient, getSuggestedCorrections } from '../../../../../routes/doctor/files';

// Icons 

import Box from '@mui/material/Box';
import UploadFileButton from './uploadFile/UploadFileButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { setEditedFolder, setEditedFolderTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFolder';
import { setDashboardSection } from '../../../../../redux/features/general/navigation';
import { setPatientIdFromFileExplorer } from '../../../../../redux/features/doctor/Dashboard/patients/patient';
import { setCorrections, setIsServerCorrecting } from '../../../../../redux/features/doctor/Dashboard/documents/correctFile';

type Props = {
    children?: React.ReactNode;
    title: any,
    disabled?: boolean,
    iconColor?: string, 
    action: string
    type: string; 
    hideLabel?: boolean, 
};


const Action: React.FC<Props> = ({ children, title, disabled, iconColor, action, type, hideLabel }) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector((state) => state.theme.colors); 

    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder);
    const directory = useAppSelector(state => state.fileExplorer.directory);
    const currentFolder = useAppSelector(state => state.fileExplorer.currentFolder); 
    const downloadsFolder = useAppSelector(state => state.fileExplorer.downloadsFolder); 
    const documentsFolder = useAppSelector(state => state.fileExplorer.documentsFolder); 
    const folderView = useAppSelector(state => state.fileExplorer.folderView); 
    const mode = useAppSelector(state => state.fileExplorer.mode); 

    // -- 

    const verifyCurrentFolder = (selectedFolder: any, action: string) => { 

        if ((currentFolder.path === 'Recent') || (currentFolder.path === 'Patients')) { 

            action === 'uploadFile' && dispatch(setCurrentFolder(downloadsFolder)); 
            action === 'createFolder' && dispatch(setCurrentFolder(documentsFolder)); 

        } else if (selectedFolder) { 

            dispatch(setCurrentFolder(selectedFolder)); 

        };

    }; 

    // -- 

    const handleFileAction = async () => { 

        if (disabled) { 

            return; 
            
        }; 

        if (action === 'viewFile') { 

            const res = await serveFile(selectedFile._id) as any; 

            const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

            dispatch(setBlobFileUrl(URL.createObjectURL(fileBlob))); 
            dispatch(setFileExtension(selectedFile.fileUrl.split('.').pop() || 'unknown')); 
            dispatch(setFileName(selectedFile.fileUrl.split('-').slice(1).join('-') || 'file')); 
            dispatch(setMode('viewFile')); 

        } else if (action === 'uploadFile') {

            verifyCurrentFolder(selectedFolder, 'uploadFile'); 

            dispatch(setMode('uploadFile'));
            dispatch(selectFile(null));
            dispatch(selectFolder(null));

        } else if (action === 'createFolder') { 

            verifyCurrentFolder(selectedFolder, 'createFolder'); 

            dispatch(setIsOpened(true)); 
            dispatch(selectFolder(null)); 

        } else if (action === 'renameFolder') { 

            dispatch(setMode('editFolder')); 
            dispatch(setEditedFolder(selectedFolder)); 
            dispatch(setEditedFolderTitle(selectedFolder.title)); 
            
        } else if (action === 'pinFolder') { 

            const res = await pinFolder(selectedFolder._id); 

            if (res?.data.status === 200) { 

                dispatch(setNotificationMessage(t('pinnedFolder'))); 
                dispatch(setRefreshFileExplorer(true)); 
                dispatch(selectFolder(res.data.folder)); 

            }; 

        } else if (action === 'removePin') { 

            const res = await removePinnedFolder(selectedFolder._id); 
            
            if (res?.data.status === 200) { 

                dispatch(setNotificationMessage(t('removedPin'))); 
                dispatch(setRefreshFileExplorer(true)); 
                dispatch(selectFolder(res.data.folder)); 

            };

        } else if (action === 'changeFolderView') {

            if (folderView === 'row') { 

                dispatch(setFolderView('box'));
                localStorage.setItem('folderView', 'box');

            } else if (folderView === 'box') { 

                dispatch(setFolderView('row'));
                localStorage.setItem('folderView', 'row');
                
            }; 
          
        } else if (action === 'renameFile') { 

            dispatch(setMode('editFile'));
            dispatch(setEditedFile(selectedFile)); 
            dispatch(setEditedFileTitle(selectedFile.title));  

        } else if (action === 'correctFile') {  

            const res = await serveFile(selectedFile._id) as any; 

            const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });

            dispatch(setBlobFileUrl(URL.createObjectURL(fileBlob))); 
            dispatch(setFileExtension(selectedFile.fileUrl.split('.').pop() || 'unknown')); 
            dispatch(setFileName(selectedFile.fileUrl.split('-').slice(1).join('-') || 'file')); 
            dispatch(setMode('correctFile'));   

            dispatch(setIsServerCorrecting(true)); 

            const response = await getSuggestedCorrections(selectedFile._id) as any; 

            if (response?.data.status === 200) { 

                dispatch(setCorrections(response.data.corrections)); 

                dispatch(setIsServerCorrecting(false)); 

            }; 

        } else if (action === 'deleteFile') { 

            dispatch(setMode('deleteFile')); 
    
        } else if (action === 'deleteFolder') { 

            dispatch(setMode('deleteFolder')); 

        } else if (action === 'attachToPatient') { 

            dispatch(setMode('attachToPatient')); 

        } else if (action === 'patientInfo') { 

            if (selectedFolder || selectedFile) { 

                selectedFolder && dispatch(setPatientIdFromFileExplorer(selectedFolder?.relatedPatient)); 
                selectedFile && dispatch(setPatientIdFromFileExplorer(selectedFile?.relatedPatient)); 

            } else if (mode === 'sharedFolder') { 

                dispatch(setPatientIdFromFileExplorer(currentFolder?.relatedPatient)); 

            } else if (directory.startsWith('Patients/')) {

                dispatch(setPatientIdFromFileExplorer(currentFolder?.relatedPatient)); 

            };

            selectFolder(null);
            selectFile(null); 

            dispatch(setDashboardSection('patients')); 

        } else if (action === 'detachFileFromPatient') { 

            const res = await detachFileFromPatient(selectedFile._id); 

            if (res?.data.status === 200) { 

                dispatch(setNotificationMessage('fileDetachedFromPatient')); 
                dispatch(selectFile(null)); 
                dispatch(setSearchSection(res.data.file.folder)); 
                dispatch(setRefreshFileExplorer(true)); 

            }; 

        } else if (action === 'moveFile') { 

            dispatch(setMode('moveFile'));
            dispatch(setEditedFileTitle(selectedFile.title));

        } else if (action === 'moveFolder') { 

            dispatch(setMode('moveFolder'));
            dispatch(setEditedFolderTitle(selectedFolder.title));

        } else if (action === 'refreshFileExplorer') { 

            dispatch(setRefreshFileExplorer(true)); 

        }; 

        dispatch(setIsDropdownOpened(false)); 

    }; 

    return (

        <>

            { type === 'default' &&
            
            <Box 
                onClick={handleFileAction}
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                color: disabled ? colors?.disabledRelatedPatient : iconColor,
                marginLeft: 3,
                padding: 1, 
                borderRadius: 3, 
                cursor: disabled ? 'default' : 'pointer', 
                height: 30,
                fontSize: 20, 
                zIndex: 0, 
                '&:hover': { backgroundColor: disabled ? 'transparent' : colors?.sectionHover }
            }}>
                {children}
                { !hideLabel && <Box sx={{ marginLeft: 1, fontSize: 12, color: disabled ? colors?.disabledRelatedPatient : colors?.text }}>{t(title)}</Box>}

                { (action === 'uploadFile' && !disabled) && <UploadFileButton />}

            </Box>}

            { type === 'dropdown' &&
            
            <Box
                onClick={handleFileAction}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 40,
                    fontSize: 12,  
                    borderRadius: 3,
                    '&:hover': { backgroundColor: colors?.dropdown}
                }}
            >
                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                >
                    {children}
                    <Box sx={{ marginLeft: 3 }}>{t(title)}</Box>
                </Box>

            { (action === 'uploadFile' && !disabled) && <UploadFileButton />}

        </Box>}

        </>

    );

};

export default Action;















// Print 

// const handlePrint = () => {
//     if (uploadedFileData?.extension === 'pdf' && iframeRef.current) {
//       iframeRef.current.contentWindow?.print();
//     } else if (uploadedFileData?.extension === 'docx' && containerRef.current) {
//       const printWindow = window.open('', '_blank');
//       printWindow?.document.write(containerRef.current.innerHTML);
//       printWindow?.document.close();
//       printWindow?.print();
//       printWindow?.close();
//     }
//   };


// import React, { useEffect, useState, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useDropzone } from 'react-dropzone';
// import { RootState, AppDispatch } from '../../../../../../../redux/store';
// import { setFile, clearFile, setTitle, setDescription, setResetUploadFile } from '../../../../../../../redux/features/doctor/Dashboard/documents/uploadFileSlice';
// import Cookies from 'js-cookie';
// import debounce from 'lodash/debounce';
// import Notification from '../../../../../../general/Notification';
// import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';
// import { getPatientsList } from '../../../../../../../routes/doctor/dashboard';
// import axios from 'axios';

// // Routes

// import { uploadFile, getBlobFile } from '../../../../../../../routes/doctor/transferFiles';

// // MUI Import

// import { FormControl, InputLabel } from '@mui/material';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import Select from '@mui/material/Select';
// import { SelectChangeEvent } from '@mui/material';
// import MenuItem from '@mui/material/MenuItem';
// import Typography from '@mui/material/Typography';
// import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import DownloadIcon from '@mui/icons-material/Download';
// import PrintIcon from '@mui/icons-material/Print';
// import IconButton from '@mui/material/IconButton';
// import { useTranslation } from 'react-i18next';

// const InsertFile: React.FC = () => {
//   const { t } = useTranslation();
//   const dispatch: AppDispatch = useDispatch();
//   const { file, title, description } = useSelector((state: RootState) => state.uploadFile);

//   const [patients, setPatients] = useState<Array<any>>([]);
//   const [selectedPatient, setSelectedPatient] = useState('');
//   const [uploadedFileData, setUploadedFileData] = useState<{ url: string; extension: string; name: string } | null>(null);

//   const iframeRef = useRef<HTMLIFrameElement>(null);
//   const containerRef = useRef<HTMLDivElement>(null);

//   // Fetch patients list when component loads
//   useEffect(() => {
//     const fetchPatients = async () => {
//       try {
//         const data = await getPatientsList();
//         if (data) {
//           setPatients(data);
//         }
//       } catch (error) {
//         console.error('Error fetching patients:', error);
//       }
//     };

//     fetchPatients();
//   }, []);

//   const onDrop = (acceptedFiles: File[]) => {
//     if (acceptedFiles.length > 0) {
//       const selectedFile = acceptedFiles[0];
//       const allowedMimeTypes = [
//         'application/pdf',
//         'application/msword',
//         'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
//       ];

//       if (!allowedMimeTypes.includes(selectedFile.type)) {
//         dispatch(setNotificationMessage(t('invalidFileType')));
//         return;
//       }

//       dispatch(setFile(selectedFile));
//     }
//   };

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     onDrop,
//     disabled: !!file,
//     accept: {
//       'application/pdf': ['.pdf'],
//       'application/msword': ['.doc'],
//       'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
//     },
//   });

//   const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     dispatch(setTitle(event.target.value));
//   };

//   const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     dispatch(setDescription(event.target.value));
//   };

//   const handlePatientSelect = (event: SelectChangeEvent<string>) => {
//     setSelectedPatient(event.target.value as string);
//   };

//   const handleSelectFile = async (file) => {

//     if (!file || !file._id) return;

//     try {

//       const res = await getBlobFile(file._id) as any;  

//       if (res.status === 200) { 

//         const fileBlob = new Blob([res.data], { type: res.headers['content-type'] });
//         const fileUrl = URL.createObjectURL(fileBlob);
//         const fileExtension = file.fileUrl.split('.').pop() || 'unknown';
//         const fileName = file.fileUrl.split('-').slice(1).join('-') || 'file';
  
//         // Update state with file URL and metadata

//         setUploadedFileData({
//           url: fileUrl,
//           extension: fileExtension,
//           name: fileName,
//         });

//       };

//     } catch (error) {
//       console.error('Error serving file:', error);
//       dispatch(setNotificationMessage(t('errorRetrievingFile')));
//     }
//   };

//   const debouncedUploadFile = debounce(async () => {
//     if (!file) return;

//     let finalTitle = title;
//     if (!title) {
//       const patient = patients.find(item => item.patientId === selectedPatient);
//       const currentDate = new Date().toLocaleString();
//       const patientName = patient ? `${patient.firstName} ${patient.lastName}` : t('unknownPatient');
//       finalTitle = file.name.replace(/\.(pdf|docx)$/, '');;
//     }

//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('title', finalTitle);
//     formData.append('description', description);
//     formData.append('patientId', selectedPatient);

//     try {

//       const res = await uploadFile(formData) as any;  

//       if (res.status === 200) {
        
//         // Call handleSelectFile after the upload is successful
//         handleSelectFile(res.data.file);

//         dispatch(setNotificationMessage(t('fileUploaded')));
//         dispatch(clearFile());

//       } else {
//         dispatch(setNotificationMessage(t('fileUploadFailed')));
//       }
//     } catch (error) {
//       dispatch(setNotificationMessage(t('fileUploadFailed')));
//     }
//   }, 300);
  

//   const handleCancel = () => {
//     dispatch(clearFile());
//   };

//   const renderFile = () => {

//     if (!uploadedFileData) {
//       return null
//     };
  
//     if (uploadedFileData.extension === 'pdf') {
//       return (
//         <iframe
//           ref={iframeRef}
//           src={uploadedFileData.url}
//           style={{ width: '100%', height: '80vh' }}
//           title="PDF Viewer"
//         />
//       );
//     }
  
//     if (uploadedFileData.extension === 'docx') {
//       return (
//         <div
//           ref={containerRef}
//           style={{ width: '100%', height: '80vh', overflow: 'auto', backgroundColor: '#fff' }}
//         />
//       );
//     }
  
//     return null;
//   };

//   const handleGoBack = () => {
//     setResetUploadFile();        
//     setUploadedFileData(null);  
//     setTitle('');         
//     setDescription('');   
//     setSelectedPatient(''); 
//   };

//   const handlePrint = () => {
//     if (uploadedFileData?.extension === 'pdf' && iframeRef.current) {
//       iframeRef.current.contentWindow?.print();
//     } else if (uploadedFileData?.extension === 'docx' && containerRef.current) {
//       const printWindow = window.open('', '_blank');
//       printWindow?.document.write(containerRef.current.innerHTML);
//       printWindow?.document.close();
//       printWindow?.print();
//       printWindow?.close();
//     }
//   };
  
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//         justifyContent: 'center',
//         width: '100%',   // Adjust to cover entire width
//         height: '100%',  // Adjust to cover entire height
//         borderRadius: 5,
//         borderWidth: '2px',
//         borderStyle: 'solid',
//         borderColor: '#313338',
//         padding: 5,
//         backgroundColor: '#383A40',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//       }}
//     >
//       <Typography variant="h5" sx={{ mb: 2, fontWeight: 'bold', color: 'white' }}>
//         {t('uploadFile')}
//       </Typography>
  
//       {!uploadedFileData ? (
//         !file ? (
//           <div
//             {...getRootProps()}
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               justifyContent: 'center',
//               width: '100%',
//               height: '100%',
//               borderWidth: '2px',
//               borderStyle: 'dashed',
//               borderColor: 'darkgrey',
//               borderRadius: '5px',
//               padding: '20px',
//               textAlign: 'center',
//               backgroundColor: '#313338',
//               boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
//               transition: 'border 0.3s ease',
//               cursor: 'pointer',
//             }}
//             onMouseEnter={(e) => (e.currentTarget.style.borderColor = '#0099cc')}
//             onMouseLeave={(e) => (e.currentTarget.style.borderColor = 'darkgrey')}
//           >
//             <input {...getInputProps()} />
//             {isDragActive ? (
//               <Typography variant="body1" sx={{ color: 'white' }}>
//                 {t('dropFilesHere')}
//               </Typography>
//             ) : (
//               <>
//                 <CloudDownloadIcon sx={{ fontSize: 80, color: 'darkgrey' }} />
//                 <Typography variant="body1" sx={{ color: 'darkgrey' }}>
//                   {t('dragAndDrop')}
//                 </Typography>
//               </>
//             )}
//           </div>
//         ) : (
//           <div
//             style={{
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//               justifyContent: 'flex-start',
//               width: '100%',
//               height: '100%',
//             }}
//           >
//             <Typography variant="body2" sx={{ mb: 1, color: 'gray', fontStyle: 'italic' }}>
//               {t('uploading', { fileName: file.name })}
//             </Typography>
  
//             <TextField
//               label={t('title')}
//               value={title}
//               onChange={handleTitleChange}
//               variant="outlined"
//               fullWidth
//               margin="normal"
//               sx={{ backgroundColor: '#313338', borderRadius: 1, input: { color: 'white' }, label: { color: 'darkgrey' } }}
//             />
            
//             <TextField
//               label={t('description')}
//               value={description}
//               onChange={handleDescriptionChange}
//               variant="outlined"
//               fullWidth
//               multiline
//               rows={4}
//               margin="normal"
//               sx={{ backgroundColor: '#313338', borderRadius: 1, input: { color: 'white' }, label: { color: 'darkgrey' } }}
//             />
  
//             <FormControl fullWidth>
//               <InputLabel sx={{ color: 'darkgrey' }}>{t('selectPatient')}</InputLabel>
//               <Select
//                 value={selectedPatient}
//                 onChange={handlePatientSelect}
//                 label={t('selectPatient')}
//                 sx={{
//                   backgroundColor: '#313338',
//                   color: 'white',
//                   '.MuiOutlinedInput-notchedOutline': { borderColor: 'darkgrey' },
//                   '.MuiSvgIcon-root': { color: 'white' },
//                 }}
//               >
//                 {patients.map((item) => (
//                   <MenuItem key={item.patientId} value={item.patientId}>
//                     {/* {patient.firstName} {patient.lastName} */}
//                   </MenuItem>
//                 ))}
//               </Select>
//             </FormControl>
  
//             <Box
//               sx={{
//                 display: 'flex',
//                 justifyContent: 'center',
//                 mt: 2,
//                 width: '100%',
//               }}
//             >
//               <Button
//                 onClick={debouncedUploadFile}
//                 variant="contained"
//                 color="primary"
//                 sx={{
//                   backgroundColor: '#0099cc',
//                   color: 'white',
//                   '&:hover': {
//                     backgroundColor: '#0077aa',
//                   },
//                   width: '45%',
//                 }}
//               >
//                 {t('upload')}
//               </Button>
//               <Button
//                 onClick={handleCancel}
//                 variant="outlined"
//                 color="secondary"
//                 sx={{
//                   ml: 2,
//                   color: '#ff5555',
//                   borderColor: '#ff5555',
//                   '&:hover': {
//                     borderColor: '#ff3333',
//                   },
//                   width: '45%',
//                 }}
//               >
//                 {t('cancel')}
//               </Button>
//             </Box>
//           </div>
//         )
//       ) : (
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             justifyContent: 'center',
//             width: '100%',
//             height: '100%',
//           }}
//         >
//           {renderFile()}

//           <div
//             style={{
//               display: 'flex',
//               flexDirection: 'row', 
//               alignItems: 'center',
//               justifyContent: 'center',
//               marginTop: '16px', 
//             }}
//           >
//             <IconButton
//               onClick={handleGoBack}
//               sx={{
//                 color: 'white',
//                 '&:hover': {
//                   color: '#0099cc',
//                 },
//                 mr: 2, 
//               }}
//             >
//               <ArrowBackIcon />
//             </IconButton>

//             <IconButton
//               onClick={() => {
//                 const link = document.createElement('a');
//                 link.href = uploadedFileData.url;
//                 link.download = uploadedFileData.name || 'file';
//                 link.click();
//               }}
//               sx={{
//                 color: 'white',
//                 '&:hover': {
//                   color: '#0099cc',
//                 },
//                 mr: 2, 
//               }}
//             >
//               <DownloadIcon />
//             </IconButton>

//             <IconButton
//               onClick={handlePrint}
//               sx={{
//                 color: 'white',
//                 '&:hover': {
//                   color: '#0099cc',
//                 },
//               }}
//             >
//               <PrintIcon />
//             </IconButton>
//           </div>
//         </div>

//       )}
  
//       <Notification />
//     </Box>
//   );
  
  
// };

// export default InsertFile;