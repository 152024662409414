import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Routes

import { getAvailableEmployees } from './../../../../../routes/admin/team';

// Redux 

import { addTeamMember, removeTeamMember } from './../../../../../redux/features/admin/dashboard/team/createTeam';

// Icons 

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';


const TeamMembers: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const teamMembers = useAppSelector(state => state.adminCreateTeam.teamMembers); 

    const [availableEmployees, setAvailableEmployees] = useState(Array<any>); 

    const selectTeamMember = (employeeId: string) => { 

      if (!teamMembers.includes(employeeId)) {  

        dispatch(addTeamMember(employeeId)); 

      } else { 

        dispatch(removeTeamMember(employeeId));

      };

    };

      useEffect(() => { 

        const getEmployeesList = async () => {

          const employeesList = await getAvailableEmployees() as Array<any>;

          setAvailableEmployees(employeesList); 

        };

        getEmployeesList(); 

      },[]); 


      return (

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '30vh',
          width: '100%',
          borderRadius: 5,
          backgroundColor: '#383A40'
        }}>

          {availableEmployees?.length >= 1 && 
          <Box sx={{ 
            overflow: 'auto',
            width: '95%',
            marginTop: '2vh' }}>
    
            <List dense>
    
              {availableEmployees?.map((item, index) => (

                <ListItem onClick={() => selectTeamMember(item._id)} button key={index}>

    
                    <ListItemIcon sx={{ color: '#C2C5C8' }}>
                      {teamMembers?.includes(item._id) ? 
                      <CheckCircleIcon sx={{ color: 'lightblue'}} />:<AddCircleOutlineIcon />}
                    </ListItemIcon>
    
                    {teamMembers?.includes(item._id) ?   
                    <ListItemText sx={{ color: 'lightblue' }} primary={`${item.firstName} ${item.lastName}`} />:
                    <ListItemText sx={{ color: '#C2C5C8' }} primary={`${item.firstName} ${item.lastName}`} />}

                    <ListItemText sx={{ color: '#C2C5C8' }} primary={item.email} />
    
                </ListItem>

                
              ))}
    
            </List>
    
          </Box>}

          {availableEmployees?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              color: '#C2C5C8'
            }}>

              <PersonAddAlt1Icon sx={{ fontSize: 70 }} />

              <div>Add users to your account to create a team.</div>

            </Box>
          
          }

        </Box>
    
      );

};

export default TeamMembers;