

const getFormattedDateOfBirth = (dateOfBirth: string) => { 

    // Desired format: YYYY-MM-DD; 

    const reg = new RegExp('^[0-9\-/]+$'); 

    if (dateOfBirth) { 

        if (dateOfBirth.length === 11) { 

            return dateOfBirth.substring(0, dateOfBirth.length -1);

        } else if (dateOfBirth.length <= 3) {
        
            if (reg.test(dateOfBirth)) { 
    
                return dateOfBirth; 
    
            } else { 
    
                return dateOfBirth.substring(0, dateOfBirth.length -1);
    
            }; 
    
        } else if (dateOfBirth.length === 4) {

            if (reg.test(dateOfBirth)) { 
    
                return `${dateOfBirth}-`; 
    
            } else { 
    
                return dateOfBirth.substring(0, dateOfBirth.length -1);
    
            }; 


        } else if (dateOfBirth.length === 7) { 

            const reg = new RegExp('^[0-9\-/]+$'); 

            if (reg.test(dateOfBirth)) { 

                return `${dateOfBirth}-`; 

            } else { 

                return dateOfBirth.substring(0, dateOfBirth.length -1);

            }; 


        } else if ((dateOfBirth.length === 5) || (dateOfBirth.length === 6) || (dateOfBirth.length === 9) || (dateOfBirth.length === 10)) { 

            return dateOfBirth; 
        }; 

    } else { 

        return ''; 

    }; 




}; 

export default getFormattedDateOfBirth; 