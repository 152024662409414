import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setMain } from '../../../../redux/features/hacker/users/createHacker';

// Components 

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


const Access: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const main = useAppSelector(state => state.createHacker.main); 

  const [isMain, setIsMain] = useState(false); 

  useEffect(() => { 

    dispatch(setMain(isMain)); 

  },[isMain]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginBottom: '3vh',
        marginLeft: 1
    }}>

        <FormGroup>
            <FormControlLabel control={ <Switch onChange={(e) => setIsMain(e.target.checked)} /> } label="Main" />
        </FormGroup>

    </Box>

  );

};

export default Access;