import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Routes 

import { assignFile } from './../../../../../../routes/admin/team';

// Redux 

import { setNotificationMessage } from './../../../../../../redux/features/general/notification';
import { setFile } from './../../../../../../redux/features/admin/dashboard/documents/file';
import { setEmptyNotesList } from './../../../../../../redux/features/admin/dashboard/documents/refreshNotes';
import { refreshFiles } from './../../../../../../redux/features/admin/dashboard/documents/refreshFiles';

const AssignmentContainer: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const file = useAppSelector(state => state.adminFile.file); 
  const selectedTeam = useAppSelector(state => state.adminFile.selectedTeam); 

  const handleFileAssignment = async () => { 

    const res = await assignFile(file._id, selectedTeam); 

    if (res.status === 200) { 

      dispatch(setNotificationMessage('File successfully revoked')); 

      dispatch(setFile(null)); 

      dispatch(setEmptyNotesList(true)); 

      dispatch(refreshFiles(true)); 

    }; 

  }; 

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column-reverse',
        alignItems: 'center', 
        width: '95%',
        height: '15%', 
        marginTop: '10%',
    }}>


      { (file && selectedTeam) ? 
      <Button 
        onClick={() => handleFileAssignment()}
        variant="contained" 
        sx={{ 
          width: '90%',
          backgroundColor: 'green',
          marginBottom: 2,
         }}>Assign</Button>: 
        <Button 
         variant="contained" 
         disabled
         sx={{ 
           width: '90%',
           backgroundColor: 'green',
           marginBottom: 2,
          }}>Assign</Button>} 

    </Box>

  );

};

export default AssignmentContainer; 