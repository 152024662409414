import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { setAddress, setDateOfBirth, setEmail, setFirstName, setGender, setGroupNumber, setHealthCardNumber, setLanguagePreference, setLastName, setPhone, setPolicyNumber, setPrimaryDoctor, setProvider } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import moment from 'moment';

// Redux 

const EditUpdater: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const editedPatient = useAppSelector(state => state.createPatient.editedPatient); 

    const [genderDisplayed, setGenderDisplayed] = useState(''); 

    useEffect(() => { 

        if (editedPatient) { 

            // Patient Info

            dispatch(setFirstName(editedPatient.firstName));
            dispatch(setLastName(editedPatient.lastName));
            dispatch(setGender({ _id: editedPatient?.gender, stringDisplayed: editedPatient?.gender })); 
            dispatch(setDateOfBirth(moment(editedPatient.setDateOfBirth).format('YYYY-MM-DD'))); 

            // Contact Info 

            dispatch(setHealthCardNumber(editedPatient.healthCardNumber));
            dispatch(setEmail(editedPatient.contactInfo.email));
            dispatch(setPhone(editedPatient.contactInfo.phone));
            dispatch(setAddress(editedPatient.contactInfo.address)); 

            // Insurance details 

            dispatch(setProvider(editedPatient.insuranceDetails.provider));
            dispatch(setPolicyNumber(editedPatient.insuranceDetails.policyNumber));
            dispatch(setGroupNumber(editedPatient.insuranceDetails.groupNumber));

            // Extra Information 

            dispatch(setLanguagePreference({ _id: editedPatient.languagePreference, stringDisplayed: editedPatient?.languagePreference  }));
            dispatch(setPrimaryDoctor(editedPatient.primaryDoctor));

        }; 

    },[editedPatient]); 

    return <></>; 

};

export default EditUpdater;