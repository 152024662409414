import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Utils 

import getRandomColor from '../../../../../../../util/style/getRandomColor';
import getStatusColor from '../../../../../../../util/style/getStatusColor';

// Routes 

// Components 

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getAccessRequestPatientInfo, getMetaPatientInfo } from '../../../../../../../routes/doctor/patients';

type Props = {
    accessRequestId?: any, 
    patientId?: string, 
    status: string,
    updatedAt?: string, 
};


const AccessRequestRow: React.FC<Props> = ({ accessRequestId, patientId, status, updatedAt }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const [descriptionColor, setDescriptionColor] = useState(''); 
    const [patientName, setPatientName] = useState(''); 
    const [statusColor, setStatusColor] = useState(''); 


    const handlePatientInfo = async () => { 

        const res = await getAccessRequestPatientInfo(accessRequestId); 

        if (res?.data.status === 200) { 

            setPatientName(`${res.data.patientInfo.firstName} ${res.data.patientInfo.lastName}`); 

        }; 

    }; 


    useEffect(() => { 

        if (accessRequestId) { 

            handlePatientInfo(); 

        }; 

    },[accessRequestId]); 

    // -- 

    useEffect(() => { 

        if (status) { 

            setStatusColor(getStatusColor(status)); 

        };

    },[status]); 


    return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
    }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                color: colors?.text, 
                width: '18%', 
                marginLeft: '2%',
                textAlign: 'left',
                overflow: 'hidden',
            }}>{patientName}
            </div>

            <div style={{ 
                width: '50%', 
                marginLeft: '5%',
                color: descriptionColor, 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>                
                <strong style={{ 
                    padding: 5,
                    borderRadius: 7,
                    backgroundColor: statusColor, 
                }}>
                {status}
            </strong></div>

            <div style={{ 
                width: '15%', 
                color: colors?.text, 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{updatedAt}</div>

            <div style={{ 
                width: '10%', 
                color: colors?.text, 
                textAlign: 'center',
                overflow: 'hidden', 
            }}>
                <MoreVertIcon></MoreVertIcon>
            </div>

    </Box>

  );

};

export default AccessRequestRow;