
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Routes


// Components

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Box from '@mui/material/Box';
import CorrectionRow from './CorrectionRow';
import Logo from '../../../../../../general/navBar/Logo';
import CircularProgress from '@mui/material/CircularProgress';

const CorrectionsList: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const corrections = useAppSelector(state => state.correctFile.corrections); 
    const isServerCorrecting = useAppSelector(state => state.correctFile.isServerCorrecting); 

    // -- 

    const selectCorrection = async (metaPatient: any) => { 

    }; 

    // -- 

    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%',
                height: '80%', 
                marginTop: 1,
            }}
        >

        { corrections?.length >= 1 && 

            <Box 
                
                sx={{ 
                overflow: 'auto',
                width: '100%',
                height: '100%',
                marginTop: 1,
                scrollbarWidth: 'none' }}>
        
                {corrections?.map((item, index) => (

                    <CorrectionRow 
                        mistake={item.mistake}
                        correction={item.correction}
                    />

                ))}
        
            </Box>}

        { isServerCorrecting && 
        
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 2,
                }}
            >

                <Box
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 2,
                        marginBottom: 1, 
                    }}
                >

                    <Logo />

                    <Box
                        sx={{ marginLeft: 3 }}
                    >
                        <svg width={0} height={0}>
                            <defs>
                            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                                <stop offset="0%" stopColor="#e01cd5" />
                                <stop offset="100%" stopColor="#1CB5E0" />
                            </linearGradient>
                            </defs>
                        </svg>
                        <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
                    </Box>

                </Box>

                <Box
                    sx={{ 
                        color: colors?.text,
                        fontSize: 12,  
                        textAlign: 'center' }}
                >{t('serverProcessingCorrections')}
                </Box>

            </Box>}

          </Box>

    );

};

export default CorrectionsList;