import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setActivityData } from '../../../../redux/features/hacker/data/hackerData';

// Routes 

import { getActivityData } from '../../../../routes/hacker/users';

// Components 

import Box from '@mui/material/Box';
import ActivityChart from './chart/ActivityChart';


const Activity: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const activityData = useAppSelector(state => state.hackerData.activityData); 

    // -- 

    const handleData = async () => { 

      const res = await getActivityData() as any;  

      if (res.data.status === 200) { 

        dispatch(setActivityData(res.data.data)); 

      }; 

    }; 

    // -- 

    useEffect(() => { 

        if (!activityData) { 
  
          handleData();
  
        };
  
    },[activityData]);

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: '100%', 
      }}>

        <ActivityChart /> 

      </Box>

  );

};

export default Activity;