import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Redux 

import { setDashboardSection } from './../../../../../redux/features/general/navigation'; 


const TeamActions: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const handleCreateTeam = () => { 

    dispatch(setDashboardSection('createTeam')); 

  };

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column-reverse',
        alignItems: 'center', 
        width: '95%',
        height: '35%', 
    }}>

      <Button 
        onClick={() => handleCreateTeam()}
        variant="contained" 
        sx={{ 
          width: '90%',
          backgroundColor: 'green',
          marginBottom: 4,
         }}>Create Team</Button>

    </Box>

  );

};

export default TeamActions; 