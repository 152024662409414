import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { createTemplateTextField, deleteTemplateTextField, saveTemplate } from '../../../../routes/doctor/templates';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { colorToComponents, degrees, PDFDocument, rgb, StandardFonts, setFontAndSize, TextAlignment, NonFullScreenPageMode  } from 'pdf-lib';

// Components 

import Box from '@mui/material/Box';
import EventLimiter from '../../../../util/events/EventLimiter';
import { setNotificationMessage } from '../../../../redux/features/general/notification';
import { setIsComingFromTemplateEditing, setMode } from '../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setFile, setTitle } from '../../../../redux/features/doctor/Dashboard/documents/uploadFile';
import { setTemplateMode } from '../../../../redux/features/template/template';
interface Props { 
    children?: React.ReactNode;
    title: string; 
    action: string; 
}; 

const DropdownRow: React.FC<Props> = ({ children, title, action }) => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const currentPdfBytes = useAppSelector(state => state.template.currentPdfBytes); 
    const templateId = useAppSelector(state => state.template.templateId); 
    const doc = useAppSelector(state => state.template.doc); 
    const pendingTextFields = useAppSelector(state => state.template.pendingTextFields); 
    const removedTextFields = useAppSelector(state => state.template.removedTextFields); 

    // -- 

    const handleFileAction = async () => { 

        if (action === 'saveTemplate') { 

            dispatch(setNotificationMessage(t('templateSaved'))); 

        } else if (action === 'saveAsPdf') { 

            // -- 

            const formatTemplate = async () => { 

                dispatch(setTemplateMode('uploading')); 

                return; 

            }; 

            await formatTemplate(); 

            const documentComponent = document.getElementById('template-pdf') as any;

            const canvas = await html2canvas(documentComponent);
    
            const imgData = canvas.toDataURL('image/png');
    
            const pdfDoc = await PDFDocument.create();
    
            const image = await pdfDoc.embedPng(imgData);
    
            const page = pdfDoc.addPage([image.width, image.height]);
            page.drawImage(image, { x: 0, y: 0, width: image.width, height: image.height });
    
            const pdfBytes = await pdfDoc.save();
    
            const titlePlaceHolder = `file_${templateId.slice(0, 20)}_${Math.floor(Math.random() * (100000 - 1 + 1) + 1)}.pdf`; 
    
            const newFile = new File([pdfBytes], titlePlaceHolder, { type: "application/pdf" });
    
            dispatch(setMode('uploadFile')); 
            dispatch(setFile(newFile)); 
            dispatch(setTitle(titlePlaceHolder)); 
            dispatch(setIsComingFromTemplateEditing(true)); 
    
            navigate('/dashboard');
        
        }; 

    };

    // -- 

    return (
            
            <Box
                onClick={handleFileAction}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: 40,
                    fontSize: 12,  
                    borderRadius: 3,
                    '&:hover': { backgroundColor: 'rgb(182,184,206)'}
                }}
            >
                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                >
                    {children}
                    <Box sx={{ marginLeft: 3 }}>{t(title)}</Box>
                </Box>

        </Box>

  );

};

export default DropdownRow;