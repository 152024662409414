import React, { useState, useEffect }from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { setDashboardSection } from '../../../../redux/features/general/navigation';
import { resetCreateDoctor } from '../../../../redux/features/hacker/users/createDoctor';

const BackButton: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 

    const section = useAppSelector(state => state.navigation.section);

  // -- 

  const changeSection = () => { 

    dispatch(resetCreateDoctor()); 
    dispatch(setDashboardSection('doctors')); 

  }; 

  // -- 

  return (

    <Box
        onClick={changeSection}
        sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        width: 100, 
        padding: 1, 
        cursor: 'pointer', 
        color: '#C2C5C8',
        borderRadius: 5, 
        '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.4)' }
    }}>
        <ArrowCircleLeftOutlinedIcon /> 

        <Box sx={{ marginLeft: 2, paddingRight: 1 }}>Back</Box>

    </Box>

  );

};

export default BackButton;