import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Utils 

import getRandomColor from '../../../../../util/style/getRandomColor';
import getStatusColor from '../../../../../util/style/getStatusColor';

// Routes 

// Components 

import MoreVertIcon from '@mui/icons-material/MoreVert';
import BarChartIcon from '@mui/icons-material/BarChart';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

type Props = {
    category: any
    lastDay: any
    variation: any
    average: any
    total: any
};


const DataRow: React.FC<Props> = ({ category, lastDay, variation, average, total }) => {

    const dispatch = useAppDispatch(); 

    const [volatility, setVolatility] = useState('low');
    const [variationColor, setVariationColor] = useState('transparent'); 
    const [formattedVariation, setFormattedVariation] = useState(''); 
    
    useEffect(() => { 

        if (variation < 0) { 

            setVariationColor('rgb(45, 207, 62)');
            setFormattedVariation(`+ ${Math.abs(variation)} %`);

        } else if (variation == 0) { 

            setVariationColor('#C2C5C8');
            setFormattedVariation(`${Math.abs(variation)} %`);

        } else if (variation > 0) { 

            setVariationColor('rgb(240, 105, 2)');
            setFormattedVariation(`- ${Math.abs(variation)} %`);
        }; 

        const formattedPercentage = Math.abs(variation); 

        if (formattedPercentage <= 5) { 

            setVolatility('low'); 

        } else if (formattedPercentage <= 10) { 

            setVolatility('medium');

        } else if (formattedPercentage > 10) { 

            setVolatility('high'); 

        }; 

    },[variation]); 

  return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 15, 
      height: 50,
      color: '#C2C5C8', 
    }}>
            {/* Category */}

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '13%', 
                marginLeft: '2%',
                textAlign: 'left',
                overflow: 'hidden',
            }}>{category}
            </div>

            {/* Status */}

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>
                { volatility === 'low' && <BarChartIcon sx={{ color: 'rgba(45, 207, 196, 0.3)'}} />}
                { volatility === 'medium' && <EqualizerIcon sx={{ color: 'rgba(45, 207, 196, 0.7)'}} />}
                { volatility === 'high' && <EqualizerIcon sx={{ color: 'rgb(240, 105, 2)' }} />}

            </div>


            {/* lastDay */}

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{lastDay}
            </div>

            {/* Variation */}

            <div style={{ 
                width: '15%', 
                color: variationColor, 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{formattedVariation}
            </div>

            {/* Average */}

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{average}</div>

            {/* Total */}

            <div style={{ 
                width: '15%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{total}</div>

            <div style={{ 
                width: '10%', 
                color: '#C2C5C8', 
                textAlign: 'center',
                overflow: 'hidden', 
            }}>
                <MoreVertIcon></MoreVertIcon>
            </div>

    </Box>

  );

};

export default DataRow;