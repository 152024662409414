import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';

interface Props { 
    children?: React.ReactNode
    title: string,
    data: any, 
    dataColor?: string
}

const DataBox: React.FC<Props> = ({ children, title, data, dataColor }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: 200,
          height: 100, 
          marginLeft: 2,
          marginRight: 2, 
          borderRadius: 5,
          backgroundColor: '#383A40',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      }}>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                paddingLeft: 3,
                paddingTop: 2, 
                width: '100%',
                height: '20%', 
                color: '#C2C5C8'
            }}
        >{title}

        </Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                
                height: '80%', 
                color: '#C2C5C8'
            }}
        >

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    fontSize: 30,
                    paddingLeft: 3,
                    height: '100%', 
                }}
            >
                {children}
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    fontSize: 30, 
                    marginLeft: 3,
                    height: '100%', 
                    color: dataColor ? dataColor : 'rgb(45, 207, 196)'
                }}
            >
                {data}
            </Box>

        </Box>

      </Box>

  );

};

export default DataBox;