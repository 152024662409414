import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Routes & Redux 

import { createTemplate } from '../../../../../routes/doctor/templates';
import { setQrValue, setTempLinkId } from '../../../../../redux/features/doctor/Dashboard/templates/templates';

// Components 

import Template from '../list/templatesList/Template';
import CodeBox from './CodeBox';
import BackButton from './BackButton';
import Footer from './Footer';
import Header from './Header';


const CreateTemplate: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();
    
    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector((state) => state.templates.section); 

    // --

    const handleTemplateCreation = async () => { 

        const res = await createTemplate() as any; 

        if (res?.data.status === 200) { 

            dispatch(setQrValue(res.data.tempLinkUrl)); 
            dispatch(setTempLinkId(res.data.tempLinkId)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (section === 'createTemplate') {

            handleTemplateCreation(); 

        }; 

    },[section]);

    return (

        <Box 
            sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: '95%',
            height: '95%', 
            borderRadius: 5,
            backgroundColor: colors?.container,
            overflowY: 'auto',
            overflowX: 'hidden',
            transition: 'width 0.3s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        }}>

            <Header /> 

            <CodeBox /> 

            <Footer /> 

            <BackButton /> 
            
        </Box>

    );

};

export default CreateTemplate;