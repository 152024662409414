import React from 'react';
import Box from '@mui/material/Box';

import TeamsList from './TeamsList';
import TeamActions from './TeamActions';
import Header from './Header';

const ListContainer: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '20%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#313338',
    }}>

        <Header />

        <TeamsList />

        <TeamActions />

    </Box>

  );

};

export default ListContainer;