import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Routes 

import { getTeams } from './../../../../../../routes/admin/team';

// Redux 

import { setAssignedTeam } from './../../../../../../redux/features/admin/dashboard/documents/file';

// Icons 

import PeopleIcon from '@mui/icons-material/People';


const TeamsList: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const [teams, setTeams] = useState(Array<any>); 

    const getTeamsList = async () => { 

      const res = await getTeams(); 

      if (res.status === 200) { 

        setTeams(res.teamsList); 

      };
      
    }; 

    const handleTeamSelection = async (teamId: string) => { 

        dispatch(setAssignedTeam(teamId)); 

    }; 

    useEffect(() => { 

      getTeamsList(); 

    },[]);

    
      return (

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '40%',
          }}>
  
            <Box sx={{ 
              overflow: 'auto',
              width: '95%' }}>
      
              <List dense>
      
                {teams?.map((item, index) => (
      
                  <ListItem  onClick={() => handleTeamSelection(item._id)}button key={index}>
      
                      { item.type !== 'header' && 
                        
                        <ListItemIcon sx={{ color: '#C2C5C8' }}>
                          <PeopleIcon />
                        </ListItemIcon>}
      
                      { item.type !== 'header' && <ListItemText sx={{ color: '#C2C5C8' }} primary={item.name} />}

                      { item.type === 'header' && 
                          <ListItemText 
                              sx={{ 
                                  marginTop: '2vh',
                                  marginBottom: '2vh',
                                  textAlign: 'left',
                                  color: '#C2C5C8' }} 
                                  primary={item.title} 
                          />}
      
                  </ListItem>
                ))}
      
              </List>
      
            </Box>

          </Box>
      );

};

export default TeamsList;