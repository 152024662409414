import React, {useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { resetPatient } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';

// Routes 

// Components 

import DashboardIcon from '@mui/icons-material/Dashboard';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const OverviewButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector(state => state.createPatient.section) as string; 
    const patient = useAppSelector((state) => state.patient) as any; 
    const incompletedCategories = useAppSelector((state) => state.createPatient.incompletedCategories);
    const completedFields = useAppSelector((state) => state.createPatient.completedFields);

    const [allowSubmitButton, setAllowSubmitButton] = useState(true); 


    const handleNavigation = () => { 

        dispatch(resetPatient()); 

    }; 

  // -- 

    return (

        <Box 
            onClick={handleNavigation}
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 1,
            height: 50,
            width: '100%',
            cursor: 'pointer',
            '&:hover': { backgroundColor: colors?.overviewRowHover }
        }}>

            <DashboardIcon sx={{ marginLeft: 2, color: colors?.overviewIcon }}/>

            <Box sx={{ marginLeft: 2 }}>Overview</Box>


        </Box>

  );

};

export default OverviewButton;