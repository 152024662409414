import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface SupportTicketsAccessState {
  supportTickets: any[],
  isRefreshSupportTicketsList: boolean, 
  initialRendering: boolean, 
}

// ---------------

const initialState: SupportTicketsAccessState = {
    supportTickets: [], 
    isRefreshSupportTicketsList: false, 
    initialRendering: true, 
};

// ---------------


export const supportTicketsAccessSlice = createSlice({

  name: 'supportTicketsAccess',

  initialState,

  reducers: {

    setSupportTickets: (state, action: PayloadAction<any>) => { 

        state.supportTickets = action.payload; 

    },
    setIsRefreshSupportTicketsList: (state, action: PayloadAction<boolean>) => { 

      state.isRefreshSupportTicketsList = action.payload; 

    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetSupportTicketsAccess: (state) => { 
        
      state.supportTickets = []; 
      state.isRefreshSupportTicketsList = false;
      state.initialRendering = true; 

    } 

  },

}); 

// ---------------

export const { 
  setSupportTickets, 
  setIsRefreshSupportTicketsList, 
  setInitialRendering, 
  resetSupportTicketsAccess } = supportTicketsAccessSlice.actions;

export default supportTicketsAccessSlice.reducer;