import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Icons 

import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import TaskIcon from '@mui/icons-material/Task';
import InventoryIcon from '@mui/icons-material/Inventory';
import MoveUpIcon from '@mui/icons-material/MoveUp';

type Props = {
  title: string,
  type: string,
  data: any,
  author?: any,
};


const DataBox: React.FC<Props> = ({title, type, data}) => {

    const [description, setDescription] = useState(''); 

  return (

    <div style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      justifyContent: 'space-evenly',
      width: '80%',
      height: '25%',
      borderRadius: 10,
      marginTop: 4,
      marginBottom: 5,
      fontSize: 12,
      backgroundColor: '#383A40'
    }}>

        <div 
            style={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: '25%',
                height: '100%',
        }}>

           {type === 'waiting' && <HourglassBottomIcon sx={{ color: 'orange' }}/>} 
           {type === 'review' && <RateReviewIcon sx={{ color: '#91048A' }}/>} 
           {type === 'revoked' && <RemoveCircleOutlineIcon sx={{ color: 'red' }}/>} 

           {type === 'completed' && <TaskIcon sx={{ color: '#0AC5CF' }}/>} 
           {type === 'dailyAverage' && <MoveUpIcon sx={{ color: '#279E06' }}/>} 
           {type === 'archived' && <InventoryIcon sx={{ color: '#CF870A' }}/>} 

        </div>

        <div
            style={{
                display: 'flex', 
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                width: '60%',
                height: '100%'}}>

            <div style={{ fontSize: 15, color: '#C2C5C8', textAlign: 'left' }}>{title}</div>

            <div style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'left' }}>{data}</div>

        </div>


    </div>

  );

};

export default DataBox;