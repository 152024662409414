import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 


// --- 

export const getAvailableEmployees = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/createTeam/employees`, getConfig()); 

        if (res.status === 200) { 

            const availableEmployees = res.data.availableEmployees as Array<object>; 

            return availableEmployees; 
              
        };

    } catch (error) {
        console.error('Error getting a list of available employees:', error);
    };

};

// -- 

export const createTeam = async (teamName: string, teamMembers: string[]) => {

    try {

        const content = { 
            teamName: teamName, 
            teamMembers: teamMembers
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/createTeam`, content, getConfig()); 

        if (res.status === 200) { 

            const newTeam = res.data.newTeam as Array<object>; 

            const response = { 
                status: 200, 
                newTeam: newTeam
            }; 

            return response; 
              
        };

    } catch (error) {
        console.error('Error creating a team:', error);
    };

};

// -- 

export const getTeams = async () => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/documents/teams`, getConfig()); 

        if (res.status === 200) { 

            const teams = res.data.availableTeams as Array<object>; 

            const response = { 
                status: 200, 
                teamsList: teams
            }; 

            return response as any; 
              
        }; 
        
    } catch (error) {
        console.error('Error fetching the teams:', error);
    };

};

// -- 

export const assignFile = async (fileId: any, teamId: string) => { 

    try {

        const content = { 
            fileId: fileId, 
            teamId: teamId
        };  

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/documents/assign`, content, getConfig()); 

        if (res.status === 200) { 

            return res.status as any; 
              
        }; 
        
    } catch (error) {
        console.error('Error fetching the teams:', error);
    };

};
