import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

type Props = {
  firstName?: string,
  lastName?: string,
  email: string,
  main?: boolean,
  createdAt?: string, 
};


const HackerRow: React.FC<Props> = ({firstName, lastName, email, main, createdAt }) => {

  return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
    }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '18%',
                marginLeft: '2%',  
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>{firstName} {lastName}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{email}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{main ? 'Yes' : 'No'}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{createdAt}</div>

    </Box>

  );

};

export default HackerRow;