import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateHackerState {
  firstName: string,
  lastName: string,
  email: string,
  main: boolean
}

// ---------------

const initialState: CreateHackerState = {
    firstName: '', 
    lastName: '',
    email: '',
    main: false
};

// ---------------


export const createHackerSlice = createSlice({

  name: 'createHacker',

  initialState,

  reducers: {

    setFirstName: (state, action: PayloadAction<string>) => {

        state.firstName = action.payload; 
    },
    setLastName: (state, action: PayloadAction<string>) => { 
        
        state.lastName = action.payload;  
    },
    setEmail: (state, action: PayloadAction<string>) => { 
        
        state.email = action.payload; 
    },
    setMain: (state, action: PayloadAction<boolean>) => { 
        
        state.main = action.payload; 
    },
    resetCreateHacker: (state) => { 
        
        state.firstName = '';
        state.lastName = '';
        state.email = '';
        state.main = false; 
    } 

  },

}); 

// ---------------

export const { 
    setFirstName, 
    setLastName, 
    setEmail, 
    setMain, 
    resetCreateHacker } = createHackerSlice.actions;

export default createHackerSlice.reducer;