import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Redux 

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { setCurrentFolder, setSearchSection } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Routes 

import { getFolderDataByPath } from '../../../../../routes/doctor/files';

// Utils 

import getFormattedPathName from '../../../../../util/dashboard/doctor/files/getFormattedPathName';
import getFormattedDirectory from '../../../../../util/dashboard/doctor/files/getFormattedDirectory';

const Directory: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const directory = useAppSelector((state) => state.fileExplorer.directory); 
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder); 
    const accountInfo = useAppSelector((state) => state.meta.accountInfo); 

    const [pathName, setPathName] = useState([]) as any; 

    // -- 

    const changeDirectory = async (section: any, index: any) => { 

        if (section !== currentFolder.title) { 

            if (section == 'Shared') { 

                dispatch(setSearchSection('rootSharedFolder')); 

            } else if (directory.startsWith(`Patients/${currentFolder.relatedPatient}`) && (pathName[2] === 'Shared') && (index == 3)) {
                
                dispatch(setSearchSection('rootSharedDoctorFolder')); 

            } else { 

                const path = getFormattedPathName(section, directory, currentFolder); 

                const res = await getFolderDataByPath(path);

                if (res?.data.status === '200') {

                    const { folder } = res.data; 

                    if (folder) { 

                        dispatch(setCurrentFolder(folder)); 

                    };

                };

            }; 

        } else if ((currentFolder._id === 'shared') && (section === 'Shared')) { 

            dispatch(setSearchSection('rootSharedFolder')); 

        }; 

    }; 

    // -- 

    const handleDirectoryFormatting = async () => { 

        const formattedPathName = await getFormattedDirectory(directory, currentFolder, accountInfo?._id); 

        setPathName(formattedPathName);

    }; 

    // -- 

    useEffect(() => { 

        if (directory.includes('/')) { 

            handleDirectoryFormatting(); 

        } else { 

            setPathName([directory]);

        };

    },[directory]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '75%', 
            overflowX: 'scroll',
            scrollbarWidth: 'none', 
            marginLeft: 10, 
            fontSize: 15,
        }}>

        {pathName?.map((item, index) => (

             <Box 
                key={index}
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
            }}>
        
                <Box 
                    onClick={() => changeDirectory(item, index)}
                    sx={{ 
                        marginLeft: 1,
                        cursor: index === directory?.length - 1 ? 'default': 'pointer', 
                        '&:hover': { color: index === directory?.length - 1 ? colors?.highlightedText : colors?.directory }
                    }}
                >{item}</Box>
                <Box sx={{ marginLeft: 1 }}>{'>'}</Box>

            </Box>

        ))}
            
        </Box>

    );

};

export default Directory;