import React from 'react';
import Box from '@mui/material/Box';

const Logo: React.FC = () => {

  return (
    <Box
      component="img"
      sx={{
        height: 50,
        width: 'auto',
        position: 'absolute',
        top: '25vh',
        left: '15vw',
      }}
      alt="Solutions Medca Inc."
      src={require('../../assets/logos/medca_logo_blue_white.png')}
    />
  );
};

export default Logo;