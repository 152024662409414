import React from 'react';
import Box from '@mui/material/Box';

// Components

import DataBox from './items/DataBox';

const ArchivedData: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '45%',
        height: '95%', 
        borderRadius: 5,
    }}>

      <DataBox title='Completed (Today)' type='completed' data='78'/>

      <DataBox title='Daily Average' type='dailyAverage' data='62'/>

      <DataBox title='Archived' type='archived' data='426'/>

    </Box>

  );

};

export default ArchivedData;