import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Components 

import TextField from '@mui/material/TextField';

interface Props { 
    title: string
}

const SectionHeader: React.FC<Props> = ({title}) => {

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '5vh',
        marginTop: '3vh',
        marginBottom: '3vh',
    }}>{title}
    </Box>

  );

};

export default SectionHeader;