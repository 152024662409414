import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';


// Icons 

import GroupWorkIcon from '@mui/icons-material/GroupWork';


const Header: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center', 
        width: '95%',
        height: '10%', 
    }}>

        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: '95%',
            height: '50%', 
        }}>
                
                <div style={{   
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '95%',
                    height: '50%', 
                    color: '#C2C5C8'
                }}>Selected Team</div>

                <div style={{
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '95%',
                    height: '50%', 
                    marginTop: 25,
                }}>
                    <GroupWorkIcon sx={{ fontSize: 25, color: 'lightblue'}}></GroupWorkIcon>

                    <div style={{ fontSize: 25, marginLeft: 15 }}>Legal</div>
                </div>

        </div>


    </Box>

  );

};

export default Header; 