
// Routes 

import { getDoctorName, getMetaPatientInfo } from '../../../../routes/doctor/patients';

const getFormattedDirectory = async (directory: string, folder: any, doctorId: any) => { 

    // We split the directory. 

    let list = [] as any; 
    list = directory.split('/'); 

    if (directory.startsWith('Patients/')) { 

        // Patients/672d61efdee9cdb0a7491d4a/emergency etc...

        // list = ['Patients', '672d61efdee9cdb0a7491d4a', 'emergency'];

        // We want the patient ID to be replaced by the patient's full name and birth date. 

        const res = await getMetaPatientInfo(folder.relatedPatient); 

        if (res?.data.status === 200) { 

            list[1] = res.data.metaPatient.fullName; 

            if ((folder.uploadedBy) && (folder.uploadedBy != doctorId)) { 

                // Patients/672d61efdee9cdb0a7491d4a/emergency etc...

                const res = await getDoctorName(folder.uploadedBy) as any; 

                list.splice(2, 0, 'Shared'); 
                list.splice(3, 0, res.data.fullName); 

                // Patients/672d61efdee9cdb0a7491d4a/Shared/John Doe/Folder etc...

                return list; 

            } else { 

                return list; 

            }; 

        }; 

    } else { 
    
        return list; 

    }; 



}; 

export default getFormattedDirectory; 