import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components 

import FormContainer from './createUser/FormContainer';

const CreateUserInterface: React.FC = () => {

  return (

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '90%',
      height: '95%', 
      left: '10%',
      bottom: 0,
      position: 'absolute',
      backgroundColor: '#201F1E',
    }}>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '70%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#313338',
      }}>

        <FormContainer />

      </Box>

      </Box>

  );

};

export default CreateUserInterface;