import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateDoctorState {
  firstName: string,
  lastName: string,
  email: string,
}

// ---------------

const initialState: CreateDoctorState = {
    firstName: '', 
    lastName: '',
    email: '' 
};

// ---------------


export const createDoctorSlice = createSlice({

  name: 'createDoctor',

  initialState,

  reducers: {

    setFirstName: (state, action: PayloadAction<string>) => {

        state.firstName = action.payload; 
    },
    setLastName: (state, action: PayloadAction<string>) => { 
        
        state.lastName = action.payload;  
    },
    setEmail: (state, action: PayloadAction<string>) => { 
        
        state.email = action.payload; 
    },
    resetCreateDoctor: (state) => { 
        
        state.firstName = '';
        state.lastName = '';
        state.email = '';
    } 

  },

}); 

// ---------------

export const { 
    setFirstName, 
    setLastName, 
    setEmail, 
    resetCreateDoctor } = createDoctorSlice.actions;

export default createDoctorSlice.reducer;