import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface RefreshNotesState {
  refreshNotes: boolean,
  emptyNotes: boolean, 
}

// ---------------

const initialState: RefreshNotesState = {
  refreshNotes: false,
  emptyNotes: false,
};

// ---------------


export const refreshNotesSlice = createSlice({

  name: 'refreshNotes',

  initialState,

  reducers: {

    refreshNotes: (state, action: PayloadAction<boolean>) => {
        state.refreshNotes = action.payload
    },
    setEmptyNotesList: (state, action: PayloadAction<boolean>) => { 

      state.emptyNotes = action.payload
    }
  },

}); 

// ---------------

export const { refreshNotes, setEmptyNotesList } = refreshNotesSlice.actions;


export default refreshNotesSlice.reducer;