import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface SupportEmailState {
  category: string, 
  description: string,
};

// ---------------

const initialState: SupportEmailState = {
    category: '', 
    description: '', 
};

// ---------------


export const SupportEmailSlice = createSlice({

  name: 'supportEmail',

  initialState,

  reducers: {

    setDescription: (state, action: PayloadAction<any>) => {
        state.description = action.payload;
    },
    setCategory: (state, action: PayloadAction<any>) => {
        state.category = action.payload;
    },
    setResetSupportEmail: (state) => { 
        
        state.category =  '';
        state.description =  '';
    } 

  },

}); 

// ---------------

export const { 
    setDescription,
    setCategory,
    setResetSupportEmail } = SupportEmailSlice.actions;

export default SupportEmailSlice.reducer;