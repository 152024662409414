import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

// Component

import DescriptionBox from './DescriptionBox';

// Icons 

import ManageSearchIcon from '@mui/icons-material/ManageSearch';

const FAQ: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly', 
        alignItems: 'center',
        width: '100vw',
        minHeight: 200,
        marginTop: 3, 
        marginBottom: 10,
        color: colors?.legalDescription,
      }}
    >
        <DescriptionBox 
            icon={<ManageSearchIcon sx={{ fontSize: 50, marginBottom: 3 }} />}
            title={'FAQ'}
            description={'FAQ description coming soon.'}
            navigateTo={'/questions'}
        />

    </Box>
  );
};

export default FAQ;