import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { Box, Button, Typography, Grid, TextField, Snackbar, Alert } from '@mui/material';
import Container from '../components/scanTemplate/Container';

const ScanTemplate: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors);

  return (

    <Box 
      component="main" 
      sx={{ 
        display: 'flex', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText 
      }}>

        <Container /> 

    </Box>
  );
};

export default ScanTemplate;