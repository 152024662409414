import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setEmail } from '../../../../redux/features/hacker/users/createHacker';

// Components 

import TextField from '@mui/material/TextField';

const Email: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const email = useAppSelector(state => state.createHacker.email); 

  const [text, setText] = useState(''); 

  // Redux 

  useEffect(() => {

    dispatch(setEmail(text)); 

  },[text]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginBottom: '3vh',
    }}>

        <TextField 
          sx={{ width: '40%', alignSelf: 'center' }} 
          onChange={(e) => setText(e.target.value)}
          id="standard-basic" 
          label="User Email" 
          value={email}
          variant="standard" />


    </Box>

  );

};

export default Email;