import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface NavigationState {
  teamName: string,
  teamMembers: string[],
}

// ---------------

const initialState: NavigationState = {
    teamName: '', 
    teamMembers: [], 
};

// ---------------


export const createTeamSlice = createSlice({

  name: 'createTeam',

  initialState,

  reducers: {

    setTeamName: (state, action: PayloadAction<string>) => {
        state.teamName = action.payload
    },
    addTeamMember: (state, action: PayloadAction<string>) => { 
        
        state.teamMembers.push(action.payload); 
    },
    removeTeamMember: (state, action: PayloadAction<string>) => { 

        const currentTeam = state.teamMembers; 

        const memberToBeRemoved = currentTeam.indexOf(action.payload); 

        currentTeam.splice(memberToBeRemoved, 1); 

        state.teamMembers = currentTeam; 

    },
    resetCreateTeam: (state) => { 
        
      state.teamName = ''; 
      state.teamMembers = [];

    } 

  },

}); 

// ---------------

export const { setTeamName, addTeamMember, removeTeamMember, resetCreateTeam } = createTeamSlice.actions;

export default createTeamSlice.reducer;