import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Routes

import { deleteFile, deleteFolder, uploadFile } from '../../../../../routes/doctor/files';

// Redux 

import { selectFile, selectFolder, setCurrentFolder, setMode, setRefreshFileExplorer } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';
import { resetUploadFile, setIsFileUploaded, setTitle } from '../../../../../redux/features/doctor/Dashboard/documents/uploadFile';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const DataDeletion: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 
    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 

    const [path, setPath] = useState(''); 

    // -- 

    const cancelDeletion = () => { 

        dispatch(setMode('')); 
        dispatch(selectFile(null));
        dispatch(selectFolder(null));

    }; 

    // -- 

    const handleDeletion = async () => { 

        if (selectedFile) { 

            const res = await deleteFile(selectedFile._id) as any; 
    
            if (res.data.status === 200) { 

                dispatch(setMode('')); 

                dispatch(setNotificationMessage(t('fileDeleteOK'))); 
                dispatch(setRefreshFileExplorer(true)); 
                dispatch(selectFile(null)); 

            } else { 

                dispatch(setNotificationMessage(t('fileDeleteNotOK'))); 

            }; 

        } else if (selectedFolder) { 

            const res = await deleteFolder(selectedFolder._id) as any; 

            if (res.data.status === 200) { 

                dispatch(setMode('')); 

                dispatch(setNotificationMessage(t('folderDeleted'))); 
                dispatch(setRefreshFileExplorer(true)); 
                dispatch(selectFolder(null)); 

            }; 

        }; 

    };

    // -- 

    useEffect(() => { 

        if (selectedFile || selectedFolder) { 

            selectedFile && setPath(`${selectedFile?.path}/${selectedFile?.title}`); 
            selectedFolder && setPath(`${selectedFolder?.path}`); 

        }; 

    },[selectedFile, selectedFolder]); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 60, 
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '50%',
                    marginLeft: '2%', 
                    padding: 1,
                    borderRadius: 2,
                    backgroundColor: colors?.fileExplorerFooter
                }}
            >
                { selectedFolder && <FolderIcon sx={{ color: colors?.folder, fontSize: 15, marginLeft: 1 }} />}
                { selectedFile && <InsertDriveFileIcon sx={{ color: colors?.driveIcon, fontSize: 15, marginLeft: 5 }} />}

                <Box 
                    sx={{
                        fontSize: 12, 
                        marginLeft: 3
                    }}>
                    {path}
                </Box>

            </Box>

            <Box style={{ 
                display: 'flex', 
                flexDirection: 'row-reverse',
                alignItems: 'center',  
                width: '50%',
            }}>

                <Button 
                    onClick={() => cancelDeletion()} 
                    sx={{ 
                        marginLeft: 1,
                        marginRight: 3,
                        color: colors?.closeButtonText, 
                        backgroundColor: colors?.closeButtonBackground,
                        borderColor: colors?.closeButtonBackground,
                        '&:hover': 
                        { 
                            backgroundColor: colors?.closeButtonBackground,
                            color: colors?.closeButtonText,
                            borderColor: colors?.closeButtonBackground
                        }
                    }}
                    variant="contained">{t('cancel')}
                </Button>

                <Button 
                    onClick={() => handleDeletion()} 
                    sx={{ 
                        marginLeft: 5, 
                        backgroundColor: colors?.deleteUploadedFile,
                        '&:hover': { backgroundColor: colors?.deleteUploadedFileHover}
                    }} 
                    variant="contained">{t('confirm')}</Button>

                </Box>

        </Box>

    );

};

export default DataDeletion;