import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { setTemplatesSection } from '../../../../../../redux/features/doctor/Dashboard/templates/templates';

const CreateButton: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    const handleNavigation = () => { 

        dispatch(setTemplatesSection('createTemplate'));
    };

    // -- 

    return (

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: '20%', 
            height: 50, 
            cursor: 'pointer',
        }}>
            <Box
                onClick={handleNavigation}
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderRadius: 4,
                    paddingRight: 2,
                    paddingLeft: 2,
                    paddingTop: 1,
                    paddingBottom: 1, 
                    '&:hover': { backgroundColor: colors?.createTemplate }
                }}
            >
                <AddCircleOutlineIcon sx={{ color: colors?.createTemplateIcon, marginRight: 2 }} /> 
                <Box>Template</Box>
            </Box>
            
        </Box>

    );

};

export default CreateButton;