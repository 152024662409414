import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

const ListInfo: React.FC = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const folderData = useAppSelector((state) => state.fileExplorer.folderData); 
    const unconfirmedSelection = useAppSelector((state) => state.fileExplorer.unconfirmedSelection);

    return (
        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%', 
            fontSize: 10,
            marginBottom: 10,
            position: 'relative', 
            color: colors?.listInfo,
            fontWeight: 'bold', 
        }}>
            { folderData?.length > 0 && 
                <Box sx={{ marginLeft: 2 }}>
                    {unconfirmedSelection ? 1 : 0} {t('itemsSelected')} | {folderData?.length} {t('itemsTotal')}
                </Box>
            }
        </Box>
    );
};

export default ListInfo;