import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getBlobHeadersConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    }; 

    return config as any; 

}; 

// --

export const getRecentFiles = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/recent`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent files:', error);
    }
};

// --

export const getDoctorRecentFiles = async () => {

    try {

        // For the file Explorer (compared to getRecentFiles(), which only render files for the Overview section). 

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/doctor`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent files:', error);
    }
};

export const getPatientSharedFilesByDoctor = async (metaPatientId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/patient/shared/files/${metaPatientId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent files:', error);
    }
};

export const getPatientSharedDoctorsFolder = async (patientId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/patient/shared/doctors/${patientId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent files:', error);
    }
};

// --

export const getPatientsFolder = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/folders/patients`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting the doctor patients folder:', error);
    }
};

// -- 

export const getDoctorRootFolders = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/folders`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor root folders:', error);
    }
};

// --

export const getFolderData = async (folderId: string) => {

    try {

        const content = { 
            folderId: folderId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/data`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor folder:', error);
    }
};

// -- 

export const getFolderDataByPath = async (path: string) => {

    try {

        const content = { 
            path: path
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/path/data`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor folder:', error);
    }
};


// -- 

export const createFolder = async (newFolder: any) => {

    try {

        const content = { 
            title: newFolder.title, 
            folder: newFolder.folder,
            relatedPatient: newFolder.relatedPatient
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folders/create`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error creating a new folder:', error);
    }
};

// --

export const searchFileExplorer = async (currentFolder: any, searchQuery: string) => {

    try {

        const content = { 
            currentFolder: currentFolder, 
            searchQuery: searchQuery
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/search`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor folder:', error);
    }
};

// -- 

export const getSuggestedCorrections = async (fileId: string) => { 

    try {

        const content = { 
            fileId: fileId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ai/suggest-corrections`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor root documents:', error);
    }; 

}; 

// -- 

export const serveFile = async (fileId: string, type?: string) => { 

    try {

        const content = { 
            fileId: fileId, 
            type: type
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/serve`, content, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving a file.', error);
    }; 

}; 

// -- 

export const updateFile = async (fileId: string, title: string) => { 

    try {

        const content = { 
            fileId: fileId, 
            title: title, 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/update`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error updating a file.', error);
    }; 

}; 

// -- 

export const updateFolder = async (folderId: string, title: string) => { 

    try {

        const content = { 
            folderId: folderId, 
            title: title, 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/update`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error updating a folder.', error);
    }; 

}; 

// -- 

export const deleteFile = async (fileId: string) => { 

    try {

        const content = { 
            fileId: fileId,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/delete`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting a file.', error);
    }; 

}; 

// -- 

export const deleteFolder = async (folderId: string) => { 

    try {

        const content = { 
            folderId: folderId,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/delete`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting a folder.', error);
    }; 

}; 

// -- 

export const getRootFolder = async () => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/folder/root`, getConfig());

        return res;
        
    } catch (error) {
        console.error('Error retrieving the documents folder.', error);
    }; 

}; 

// -- 

export const uploadFile = async (formData: any) => {

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/upload`, formData, getConfig());

        return res; 

    } catch (error) {
        console.error('Error sending uploading a file:', error);
    };

};

// -- 

export const pinFolder = async (folderId: string) => {

    try {

        const content = { 
            folderId: folderId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/pin`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error pinning a folder', error);
    };

};

// -- 

export const removePinnedFolder = async (folderId: string) => {

    try {

        const content = { 
            folderId: folderId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/folder/remove/pin`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error pinning a folder', error);
    };

};

// -- 

export const getFileExplorerPatientsList = async () => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/patients`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error pinning the file explorer list of patients.', error);
    };

}; 

// -- 

export const getFileExplorerPatientInfo = async (patientId: string) => { 

    try {

        const content = { 
            patientId: patientId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/patient/info`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error pinning the file explorer patient informaiton', error);
    };

}; 

export const getPatientFolderByPatientId = async (patientId: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/files/patient/folder/${patientId}`, getConfig());

        return res; 

    } catch (error) {
        console.error('Error getting the patient folder by the patient id', error);
    };

}; 

// -- 

export const attachFileToPatient = async (fileId: string, patientId: string, title: string) => { 

    try {

        const content = { 
            fileId: fileId, 
            patientId: patientId,
            title: title
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/patient/attach`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error attaching a file to a patient', error);
    };

}; 

// --

export const detachFileFromPatient = async (fileId: string) => { 

    try {

        const content = { 
            fileId: fileId, 
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/patient/detach`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error detaching a file from a patient', error);
    };

}; 

// -- 

export const searchPatientInFileExplorer = async (patientName: string) => { 

    try {

        const content = { 
            patientName: patientName
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/patient/search`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Error attaching a file to a patient', error);
    };

}; 

// -- 

export const moveFile = async (fileId: string, folderId: string) => { 

    try {

        const content = { 
            fileId: fileId,
            folderId: folderId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/move`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Failed to move a file', error);
    };

}; 

// -- 

export const moveFolder = async (folderId: string, rootFolderId: string) => { 

    try {

        const content = { 
            folderId: folderId,
            rootFolderId: rootFolderId
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/files/move/folder`, content, getConfig());

        return res; 

    } catch (error) {
        console.error('Failed to move a folder', error);
    };

}; 