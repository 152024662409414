import React from 'react';
import { Container, Box, Typography, Link, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../components/general/navBar/LanguageSelector';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Logo from '../components/general/navBar/Logo';
import ThemeToggle from '../components/general/navBar/ThemeToggle';
import Footer from '../components/general/navigation/Footer';

const Faq: React.FC = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!ready) {
    return <div>Loading...</div>;
  }

  const faqContent = t('faqContent', { returnObjects: true }) as any[];

  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{
        mt: 4,
        mb: 4,
        padding: '40px',
        borderRadius: '8px',
        backgroundColor: theme.palette.mode === 'dark' ? '#222' : '#f5f5f5',
        color: theme.palette.mode === 'dark' ? '#ddd' : '#333',
      }}
    >
      <Box position="absolute" top={16} left={16}>
        <Link
          component="button"
          variant="body2"
          onClick={handleGoBack}
          sx={{
            color: theme.palette.mode === 'dark' ? 'gray' : '#666',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <ArrowBackIosIcon fontSize="small" sx={{ mr: 0.5 }} />
          {t('goBack')}
        </Link>
      </Box>
      <Box position="absolute" top={16} right={16}>
        <LanguageSelector />
      </Box>
      <Box position="absolute" top={16} right={150}>
        <ThemeToggle />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Logo size={30} />
        </Box>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            mb: 2,
            color: theme.palette.mode === 'dark' ? '#fff' : '#111',
          }}
        >
          FAQ
        </Typography>

        <Box sx={{ textAlign: 'justify', width: '100%' }}>
          {faqContent.map((faq, index) => (
            <Box key={index} sx={{ mb: 3 }}>
              <Typography
                variant="h6"
                sx={{
                  mb: 1,
                  color: theme.palette.mode === 'dark' ? '#e0e0e0' : '#222',
                }}
              >
                {faq.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.mode === 'dark' ? '#bbb' : '#555' }}
              >
                {faq.description}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <Footer isScrollPage={true} />
    </Container>
  );
};

export default Faq;