import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Routes

import { acceptFile, revokeFile } from './../../../../../../routes/admin/documents';

// Redux 

import { setFile as setFileCreateNote, setAuthor } from './../../../../../../redux/features/admin/dashboard/documents/createNote';
import { setNotificationMessage } from './../../../../../../redux/features/general/notification';
import { setFile } from './../../../../../../redux/features/admin/dashboard/documents/file';
import { setEmptyNotesList } from './../../../../../../redux/features/admin/dashboard/documents/refreshNotes';
import { refreshFiles } from './../../../../../../redux/features/admin/dashboard/documents/refreshFiles';

// Icons 

import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RateReviewIcon from '@mui/icons-material/RateReview';
import TaskIcon from '@mui/icons-material/Task';

const ActionsList: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const file = useAppSelector(state => state.adminFile.file); 

    const listItems = [

        { type: 'header', title: t('export')},

        { text: 'Download', action: 'download', icon: <DownloadIcon sx={{ color: 'grey' }} /> },
        { text: 'Print', action: 'print', icon: <PrintIcon sx={{ color: 'grey' }} /> },
        { text: 'Share', action: 'share', icon: <ShareIcon sx={{ color: 'grey' }} /> },

        { text: 'Comment', action: 'createNote', icon: <RateReviewIcon sx={{ color: 'white' }} /> },
        { text: 'Revoke', action: 'revokeFile', icon: <RemoveCircleOutlineIcon sx={{ color: 'white' }} /> },
        { text: 'Accept', action: 'acceptFile', icon: <TaskIcon sx={{ color: 'white' }} /> },

      ];

      const resfreshInterface = () => { 

        dispatch(setFile(null)); 

        dispatch(setEmptyNotesList(true)); 

        dispatch(refreshFiles(true)); 

      }; 

      
      const handleAction = async (action: string | any) => { 

        if (action === 'createNote') { 

          if (file) { 

            dispatch(setFileCreateNote(file._id));
            dispatch(setAuthor('Me'));
  
          }; 

        } else if (action === 'revokeFile') { 

          const res = await revokeFile(file._id) as any; 

          if (res.status === 200) { 

            dispatch(setNotificationMessage('File successfully revoked')); 

            resfreshInterface(); 

          }; 

        } else if (action === 'acceptFile') { 

            const res = await acceptFile(file._id) as any; 

            if (res.status === 200) { 

              dispatch(setNotificationMessage('File accepted')); 

              resfreshInterface(); 
  
            }; 

        }; 

      }; 
    
    
      return (

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '45%',
          }}>
  
            <Box sx={{ 
              overflow: 'auto',
              width: '95%', }}>
      
              <List dense>
      
                {listItems.map((item, index) => (
      
                  <ListItem onClick={() => handleAction(item.action)} button key={index}>
      
                    { item.type !== 'header' &&<ListItemIcon sx={{ color: '#C2C5C8' }}>{item.icon}</ListItemIcon>}
      
                    { item.type !== 'header' && <ListItemText sx={{ color: '#C2C5C8', overflow: 'hidden' }} primary={item.text} />}

                    { item.type === 'header' && 
                          <ListItemText 
                              sx={{ 
                                  marginTop: '3vh',
                                  marginBottom: '1vh',
                                  textAlign: 'left',
                                  color: '#C2C5C8' }} 
                                  primary={item.title} 
                          />}
      
                  </ListItem>
                ))}
      
              </List>
      
            </Box>

          </Box>
      );

};

export default ActionsList;