import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
    editedFolder: any, 
    editedFolderTitle: string, 
}

const initialState: InitialState = {
    editedFolder: null, 
    editedFolderTitle: '', 
};

export const editFolderSldie = createSlice({
  name: 'editFolder',
  initialState,
    reducers: {
    setEditedFolder: (state, action: PayloadAction<any>) => {
        state.editedFolder = action.payload
    },
    setEditedFolderTitle: (state, action: PayloadAction<string>) => {
        state.editedFolderTitle = action.payload
    },
    resetEditFolder: (state) => { 

      state.editedFolder = null; 
      state.editedFolderTitle = ''; 

    } 
  },
});

export const {
    setEditedFolder, 
    setEditedFolderTitle,
    resetEditFolder
} = editFolderSldie.actions;

export default editFolderSldie.reducer;