import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// -- Routes

// -- Components 

import Box from '@mui/material/Box';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import Logo from '../../general/navBar/Logo';


const Success: React.FC = () => {

    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);


    // -- 

    return (

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '100vh', 
                width: '100vw',
                left: 0, 
                overflowX: 'hidden',
                position: 'absolute',
                color: colors?.highlightedText
            }}
        >

            <TaskRoundedIcon sx={{ fontSize: 100, color: colors?.scanTemplateCreated }} />

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    width: '80%',
                }}
            >{t('templateCreated')}</Box>

            <Logo />

        </Box>

  );
};

export default Success;