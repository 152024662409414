import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux 

import { useAppSelector } from '../../../redux/hooks';

// Components

import CreatePatient from './patients/createPatient/CreatePatient';
import Patient from './patients/patient/Patient';

const PatientsInterface: React.FC = () => {
  const theme = useTheme();

  const isPortraitMode = useMediaQuery('(orientation: portrait)');
  const isSmallScreen = useMediaQuery('(max-width: 1300px)');
  const leftOffset = isSmallScreen ? '6%' : '5vw';

  const colors = useAppSelector((state) => state.theme.colors); 
  const section = useAppSelector((state) => state.patient.section); 

  return (
    <Box
      sx={{
        display: 'flex', 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '95vw',
        height: '95vh', 
        left: leftOffset,
        bottom: 0,
        position: 'absolute',
        backgroundColor: colors?.interface,
        transition: 'left 0.3s',
      }}
    >
      { section === 'patient' && <Patient />}
      { section === 'createPatient' && <CreatePatient />}
      
    </Box>
  );
};

export default PatientsInterface;
