import moment from 'moment';

const formatAutoCompleteData = (attribute?: any, data?: any) => { 


    if (attribute === 'dateOfBirth') { 

        return moment.utc(data).format('LL');

    } else { 

        return data; 
    };

}; 

export default formatAutoCompleteData; 