import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface PatientLogsState {
  patientLogs: any[], 
  currentPatientId: any, 
  isRefreshPatientLogs: boolean, 
  dropdownOpened: string, 
  initialRendering: boolean, 
};

// ---------------

const initialState: PatientLogsState = {
  patientLogs: [], 
  currentPatientId: null, 
  isRefreshPatientLogs: false, 
  dropdownOpened: '',  
  initialRendering: true, 
};

// ---------------

export const patientLogsSlice = createSlice({

  name: 'patientLogs',

  initialState,

  reducers: {

    setPatientLogs: (state, action: PayloadAction<any>) => {

      state.patientLogs = action.payload; 

    },
    setCurrentPatientId: (state, action: PayloadAction<any>) => {

      state.currentPatientId = action.payload; 

    }, 
    setIsRefreshPatientLogs: (state, action: PayloadAction<boolean>) => {

        state.isRefreshPatientLogs = action.payload; 
  
    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetPatientLogs: (state) => { 
        
        state.patientLogs = [];

        state.currentPatientId = null; 
        state.initialRendering = true; 

        // Refreshers purposely omitted. 

    } 
  },

}); 

// ---------------

export const { 
    setPatientLogs, 
    setCurrentPatientId, 
    setIsRefreshPatientLogs, 
    setInitialRendering, 
    resetPatientLogs
 } = patientLogsSlice.actions;

export default patientLogsSlice.reducer;