import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

import { setSelectedPatient } from '../../../../../../redux/features/template/template';

// Components 

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type Props = {
    _id: string, 
    patientId: string, 
    fullName: string,
};

const PatientRow: React.FC<Props> = ({ _id, patientId,  fullName }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedPatient = useAppSelector(state => state.template.selectedPatient); 

    const [patientName, setPatientName] = useState('');     
    const [dateOfBirth, setDateOfBirth] = useState(''); 

    const [isInfoSectionOpened, setIsInfoSectionOpened] = useState(false); 

    // -- 

    const handleSelection = () => { 

        dispatch(setSelectedPatient({ patientId: patientId, fullName: fullName })); 

    }; 

    // -- 

    const openInfo = () => { 
        
        setIsInfoSectionOpened(true); 

    }; 

    // -- 

    useEffect(() => { 

        if (fullName) { 

            const splittedName = fullName.split(' ('); 

            setPatientName(splittedName[0]); 

            const splittedDateOfBirth = fullName.split(' ('); 

            const formattedDateOrBirth = splittedDateOfBirth[1].split(')'); 

            setDateOfBirth(formattedDateOrBirth[0]); 

        };

    },[fullName]); 

    // -- 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            marginLeft: '1%',
            marginRight: '1%',
            width: '95%',
            height: 'auto',
            borderRadius: 3, 
            marginBottom: 1, 
            backgroundColor: colors?.formRow,
            color: colors?.text,
            '&:hover': { backgroundColor: colors?.formRowHover},
            transition: 'height 0.3s',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            textWrap: 'nowrap'
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    justifyContent: 'space-between', 
                    width: '100%', 
                    height: 40,
                }}
            >

                <Box
                    onClick={handleSelection}
                    sx={{
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center', 
                        cursor: 'pointer',
                    }}
                >
                    <AccountCircleIcon sx={{ color: colors?.templatePatientRowIcon, fontSize: 25, marginLeft: 2 }} />

                    <Box sx={{ fontSize: 14, marginLeft: 2, overflow: 'hidden', marginRight: 1 }}>{patientName}</Box>
                </Box>

                { isInfoSectionOpened ?
                
                <KeyboardArrowUpIcon 
                    onClick={() => setIsInfoSectionOpened(false)}
                    sx={{ 
                        fontSize: 18, 
                        color: 'rgb(100, 100, 100)',
                        marginRight: 1, 
                        cursor: 'pointer',
                        '&:hover': { color: colors?.text }
                    }} />: 
                <InfoOutlinedIcon 
                    onClick={openInfo}
                    sx={{ 
                        fontSize: 18, 
                        color: 'rgb(100, 100, 100)',
                        marginRight: 1, 
                        cursor: 'pointer',
                        '&:hover': { color: colors?.text }
                }} />}        
            
            </Box>

            { isInfoSectionOpened && 
            
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    paddingLeft: 2, 
                    height: 30, 
                    marginTop: 1,
                    width: '100%'
                }}
            >

            <EventNoteIcon 
                sx={{ 
                    fontSize: 25, 
                    color: 'rgb(20,139,139)',
                    marginRight: 1, 
                }} />         

            <Box 
                sx={{ 
                    fontSize: 14, 
                    marginLeft: 1, 
                    overflow: 'hidden', 
                    marginRight: 1 
            }}>{dateOfBirth}</Box>

            </Box>}

        </Box>
  );
};

export default PatientRow;