import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


// Redux 

// Components 

import Box from '@mui/material/Box';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';

const ModificationStatus: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const navigate = useNavigate(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    const goToTemplates = () => { 

        navigate('/dashboard');
        
        window.location.reload(); 

    }; 
    
    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
          height: '100%',
          marginLeft: 1,
          color: colors?.text 
      }}>

        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                width: 30,
                height: 30,  
                fontSize: 14,
            }}
        >

          <TopicOutlinedIcon
            onClick={goToTemplates}
            sx={{ 
              fontSize: 25, 
              color: 'rgb(45, 153, 207)', 
              marginRight: 4,
              cursor: 'pointer', 
              '&:hover': { color: 'rgb(75, 183, 237)' }
            }} /> 

        </Box>

      </Box>

  );

};

export default ModificationStatus;