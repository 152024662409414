import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import BackButton from './BackButton';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { setAccessRequests, setDisabled, setInsurers, setIsEditedDoctorPreferences, setNewPatientFile, setNewPatientNote, setSecurityAlerts, setSupport } from '../../../redux/features/doctor/Account/preferences';
import EventLimiter from '../../../util/events/EventLimiter';

interface Props { 
    title: string, 
    switchValue: boolean, 
    section: string 
}

const NotificationRow: React.FC<Props> = ({ title, switchValue, section }) => {

  const dispatch = useAppDispatch(); 

  const colors = useAppSelector(state => state.theme.colors);

  const [initialValue, setInitialValue] = useState(false); 

  const { t } = useTranslation();

    const handleSwitch = async (e: any) => { 

        if (section === 'disabled') { 

          dispatch(setDisabled(e.target.checked)); 

        } else if (section === 'securityAlerts') { 

          dispatch(setSecurityAlerts(e.target.checked)); 

        } else if (section === 'accessRequests') { 

          dispatch(setAccessRequests(e.target.checked)); 

        } else if (section === 'newPatientFile') { 

          dispatch(setNewPatientFile(e.target.checked)); 

        } else if (section === 'newPatientNote') { 

          dispatch(setNewPatientNote(e.target.checked)); 

        } else if (section === 'support') { 

          dispatch(setSupport(e.target.checked)); 

        } else if (section === 'insurers') { 

          dispatch(setInsurers(e.target.checked)); 

        };  

        dispatch(setIsEditedDoctorPreferences(true));

    }; 

    useEffect(() => { 

      setInitialValue(switchValue); 

    },[]); 

  return (

    <Box style={{ 
        display: 'flex',
        flexDirection: 'row', 
        alignItems: 'center', 
        justifyContent: 'space-between', 
        width: '80%',
        height: 50, 
    }}>

        <Box sx={{ color: colors?.text, marginLeft: 10 }}>{title}</Box>

        <FormGroup>
            <FormControlLabel 
              control={<Switch 
              onChange={handleSwitch}
              checked={switchValue}
            />} label="" />
        </FormGroup>

    </Box>

  );

};

export default NotificationRow;