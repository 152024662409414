import React, { useState, useEffect }from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';

const BackButton: React.FC = () => {

  const navigate = useNavigate(); 

  const colors = useAppSelector(state => state.theme.colors);
  const section = useAppSelector(state => state.navigation.section);

  // -- 

  const handleNavigation = () => { 

    navigate('/dashboard'); 

  }; 

  // -- 

  return (

    <Box
        onClick={handleNavigation}
        sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly', 
            width: 200, 
            padding: 1, 
            marginLeft: 10, 
            marginTop: 5,
            cursor: 'pointer', 
            fontSize: 12, 
            color: colors?.text,
            borderRadius: 5, 
            '&:hover': { backgroundColor: colors?.settingsBackButtonHover }
    }}>
        <ArrowCircleLeftOutlinedIcon /> 

        <Box sx={{ marginLeft: 2, paddingRight: 1 }}>Back to Dashboard</Box>

    </Box>

  );

};

export default BackButton;