import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import { PieChart } from '@mui/x-charts/PieChart';

const Distribution: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const data = useAppSelector(state => state.hackerData.data); 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%', 
            marginBottom: 5,
        }}>

            { data && 
            <PieChart
                sx={{ marginRight: 50 }}
                series={[
                    {
                    data: data?.doctorGraph,
                    cx: 500,
                    cy: 200,
                    innerRadius: 40,
                    outerRadius: 80,
                    },
                ]}
                height={300}
                width={800}
                slotProps={{
                    legend: { position: { horizontal: 'right', vertical: 'middle' }, hidden: false },
                }}
            />}

            { data && 
            <PieChart
                sx={{ marginRight: 50 }}
                series={[
                    {
                    data: data?.ticketsGraph,
                    cx: 500,
                    cy: 200,
                    innerRadius: 40,
                    outerRadius: 80,
                    },
                ]}
                height={300}
                width={800}
                slotProps={{
                    legend: { position: { horizontal: 'right', vertical: 'middle' }, hidden: false },
                }}
            />}

        </Box>

  );

};

export default Distribution;