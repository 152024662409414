import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import Action from '../Action';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DownloadIcon from '@mui/icons-material/Download';

const GeneralDropdown: React.FC = () => {

    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const mouseInfo = useAppSelector(state => state.fileExplorer.mouseInfo); 
    const directory = useAppSelector(state => state.fileExplorer.directory); 

    return ( 

        <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: 200,
            height: 'auto',
            top: mouseInfo?.clientY - 30,
            left: mouseInfo?.clientX - 130, 
            position: 'absolute',
            backgroundColor: colors?.fileExplorerDropdown,
            borderRadius: 4,
            zIndex: 5, 
            transition: 'width 0.3s',
        }}
    >

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%', 
                cursor: 'pointer',
            }}
        >

            <Action title={'newFolder'} iconColor={colors?.newFolderIcon} action={'createFolder'} type='dropdown'>
                <AddCircleOutlineIcon /> 
            </Action>

            <Action title={'uploadFile'} iconColor={colors?.uploadFileIcon} action={'uploadFile'} type='dropdown'>
                <DownloadIcon /> 
            </Action>   

            { directory.startsWith('Patients/') &&
            <Action title={'patientInfo'} action={'patientInfo'} type='dropdown'>
                <InfoOutlinedIcon /> 
            </Action>}

        </Box>


    </Box>

    )

}; 

export default GeneralDropdown; 