import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Redux 

import { setTeamName } from './../../../../../redux/features/admin/dashboard/team/createTeam';

// Components 

import TextField from '@mui/material/TextField';

const TeamName: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const teamName = useAppSelector(state => state.adminCreateTeam.teamName); 

  const [name, setName] = useState(''); 

  // Redux 

  useEffect(() => {

    dispatch(setTeamName(name)); 

  },[name]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginBottom: '3vh',
    }}>

        <TextField 
          sx={{ width: '100%', alignSelf: 'center' }} 
          onChange={(e) => setName(e.target.value)}
          id="standard-basic" 
          label="Team Name" 
          value={teamName}
          variant="standard" />


    </Box>

  );

};

export default TeamName;