import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setSupportTicketAuthor } from '../../../../redux/features/general/createSupportTicket';

// Components 

import TextField from '@mui/material/TextField';

const Author: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const author = useAppSelector(state => state.createSupportTicket.author);

  const [text, setText] = useState(''); 

  // Redux 

  useEffect(() => {

    dispatch(setSupportTicketAuthor(text)); 

  },[text]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginBottom: '3vh',
    }}>

        <TextField 
          sx={{ width: '20%', alignSelf: 'center' }} 
          onChange={(e) => setText(e.target.value)}
          id="standard-basic" 
          label="Doctor Id" 
          value={author}
          variant="standard" />


    </Box>

  );

};

export default Author;