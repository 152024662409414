import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Components 

import SectionHeader from './formItems/SectionHeader';
import FirstName from './formItems/FirstName';
import LastName from './formItems/LastName';
import Email from './formItems/Email';
import SubmitButton from './formItems/SubmitButton';
import BackButton from './formItems/BackButton';

const CreateDoctor: React.FC = () => {

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '90%',
        marginTop: '10vh', 
        width: '80vw',
        marginLeft: '5vw',
        overflowY: 'scroll',
        scrollbarWidth: 'none'
    }}>

        <BackButton /> 

        <SectionHeader title='Create a Doctor Account' />

        <FirstName />

        <LastName /> 

        <Email /> 

        <SubmitButton /> 

    </div>

  );

};

export default CreateDoctor;