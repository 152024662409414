import darkTheme from '../../../styles/themes/darkTheme';
import lightTheme from '../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const menuItems = [
    { type: 'item', section: 'documents', text: 'docs', iconName: 'topic', iconColor: currentTheme?.sidebarIconColor, key:'i-documents' },
    { type: 'item', section: 'patients', text: 'patients', iconName: 'people', iconColor: currentTheme?.sidebarIconColor, key:'i-patients'},
    { type: 'item', section: 'templates', text: 'templates', iconName: 'description', iconColor: currentTheme?.sidebarIconColor, key:'i-templates'}, 
];

export default menuItems; 