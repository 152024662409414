import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface hackerDataState {
  section: string,
  data: any, 
  securityData: any, 
  activityData: any, 
  doctorsChartData: any[],
  patientsChartData: any[], 
  doctorsChartXAxis: any[], 
  patientsChartXAxis: any[],

  activityChartData: any[], 
  activityChartXAxis: any[], 

  initialRendering: boolean, 


}

// ---------------

const initialState: hackerDataState = {
    section: 'doctors', 
    data: null, 
    securityData: null, 
    activityData: null, 
    doctorsChartData: [],
    patientsChartData: [], 
    doctorsChartXAxis: [], 
    patientsChartXAxis: [],

    activityChartData: [], 
    activityChartXAxis: [], 

    initialRendering: true, 
};

// ---------------


export const hackerDataSlice = createSlice({

  name: 'hackerData',

  initialState,

  reducers: {

    setHackerDataSection: (state, action: PayloadAction<string>) => {

        state.section = action.payload; 
    },
    setHackerData: (state, action: PayloadAction<any>) => {

      state.data = action.payload; 
    },
    setSecurityData: (state, action: PayloadAction<any>) => {

      state.securityData = action.payload; 
    },
    setActivityData: (state, action: PayloadAction<any>) => {

      state.activityData = action.payload; 
    },
    setDoctorsChartData: (state, action: PayloadAction<any>) => {

      if (state.doctorsChartData.length < 14) { 

        state.doctorsChartData.push(action.payload); 

      }; 

    }, 
    setPatientsChartData: (state, action: PayloadAction<any>) => {

      if (state.patientsChartData.length < 14) { 

        state.patientsChartData.push(action.payload); 

      }; 

    }, 
    setDoctorsChartXAxis: (state, action: PayloadAction<any>) => {

      if (state.doctorsChartXAxis.length < 14) { 

        state.doctorsChartXAxis.push(action.payload); 

      }; 

    }, 
    setPatientsChartXAxis: (state, action: PayloadAction<any>) => {

      if (state.patientsChartXAxis.length < 14) { 

        state.patientsChartXAxis.push(action.payload); 

      }; 

    }, 
    setActivityChartData: (state, action: PayloadAction<any>) => {

      if (state.activityChartData.length < 24) { 

        state.activityChartData.push(action.payload); 

      }; 

    }, 
    setActivityChartXAxis: (state, action: PayloadAction<any>) => {

      if (state.activityChartXAxis.length < 24) { 

        state.activityChartXAxis.push(action.payload); 

      }; 

    }, 
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetHackerData: (state) => { 
        
        state.section = 'doctors';
        state.data = null; 
        state.securityData = null; 
        state.activityData = null; 
        state.doctorsChartData = [];
        state.patientsChartData = [];
        state.doctorsChartXAxis = []; 
        state.patientsChartXAxis = []; 

        state.activityChartData = []; 
        state.activityChartXAxis = []; 

        state.initialRendering = true; 

    } 

  },

}); 

// ---------------

export const { 
    setHackerDataSection,
    setHackerData,  
    setSecurityData, 
    setActivityData, 
    setDoctorsChartData, 
    setPatientsChartData,
    setDoctorsChartXAxis, 
    setPatientsChartXAxis,  
    setActivityChartData,
    setActivityChartXAxis, 
    setInitialRendering, 
    resetHackerData } = hackerDataSlice.actions;

export default hackerDataSlice.reducer;