
const darkTheme = { 

    // Theme

    theme: 'dark', 

    // const colors = useAppSelector(state => state.theme.colors);

    // Backgrounds 

    interface: 'rgb(32,31,30)', 
    container: 'rgb(49,51,56)',
    legend: 'rgb(43,45,49)',
    text: 'rgb(194, 197, 200)',
    highlightedText: 'rgb(255, 255, 255)', 
    searchBar: 'rgb(43,45,49)', 
    blueButton: 'rgb(54,69,126)', 
    disabledIcon: 'rgb(121,124,128)', 
    disabledRelatedPatient: 'rgba(194, 197, 200, 0.5)',
    newFolderIcon: 'rgb(80,199,199)',
    uploadFileIcon: 'rgb(34,148,6)',
    relatedPatientIcon: 'rgb(127,52,235)',
    refreshIcon: 'rgb(80,199,199)',
    changeViewIcon: 'rgb(147,47,162)',
    fileExplorerSearchBar: 'rgb(56,58,64)', 
    selectedSibebarSection: 'rgb(80,199,199)',
    correctFileIcon: 'rgb(80,199,199)', 
    renameFileIcon: 'rgb(34, 148, 6)',
    moveToIcon: 'rgb(30, 144, 255)',
    patientInfoIcon: 'rgb(127, 52, 235)', 
    deleteIcon: 'rgb(201, 79, 42)', 
    viewFileIcon: 'rgb(201, 117, 42)', 
    actionBackground: 'rgb(49,51,56)', 
    removePinIcon: 'rgb(199, 131, 80)',
    pinFolderIcon: 'rgb(184, 160, 28)', 
    searchIcon: 'rgb(128, 128, 128)',

    sectionHover: 'rgba(77, 75, 89, 0.3)', 
    selectedSectionHover: 'rgb(77, 75, 89)', 
    unselectedSection: 'rgb(255, 255, 255)', 

    // File Explorer folder data, 

    hiddenBox: 'rgba(46, 45, 46, 0.7)',
    hiddenRow: 'rgba(0, 255, 238, 0.1)',
    sharedFolder: 'rgb(34, 148, 6)',
    folderDataRowBorderColor: 'rgba(159, 171, 157, 0.3)', 

    searchQueryFile: 'rgb(80,199,199)',
    searchQueryFolder: 'rgb(30, 144, 255)', 

    // Correct File. 

    reviewIcon: 'rgb(34, 148, 6)',
    correction: 'rgb(43,45,49)', 
    correctionHoverQuit: 'rgba(127, 135, 135, 0.2)', 

    fileViewerQuit: 'rgba(77, 75, 89, 0.5)', 

    dropdown: 'rgb(92, 94, 99)',
    fileExplorerDropdown: 'rgb(82,84,89)', 
    fileExplorerFooter: 'rgb(43,45,49)', 

    // File Explorer: 

    recentFolderIcon: 'rgb(207,137,45)',
    patientsFolderIcon: 'rgb(30,144,255)',
    downloadsFolderIcon: 'rgb(34,148,6)',
    documentsFolderIcon: 'rgb(80,199,199)', 
    pinIconColor: 'rgb(184, 160, 28)',
    legendCheckIcon: 'rgb(26, 255, 0)', 

    folder: 'rgb(199,145,80)',
    file: 'rgb(255,185,0)', 

    listInfo: '#919191',

    closeButtonBackground: 'rgb(147, 147, 147)',
    closeButtonText: 'rgb(207, 207, 207)', 

    patientExitButton: 'rgb(107, 107, 107)',
    patientExitButtonBackground: 'rgb(135, 0, 0)',

    deleteUploadedFile: 'rgb(199, 119, 80)', 
    deleteUploadedFileHover: 'rgb(227, 96, 32)', 

    exitButtonColor: 'rgb(92, 0, 0)',
    exitButtonColorHover: 'rgb(135, 0, 0)',
    exitButtonText: 'rgb(187, 187, 187)',
    cancelButtonText: 'rgb(110, 110, 110)', 
    cancelButtonColor: 'rgb(177, 177, 177)',  
    cancelButtonBackground: 'rgb(147, 147, 147)',

    updatePatientDetails: 'rgba(59, 87, 196, 0.5)' ,
    confirmPatientInfoText: 'rgb(150, 150, 150)',

    emergencyContacts: 'rgb(52, 53, 58)',

    // File Explorer Footer

    driveIcon: 'rgb(255,185,0)',
    accountFooterIcon: 'rgb(175, 101, 230)',

    directory: 'rgb(80,199,199)',

//  const colors = useAppSelector(state => state.theme.colors);

    createPatientContainer: 'rgb(56, 58, 64)',
    createPatientDisabledIcon: 'rgba(46, 45, 46, 0.7)',
    createPatientEnabledIcon: 'rgb(52, 53, 58)',
    createPatientRequired: 'rgb(252, 118, 50)', 
    createPatientSelect: 'rgba(59, 87, 196, 0.5)',
    createPatientError: 'rgb(252, 118, 50)',
    createPatientOptionsList: 'rgba(43,45,49, 0.5)',
    createPatient: 'rgba(59, 87, 196, 0.5)',

    completedCategory: 'rgb(42, 209, 42)',
    incompletedCategory: 'rgb(174, 174, 174)',
    incompletedCategoryHover: 'rgb(204, 107, 10)', 
    erroredCategory: 'rgb(194, 0, 3)',
    erroredCategoryHover: 'rgb(210, 0, 3)', 

    requiredFieldsWarning: 'rgb(122, 133, 133)',
    patientContainer: 'rgb(56, 58, 64)',

    listHeaderBackground: 'rgb(43, 45, 49)',

    optionContainer: 'rgb(52, 53, 58)', 

    directiveIcon: 'rgb(34, 148, 6)',
    createDirective: 'rgba(59, 87, 196, 0.5)', 
    directiveAlertIcon: 'rgb(207, 137, 45)', 

    noteIcon: 'rgb(34, 148, 6)',
    createNote: 'rgba(59, 87, 196, 0.5)', 
    createNoteHover: 'rgba(170, 177, 179, 0.2)',
    noteDescription: 'rgb(157, 159, 163)', 
    noteDropdown: 'rgb(82,84,89)',
    noteDropdownHover: 'rgb(92 , 94 , 99)', 
    
    notificationsContainer: '#2B2D31',
    
    calendarBackground: 'rgba(207, 137, 45, 0.2)',
    calendarHover: 'rgba(207, 137, 45, 0.1)',
    calendarIcon: 'rgba(207, 137, 45, 0.8)',

    notificationsBackground: 'rgba(45, 207, 62, 0.2)', 
    notificationsHover: 'rgba(45, 207, 62, 0.1)',
    notificationsIcon: 'rgba(45, 207, 62, 0.8)',

    accessRequestsBackground: 'rgba(188, 45, 207, 0.2)',
    accessRequestsHover: 'rgba(188, 45, 207, 0.1)', 
    accessRequestsIcons: 'rgba(188, 45, 207, 0.8)',
    accessRequestVerifiedIcon: 'rgb(45, 207, 62)', 

    overviewRowHover: 'rgba(80,199,199, 0.2)',
    overviewIcon: 'rgb(45, 207, 196)',

    // Patient Info categories 

    firstName: 'rgb(45, 207, 196)',
    lastName: 'rgb(207, 137, 45)',
    gender: 'rgb(188, 45, 207)', 
    dateOfBirth: 'rgb(45, 207, 62)',
    healthCardNumber: 'rgb(207, 137, 45)',
    phone: 'rgb(45, 153, 207)' ,
    email: 'rgb(45, 207, 196)',
    address: 'rgb(45, 207, 62)',
    patientId: 'rgb(207, 137, 45)',
    emergencyContactName: 'rgb(45, 207, 196)',
    emergencyContactRelation: 'rgb(188, 45, 207)',
    emergencyContactPhone: 'rgb(45, 153, 207)',

    patientSearchDropdownRow: 'rgba(77, 75, 89, 0.3)',
    patientSearchDropdownRowHover: 'rgb(92 , 94 , 99)',

    patientFoundContainer: 'rgb(49,51,56)',
    patientFoundAvatar: 'rgb(34, 148, 168)',
    patientFoundInfo: 'rgb(45, 207, 196)',
    patientFoundInfoSecure: 'rgb(45, 207, 62)',
    patientSearchRow: 'rgb(43,45,49)',
    patientSearchColor: 'rgb(200, 200, 200)',
    patientRowUnselected: 'rgba(77, 75, 89, 0.5)',
    patientRowIcon: 'rgb(80,199,199)', 
    patientDataSection: 'rgb(52, 53, 58)',

    accessRequested: 'rgba(59, 196, 105, 0.5)',
    accessRequestedHover: 'rgba(59, 196, 105, 0.9)',

    // Options 

    account: 'rgb(84, 147, 255)', 
    accountHover: 'rgba(84, 147, 255, 0.2)',

    editPatient: 'rgb(34, 148, 6)', 
    editPatientHover: 'rgba(34, 148, 6, 0.2)', 

    filesOption: 'rgb(80,199,199)', 
    filesOptionHover: 'rgba(80,199,199, 0.2)', 

    notesOption: 'rgb(188, 45, 207)',
    notesOptionHover: 'rgba(188, 45, 207, 0.2)', 
    
    directivesOption: 'rgb(207, 137, 45)',
    directivesOptionHover: 'rgba(207, 137, 45, 0.2)',

    logsOption: 'rgb(45, 207, 196)', 
    logsOptionHover: 'rgba(45, 207, 196, 0.2)', 

    settingsOption: 'rgb(170, 177, 179)',
    settingsOptionHover: 'rgba(170, 177, 179, 0.2)',

    // Templates 

    codeBox: 'rgb(43,45,49)',
    qrValueBackground: 'rgba(39, 56, 105, 0.2)', 
    qeValueBorder: 'rgba(84, 147, 255, 0.3)', 

    createTemplate: 'rgb(43,45,49)',
    createTemplateIcon: 'rgb(80,199,199)',
    templateSearch: 'rgb(200, 200, 200)', 
    templateBoxText: 'rgb(166, 166, 166)',  
    templateBoxBorder: 'rgba(45, 207, 196, 0.5)',
    templateBoxIcon: 'rgba(45, 207, 196, 0.7)',
    templateBoxHover: 'rgb(45, 207, 196)',
    templateBoxHoverBorder: 'rgba(45, 207, 196, 0.8)',
    templateHover: 'rgba(77, 75, 89,0.3)',
    templateTextHover: 'rgb(224, 224, 224)', 

    notification: 'rgb(39, 12, 135)',
    notificationText: 'rgb(255, 255, 255)', 

    popup: 'rgba(56, 58, 64, 0.75)',

    navBar: 'rgb(43, 45, 49)', 

    footerText: 'rgb(138, 138, 138)',
    footerLinkHover: 'rgb(5, 229, 211)', 

    sidebar: 'rgb(43, 45, 49)', 
    sidebarItemSelectedSection: 'rgb(45, 207, 196)',
    sidebarUnselectedItem: 'transparent',
    subSidebarSection: 'rgb(77, 75, 89)',

    legalText: 'rgb(138, 138, 138)', 
    legalBannerText: 'rgb(217, 217, 217)',
    legalDescription: 'rgb(217, 217, 217)',
    legalLearMore: 'rgb(30, 144, 255)',
    legalLearnMoreHover: 'rgb(128, 149, 255)',

    scanTemplateCameraIcon: 'rgb(45, 207, 196)',
    scanTemplateFromPhotosIcon: 'rgb(45, 207, 62)',
    scanTemplateTakePicture: 'rgb(45, 207, 196)',
    scanTemplateFlipCamera: 'rgb(45, 207, 62)',

    scanTemplateUploadBackground: 'rgb(13, 14, 15)',
    scanTemplateUploadBorder: 'rgb(200, 200, 200)',
    scanTemplateSubmitButton: 'rgba(59, 87, 196, 0.5)',
    scanTemplateCreated: 'rgb(45, 207, 62)',

    settingsBackButtonHover: 'rgba(77, 75, 89, 0.4)',
    settingsAccountIcon: 'rgb(45, 207, 196)',
    settingsEmailIcon: 'rgb(45, 207, 62)',
    settingsStatusIcon: 'rgb(188, 45, 207)',
    settingsSoftwareVersion: 'rgb(45, 153, 207)',
    settingsThemeLightMode: 'rgb(32, 31, 30)',
    settingsThemeDarkMode: 'rgb(45, 207, 196)',
    settingsDarkTheme: 'rgb(255, 255, 255)', 
    settingsLightTheme: 'black', 

    supportEmailBackground: 'rgb(39, 41, 43)',
    supportTicketsListHeader: 'rgb(56, 58, 64)',
    supportHistoryHeader: 'rgb(43, 45, 49)',  
    supportHistory: 'rgb(200, 200, 200)', 

    templateNavBar: 'rgb(38, 41, 44)',
    templateDropdown: 'rgb(82,84,89)',
    templateFontSizeFunction: 'rgb(45, 49, 52)',
    templateFontSizeBackground: 'rgb(31, 34, 37)',
    templateModified: 'rgba(59, 87, 196, 0.5)',
    templateModifiedHover: 'rgb(59, 87, 196)',
    templateNotModified: 'rgb(47, 50, 53)',

    templateToolEnabled: 'rgb(25, 28, 31)',
    templateToolDisabled: 'rgb(37, 40, 43)',
    templateToolEnabledHover: 'rgb(27, 30, 33)',
    templateToolDisabledHover: 'rgb(45, 49, 52)',

    attributeRow: 'rgb(52, 53, 58)',
    attributeRowHover: 'rgb(72, 73, 78)',
    selectedAttributeRow: 'rgb(34, 148, 6)',

    formRow: 'rgb(63,65,69)',
    formRowHover: 'rgb(73, 75, 79)', 
    selectedFormRow: 'rgb(83, 85, 89)',
    formRowTitle: 'rgb(45, 153, 207)',
    formRowArrow: 'rgb(93, 95, 99)',
    formRowMovingArrow: 'rgb(45, 207, 196)',
    formRowMovingArrowHover: 'rgba(45, 207, 196, 0.3)',
    formRowRemoveField: 'rgb(228, 45, 247)',
    formRowRemoveFieldHover: 'rgb(205, 30, 30)',

    autoCompleteQuitHover: 'rgba(116, 122, 122, 0.5)',
    autoCompleteAccountBackground: 'rgba(80,199,199, 0.5)',
    autoCompletePatientName: 'rgb(45, 207, 196)',
    autoCompleteSubmitButton: 'rgba(59, 87, 176, 0.5)',
    autoCompleteSubmitButtonHover: 'rgba(59, 87, 176, 0.8)',

    documentOptionsColor: 'rgb(80,199,199)',
    documentOptionsHover: 'rgba(80,199,199, 0.1)',

    documentOptionsKeepEditing: 'rgba(207, 137, 45, 0.5)',
    documentOptionsKeepEditingHover: 'rgba(207, 137, 45, 0.8)',

    templateSelectPatientInfo: 'rgb(63,65,89)',
    templatePatientsListSearch: 'rgb(56, 58, 64)',
    templatePatientsListSearchBackground: 'rgb(200, 200, 200)',  

    templatePatientRow: 'rgb(63,65,69)',
    templatePatientRowHover: 'rgb(73, 75, 79)',
    templatePatientRowIcon: 'rgb(80,199,199)',
    selectedPatientRow: 'rgb(83, 85, 89)',

    templateSidebar: 'rgb(38, 41, 44)',
    selectedSideBarItem: 'rgb(45, 207, 196)',
    templateSidebarItemHover: 'rgba(77, 75, 89, 0.3)',

    sidebarIconColor: 'rgb(255, 255, 255)', 

    // Colors  (most of the fields above used one of them). 

    green: 'rgb(45,207,62)', 
    orange: 'rgb(207,137,45)', 
    purple: 'rgb(188,45,207)', 
    grey: 'rgb(170,177,179)', 
    red: 'rgb(201,79,42)', 

    lighBlue: 'rgb(80,199,199)',
    darkGreen: 'rgb(34,148,6)', 
    darkBlue: 'rgb(30,144,255)', 
    darkPurple: 'rgb(127,52,235)', 

}; 

export default darkTheme; 