import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';
import Button from '@mui/material/Button';

// Routes

import { createUser } from './../../../../../routes/admin/users';

// Redux 

import { resetCreateUser } from './../../../../../redux/features/admin/dashboard/users/createUser';
import { setNotificationMessage } from './../../../../../redux/features/general/notification';


const SubmitButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

    const firstName = useAppSelector(state => state.adminCreateUser.firstName); 
    const lastName = useAppSelector(state => state.adminCreateUser.lastName); 
    const email = useAppSelector(state => state.adminCreateUser.email); 
    const team = useAppSelector(state => state.adminCreateUser.team); 
    const accountType = useAppSelector(state => state.adminCreateUser.accountType); 

  const [allowUserCreation, setAllowUserCreation] = useState(false); 

  useEffect(() => { 

    if (firstName && lastName && email) { 

        setAllowUserCreation(true); 

    } else { 

        setAllowUserCreation(false); 

    };

  },[firstName, lastName, email]); 

  // -- 

  const handleCreateUser = async () => { 

     const res = await createUser(firstName, lastName, email, team, accountType) as any; 

     if (res.status === 200) { 

      // Redux is reset. 

      dispatch(resetCreateUser()); 

      dispatch(setNotificationMessage('User Successfully Created')); 

     } else { 



    }; 

  }; 

  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '5vh',
        height: '5vh',
        marginBottom: '3vh',
    }}>

        { allowUserCreation ? 
        <Button onClick={() => handleCreateUser()} variant="contained">Create User</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">Create User</Button>}


    </Box>

  );

};

export default SubmitButton;