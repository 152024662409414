import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface FileState {
  file: any,
  selectedTeam: string,
}

// ---------------

const initialState: FileState = {
  file: null,
  selectedTeam: '', 
};

// ---------------


export const adminFileSlice = createSlice({

  name: 'adminFile',

  initialState,

  reducers: {

    setFile: (state, action: PayloadAction<any>) => {
        state.file = action.payload
    },
    setAssignedTeam: (state, action: PayloadAction<string>) => {
      state.selectedTeam = action.payload
    }, 
  },

}); 

// ---------------

export const { setFile, setAssignedTeam } = adminFileSlice.actions;


export default adminFileSlice.reducer;