import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components

import FolderIcon from '@mui/icons-material/Folder';

// Icons 

import ScheduleIcon from '@mui/icons-material/Schedule';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
    _id?: string; 
    path?: string; 
    title: string;
    description?: string;
    status?: string;
    folder?: string; 
    uploadedBy: string;
    pinned: Boolean; 
    relatedPatient?: string;
    createdAt?: Date;
    updatedAt?: Date;
};


const FolderRow: React.FC<Props> = ({ _id, path, title, pinned, description, status, folder, uploadedBy, relatedPatient, createdAt, updatedAt }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const searchSection = useAppSelector((state) => state.fileExplorer.searchSection); 

    return (

        <Box 
            sx={{
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 40,
            overflow: 'hidden',
            color: colors?.text,
            backgroundColor: searchSection === _id ? colors?.sectionHover : 'transparent',
            '&:hover': { backgroundColor: searchSection === _id ? colors?.selectedSectionHover : colors?.sectionHover }
        }}>

            { path === 'Recent' && <ScheduleIcon sx={{ color: colors?.recentFolderIcon, fontSize: 25, marginLeft: 2 }} />}
            { path === 'Patients' && <PersonIcon sx={{ color: colors?.patientsFolderIcon, fontSize: 25, marginLeft: 2 }} />}
            { path === 'Downloads' && <DownloadIcon sx={{ color: colors?.downloadsFolderIcon, fontSize: 25, marginLeft: 2 }} />}
            { path === 'Documents' && <DescriptionIcon sx={{ color: colors?.documentsFolderIcon, fontSize: 25, marginLeft: 2 }} />}
            
            { folder && <FolderIcon sx={{ color: colors?.folder, fontSize: 25, marginLeft: 2 }} />}

            <Box sx={{ width: '50%', fontSize: 14, marginLeft: 2, overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 2 }}>{title}</Box>

            { pinned && 
            
                <PushPinOutlinedIcon 
                    sx={{ 
                        color: colors?.pinIconColor, 
                        fontSize: 15, 
                        marginLeft: 10,
                        right: 10, 
                        position: 'absolute', 
                }}/>}

        </Box>

    );

};

export default FolderRow;