import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Routes

import { getAvailableTeams } from './../../../../../routes/admin/users';

// Redux 

import { setAssignedTeam } from './../../../../../redux/features/admin/dashboard/users/createUser';

// Icons 

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PeopleIcon from '@mui/icons-material/People';

const TeamsList: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const assignedTeam = useAppSelector(state => state.adminCreateUser.team); 

    const [availableTeams, setAvailableTeams] = useState(Array<any>); 

    const selectTeam = (teamId: string) => { 

        dispatch(setAssignedTeam(teamId));

    };

      useEffect(() => { 

        const getTeamsList = async () => {

          const teamsList = await getAvailableTeams() as Array<any>;
          
          if (teamsList) { 

            setAvailableTeams(teamsList); 

          };

        };

        getTeamsList(); 

      },[]); 


      return (

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '30vh',
          width: '100%',
          borderRadius: 5,
          backgroundColor: '#383A40'
        }}>

          {availableTeams?.length >= 1 && 
          <Box sx={{ 
            overflow: 'auto',
            width: '95%',
            marginTop: '2vh' }}>
    
            <List dense>
    
              {availableTeams?.map((item, index) => (

                <ListItem onClick={() => selectTeam(item._id)} button key={index}>

    
                    <ListItemIcon sx={{ color: '#C2C5C8' }}>
                      {assignedTeam == item._id ? 
                      <CheckCircleIcon sx={{ color: 'lightblue'}} />:<AddCircleOutlineIcon />}
                    </ListItemIcon>
    
                    {assignedTeam == item._id ?   
                    <ListItemText sx={{ color: 'lightblue' }} primary={item.name} />:
                    <ListItemText sx={{ color: '#C2C5C8' }} primary={item.name} />}
    
                </ListItem>

                
              ))}
    
            </List>
    
          </Box>}

          {availableTeams?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              color: '#C2C5C8'
            }}>

              <PeopleIcon sx={{ fontSize: 70 }} />

              <div>No team detected.</div>

            </Box>
          
          }

        </Box>
    
      );

};

export default TeamsList;