import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { colorToComponents, degrees, PDFDocument, rgb, StandardFonts, setFontAndSize, TextAlignment, NonFullScreenPageMode  } from 'pdf-lib';

// Components 

import Box from '@mui/material/Box';
import Logo from '../../../../../general/navBar/Logo';
import CircularProgress from '@mui/material/CircularProgress';

const Loading: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: 'auto',
      }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly', 
                    width: '100%',
                    marginTop: 3,
                    marginBottom: 1, 
                }}
            >

                <Logo size={20} />

                <Box>
                    <svg width={0} height={0}>
                        <defs>
                        <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#e01cd5" />
                            <stop offset="100%" stopColor="#1CB5E0" />
                        </linearGradient>
                        </defs>
                    </svg>
                    <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
                </Box>

            </Box>

            <Box
                sx={{ 
                    color: colors?.text,
                    fontSize: 12,  
                    textAlign: 'center' }}
            >{t('templateAutoCompleteProcessing')}
            </Box>

        </Box>

  );

};

export default Loading;