import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LanguageSelector from '../navBar/LanguageSelector';
import { useTranslation } from 'react-i18next';

interface Props { 
  isScrollPage?: Boolean
}; 

const Footer: React.FC<Props> = ({ isScrollPage }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const colors = useAppSelector(state => state.theme.colors);


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'auto',
        width: '100vw',
        position: isScrollPage ? 'relative' : 'absolute',
        fontSize: 12,
        bottom: 0,
        color: colors?.footerText,
        textAlign: 'center',
      }}
    >
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'auto',
        justifyContent: 'space-around',
        marginBottom: 1 }}>
        <Box
          sx={{
            cursor: 'pointer', 
            '&:hover': {
              color: colors?.footerLinkHover,
            },
          }}
          onClick={() => navigate('/legal')}>{t('legal')}
        </Box> 
        <Box
          sx={{ 
            marginLeft: 1,
            marginRight: 1,
          }}
        >{' | '}</Box> 
        <Button
          sx={{ 
            backgroundColor: 'transparent',
            textDecoration: 'none',
            color: colors?.footerText,
            textTransform: 'none',
            fontWeight: 'normal',
            fontSize: 12,
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              color: colors?.footerLinkHover,
            },
          }}
          href="https://medca.ai"
          target="_blank">{t('mainSite')}
        </Button>
        <Box
          sx={{ 
            marginLeft: 1,
            marginRight: 1,
          }}
        >{' | '}</Box> 
        <LanguageSelector />
        <Box
          sx={{ 
            marginLeft: 1,
            marginRight: 1,
          }}
        >{' | '}</Box> 
        <Button
          sx={{ 
            backgroundColor: 'transparent',
            textDecoration: 'none',
            color: colors?.footerText,
            textTransform: 'none',
            fontWeight: 'normal',
            fontSize: 12,
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent',
              color: colors?.footerLinkHover,
            },
          }}
          href="https://medca.ai/contact"
          target="_blank">{t('contactUs')}
        </Button>
        </Box> 
        
      <Box sx={{ fontSize: 10, marginBottom: 1 }}>{t('copyright')}</Box>
    </Box>
  );
};

export default Footer;