import React from 'react';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';

// Redux 

import { setSupportTicketStatus } from '../../../../redux/features/general/createSupportTicket';

// Utils 

import getStatusColor from '../../../../util/style/getStatusColor';

const Status: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const status = useAppSelector((state) => state.createSupportTicket.status); 

    const handleChange = (newStatus: string) => {

        dispatch(setSupportTicketStatus(newStatus)); 

        setIsOpenMenu(false);

    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);  

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

        setIsOpenMenu(!isOpenMenu);

    };

    const handleClose = () => {
        setAnchorEl(null);
    }; 

  return (

    <div>
      <Box
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: 80,
            height: 50,
            textAlign: 'center',
            cursor: 'pointer', 
            backgroundColor: getStatusColor(status), 
            borderRadius: 5, }}
        onClick={handleClick}
      >
        <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>{status}</Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpenMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >

        <MenuItem onClick={() => handleChange('active')}>
            <div>active</div>    
        </MenuItem>

        <MenuItem onClick={() => handleChange('archived')}>
            <div>archived</div>  
        </MenuItem>

      </Menu>
    </div>
  );
};

export default Status;