import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components

import Box from '@mui/material/Box';
import InfoSection from './infoSection/InfoSection';
import FileViewer from './infoSection/FileViewer';

const CorrectFile: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors); 
    const isSmallScreen = useMediaQuery('(max-width: 1300px)');
    
    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',  
            width: '100%',
            height: '100%',
            backgroundColor: colors?.interface,
            position: 'absolute', 
        }}>

            <InfoSection /> 

            <FileViewer />

        </Box>

    );

};

export default CorrectFile;