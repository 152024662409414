import { createSlice, PayloadAction } from '@reduxjs/toolkit';


interface InitialState {
    editedFile: any, 
    title: string, 
    relatedPatient: any, 
    patientName: string, // For the search 
}

const initialState: InitialState = {
    editedFile: null, 
    title: '', 
    relatedPatient: null,
    patientName: '', 
};

export const editFileSlice = createSlice({
  name: 'editFile',
  initialState,
    reducers: {
    setEditedFile: (state, action: PayloadAction<any>) => {
        state.editedFile = action.payload
    },
    setEditedFileTitle: (state, action: PayloadAction<string>) => {
        state.title = action.payload
    },
    setRelatedPatient: (state, action: PayloadAction<any>) => {
      state.relatedPatient = action.payload
    },
    setPatientName: (state, action: PayloadAction<string>) => {
      state.patientName = action.payload
    },
    resetEditFile: (state) => { 
 
      state.editedFile = null; 
      state.title = ''; 
      state.relatedPatient = null; 
      state.patientName = ''; 

    } 
  },
});

export const {
  setEditedFile, 
  setEditedFileTitle,
  setRelatedPatient, 
  setPatientName, 
  resetEditFile
} = editFileSlice.actions;

export default editFileSlice.reducer;