

const menuItems = [

    { type: 'item', section: 'overview', text: 'Data', iconName: 'bubble_chart', iconColor: '#FFFFFF', key:'i-overview' },

    { type: 'item', section: 'doctors', text: 'Doctors', iconName: 'groups', iconColor: '#FFFFFF', key:'i-doctors' },

    { type: 'item', section: 'patients', text: 'Patients', iconName: 'folder_shared', iconColor: '#FFFFFF', key: 'i-patients'}, 

    { type: 'item', section: 'supportTickets', text: 'Tickets', iconName: 'toll', iconColor: '#FFFFFF', key:'i-tickets' },

    { type: 'item', section: 'hackers', text: 'Hackers', iconName: 'security', iconColor: '#FFFFFF', key:'i-hackers' },

];

export default menuItems; 