import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';

const Support: React.FC = () => {
    return (
        <Box sx={{ textAlign: 'center', position: 'relative' }}>
            <IconButton
                sx={{ position: 'absolute', top: 0, right: 0 }}
                onClick={() => window.location.reload()}
            >
                <CloseIcon />
            </IconButton>
            <Typography variant="h6" sx={{ mb: 2 }}>
                24/7 Support
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <PhoneIcon sx={{ mr: 1, color: 'white' }} /> {/* Updated text color */}
                <a href="tel:+15149191137" style={{ color: 'white' }}>+1 (514) 919-1137</a> {/* Updated text color */}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <EmailIcon sx={{ mr: 1, color: 'white' }} /> {/* Updated text color */}
                <a href="mailto:support@medca.ai" style={{ color: 'white' }}>support@medca.ai</a> {/* Updated text color */}
            </Box>
        </Box>
    );
};

export default Support;