import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Routes 
import { getSupportTickets } from '../../../routes/support/support';
import { getTicketDetails } from '../../../routes/support/support';

// Redux 
import { setSupportTicket } from '../../../redux/features/support/supportTicket';
import { setOpenPopup } from '../../../redux/features/general/popup';
import { setAuthorPersonalInfo } from '../../../redux/features/support/supportTicket';

// Components 
import SupportTicketHeader from './formItems/SupportTicketHeader';
import SupportTicketRow from './formItems/SupportTicketRow';
import TicketDetails from './formItems/TicketDetails';
import Popup from '../../general/Popup';

// Icons 
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

const SupportTicketsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const colors = useAppSelector(state => state.theme.colors);
    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 

    const [supportTickets, setSupportTickets] = useState(Array<any>);

    // -- 

    const getSupportTicketsList = async () => { 

      const res = await getSupportTickets() as any; 

      if (res.data.status === '200') { 
        setSupportTickets(res.data.supportTickets); 
      };
    }; 

    // -- 

    const selectSupportTicket = (supportTicket: any) => { 
      dispatch(setSupportTicket(supportTicket)); 
      dispatch(setOpenPopup()); 
      getAdditionalInfo(supportTicket.author); 
    }; 

    // --

    const getAdditionalInfo = async (author: string) => { 
      const res = await getTicketDetails(author) as any; 
      if (res.data.status === '200') { 
          const author = res.data.author as any; 
          dispatch(setAuthorPersonalInfo(author)); 
      };
    }; 

    // -- 

    useEffect(() => { 
        getSupportTicketsList(); 
    },[]); 
    
    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '95vw',
              backgroundColor: colors?.supportTicketsListHeader,
              right: 0,
              position: 'absolute',
            }}>

            <SupportTicketHeader />

            { supportTickets?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2.5vh',
                height: '82.5' }}>
        
                <List dense>
        
                {supportTickets?.map((item, index) => (
        
                    <ListItem onClick={() => selectSupportTicket(item)} button key={index}>

                        <SupportTicketRow
                            author={item.author} 
                            ticketId={item._id}
                            status={item.status} 
                            category={item.category}
                            description={item.description}
                            createdAt={moment.utc(item.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {supportTickets?.length === 0 &&         
          
            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              height: '100%',
              width: '100%',
              color: colors?.text
            }}>

              <PersonAddAlt1Icon sx={{ fontSize: 70 }} />

              <div>{t('noSupportTickets')}</div>

          </Box>}

            </Box>

            { isPopupOpened && 
              <Popup>
                <TicketDetails />
              </Popup>}

        </Box>
    
      );

};

export default SupportTicketsList;