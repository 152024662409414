import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

import { getActivityByHour } from '../../../../../routes/hacker/users';

// Components 

import Box from '@mui/material/Box';
import { LineChart } from '@mui/x-charts/LineChart';
import EventLimiter from '../../../../../util/events/EventLimiter';
import CircularProgress from '@mui/material/CircularProgress';
import { setActivityChartData, setActivityChartXAxis } from '../../../../../redux/features/hacker/data/hackerData';


const ActivityChart: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const activityData = useAppSelector(state => state.hackerData.activityData); 
    const activityChartData = useAppSelector(state => state.hackerData.activityChartData); 
    const activityChartXAxis = useAppSelector(state => state.hackerData.activityChartXAxis); 

    const [allow, setAllow] = useState(false); 

    // -- 

    const getDate = async (date: any) => {

        const res = await getActivityByHour(date) as any;  

        if (res.data.status === 200) { 

            dispatch(setActivityChartData(res.data.activity)); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (activityData && activityChartData) { 

            if ((activityData?.xAxis.length === 24) && (activityChartData?.length === 0)) { 

                activityData?.xAxis.map((item, index) => { 

                    const formattedDate = moment(item).format('LT'); 
    
                    dispatch(setActivityChartXAxis(formattedDate)); 
    
                }); 
    
                activityData?.xAxis.forEach(async (date) => { 
    
                    try {
            
                        await EventLimiter.schedule(() => getDate(date));
                
                    } catch (err) {
                        console.log(err);
                    };
            
                });
    
            }; 

        };

    },[activityData, activityChartData]); 

    // -- 

    useEffect(() => { 

        if ((activityChartData?.length === 24) && (activityChartXAxis?.length === 24)) {

            setAllow(true); 

        }; 

    },[activityChartData, activityChartXAxis]); 

    // --

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            marginTop: 8,
        }}>

            {  (activityData && allow) ? 
             <LineChart
                width={window.innerWidth * 0.7}
                height={300}
                series={[
                { data: activityChartData, label: 'Daily Activity', color: 'rgb(45, 207, 196)' },
                ]}
                xAxis={[{ scaleType: 'point', data: activityChartXAxis }]}
            />:
            <CircularProgress size={60}/>
            }

        </Box>

  );

};

export default ActivityChart;