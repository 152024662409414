import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import Template from '../list/templatesList/Template';
import Typography from '@mui/material/Typography';
import { QRCodeCanvas } from 'qrcode.react';
import Logo from '../../../../general/navBar/Logo';

// Utils 


const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const qrValue = useAppSelector(state => state.templates.qrValue); 

    return (

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly', 
            height: '20%', 
            width: '50%', 
        }}>

            <Box>Create a Template</Box>

        </Box>

    );

};

export default Header;