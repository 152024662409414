import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Components 

import ActionsList from './ActionsList';
import TeamsList from './TeamsList';
import AssignmentContainer from './AssigmentContainer';

const TeamContainer: React.FC = () => {

  const file = useAppSelector(state => state.adminFile.file); 

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '20%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#383A40',
    }}>

      <ActionsList />

      <TeamsList />

      <AssignmentContainer />

      { !file && 
      <div style={{ 
        width: '13%', 
        height: '90%', 
        borderRadius: 10, 
        backgroundColor: 'rgba(56, 58, 64, 0.5)',
        position: 'absolute'
      }}></div>}

    </Box>

  );

};

export default TeamContainer;