import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface AccessRequestState {
  accessRequests: any[], 
  currentPatientId: any, 
  isRefreshAccessRequests: boolean, 
  initialRendering: boolean, 
};

// ---------------

const initialState: AccessRequestState = {
  accessRequests: [], 
  currentPatientId: null, 
  isRefreshAccessRequests: false,      
  initialRendering: true, 
};

// ---------------

export const accessRequestsSlice = createSlice({

  name: 'accessRequests',

  initialState,

  reducers: {

    setAccessRequests: (state, action: PayloadAction<any>) => {

      state.accessRequests = action.payload; 

    },
    setCurrentPatientId: (state, action: PayloadAction<any>) => {

      state.currentPatientId = action.payload; 

    }, 
    setIsRefreshAccessRequests: (state, action: PayloadAction<boolean>) => {

        state.isRefreshAccessRequests = action.payload; 
  
    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetAccessRequests: (state) => { 
        
        state.accessRequests = [];

        state.currentPatientId = null; 
        state.initialRendering = true; 

        // Refreshers purposely omitted. 

    } 
  },

}); 

// ---------------

export const { 
    setAccessRequests, 
    setCurrentPatientId, 
    setIsRefreshAccessRequests, 
    setInitialRendering, 
    resetAccessRequests
 } = accessRequestsSlice.actions;

export default accessRequestsSlice.reducer;