import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import { colorToComponents, degrees, PDFDocument, rgb, StandardFonts, setFontAndSize, TextAlignment, NonFullScreenPageMode  } from 'pdf-lib';

// Redux 

import { setFilteredPatientsList, setPatientSearchQuery, setSection, setTemplateMode } from '../../../../../../redux/features/template/template';
import { setIsComingFromTemplateEditing, setMode } from '../../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { setFile, setTitle } from '../../../../../../redux/features/doctor/Dashboard/documents/uploadFile';

// Components 

import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import GestureIcon from '@mui/icons-material/Gesture';

const DocumentOptions: React.FC = () => {

    const navigate = useNavigate(); 
    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedPatient = useAppSelector(state => state.template.selectedPatient); 
    const templateId = useAppSelector(state => state.template.templateId); 
    const doc = useAppSelector(state => state.template.doc); 
    const patientsList = useAppSelector(state => state.template.patientsList); 
    const filteredPatientsList = useAppSelector(state => state.template.filteredPatientsList); 
    const patientSearchQuery = useAppSelector(state => state.template.filteredPatientsList); 

    // -- 

    const keepEditing = () => { 

        dispatch(setSection('forms')); 

    }; 

    // -- 

    const formatTemplate = async () => { 

        dispatch(setTemplateMode('uploading')); 

        return; 

    }; 

    // -- 

    const saveTemplateAsFile = async () => { 

        await formatTemplate(); 

        const documentComponent = document.getElementById('template-pdf') as any;

        const canvas = await html2canvas(documentComponent);

        const imgData = canvas.toDataURL('image/png');

        const pdfDoc = await PDFDocument.create();

        const image = await pdfDoc.embedPng(imgData);

        const page = pdfDoc.addPage([image.width, image.height]);
        page.drawImage(image, { x: 0, y: 0, width: image.width, height: image.height });

        const pdfBytes = await pdfDoc.save();

        // -- 

        const titlePlaceHolder = `file_${templateId.slice(0, 20)}_${Math.floor(Math.random() * (100000 - 1 + 1) + 1)}.pdf`; 

        const newFile = new File([pdfBytes], titlePlaceHolder, { type: "application/pdf" });

        dispatch(setMode('uploadFile')); 
        dispatch(setFile(newFile)); 
        dispatch(setTitle(titlePlaceHolder)); 
        dispatch(setIsComingFromTemplateEditing(true)); 

        navigate('/dashboard');

    }; 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: 'auto',
      }}>

            <DescriptionIcon 
                sx={{ 
                    color: colors?.documentOptionsColor, 
                    fontSize: 40, 
                    width: 60, 
                    height: 70, 
                    marginTop: 2, 
                    marginBottom: 2, 
                    borderRadius: 3, 
                    backgroundColor: colors?.documentOptionsHover
                }} />

            <Box 
                sx={{ 
                    fontSize: 14, 
                    marginBottom: 2, 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: '80%',
                    textAlign: 'center',
                    color: colors?.text
                }}>
                {selectedPatient?.fullName}
            </Box>

            <Box
                onClick={saveTemplateAsFile}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '80%', 
                    borderRadius: 1, 
                    marginTop: 2, 
                    marginBottom: 2, 
                    height: 35,
                    fontSize: 14, 
                    cursor: 'pointer', 
                    color: 'white',
                    backgroundColor: colors?.autoCompleteSubmitButton,
                    '&:hover': { backgroundColor: colors?.autoCompleteSubmitButtonHover}
                }}
            > 
                <UploadFileOutlinedIcon sx={{ marginLeft: 1 }}/> 
                <Box sx={{ marginLeft: 1 }}>{t('saveAsPdf')}</Box>       
            </Box>

            <Box
                onClick={keepEditing}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '80%', 
                    borderRadius: 1, 
                    marginBottom: 2, 
                    height: 35,
                    fontSize: 14, 
                    cursor: 'pointer', 
                    color: 'white', 
                    backgroundColor: colors?.documentOptionsKeepEditing,
                    '&:hover': { backgroundColor: colors?.documentOptionsKeepEditingHover}
                }}
            > 
                <GestureIcon sx={{ marginLeft: 1 }}/> 
                <Box sx={{ marginLeft: 1 }}>{t('keepEditing')}</Box>       
            </Box>


      </Box>

  );

};

export default DocumentOptions;