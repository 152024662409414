import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import SidebarItem from './SidebarItem';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const Navigation: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '5vw',
          height: '90vh',
          position: 'absolute', 
          paddingTop: 5, 
          backgroundColor: colors?.templateSidebar, 
          left: 0, 
      }}>

        <SidebarItem title={'Forms'} section={'forms'}>
            <DriveFileRenameOutlineIcon sx={{ fontSize: 35 }} /> 
        </SidebarItem> 

        <SidebarItem title={'Fill Doc'} section={'autoComplete'}>
            <ContactPageIcon sx={{ fontSize: 35 }} /> 
        </SidebarItem> 

      </Box>

  );

};

export default Navigation;