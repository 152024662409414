import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface PatientDirectiveState {
  description: string, 
  isRefreshDirectives: boolean, 
};

// ---------------

const initialState: PatientDirectiveState = {
  description: '', 
  isRefreshDirectives: false, 
};

// ---------------

export const patientDirectiveSlice = createSlice({

  name: 'patientDirective',

  initialState,

  reducers: {

    setPatientDirective: (state, action: PayloadAction<any>) => {

      state.description = action.payload; 

    },
    setIsRefreshDirectives: (state, action: PayloadAction<any>) => {

        state.isRefreshDirectives = action.payload; 
  
    },
    resetPatientDirective: (state) => { 
        
        state.description = ''; 

        // Refreshers purposely omitted. 

    } 
  },

}); 

// ---------------

export const { 
    setPatientDirective, 
    setIsRefreshDirectives, 
    resetPatientDirective
 } = patientDirectiveSlice.actions;

export default patientDirectiveSlice.reducer;