import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Components 

import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import UploadOptions from './UploadOptions';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { setTemplateMode } from '../../../../redux/features/template/template';
import { resetUploadFile } from '../../../../redux/features/doctor/Dashboard/documents/uploadFile';
import { setIsComingFromTemplateEditing, setMode } from '../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

const SaveTemplate: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const isTemplateModified = useAppSelector(state => state.template.isTemplateModified); 
    const isModificationLoading = useAppSelector(state => state.template.isModificationLoading); 
    const templateMode = useAppSelector(state => state.template.templateMode); 
    
    const [isDropdownOpened, setIsDropdownOpened] = useState(false); 


    // -- 

    const handleDropdown = () => { 

      if (isDropdownOpened) { 

        setIsDropdownOpened(false); 

      } else if (isTemplateModified) { 

        setIsDropdownOpened(true); 

      }; 

    }; 

    // -- 

    const cancelUpload = () => { 

      dispatch(resetUploadFile()); 
      dispatch(setMode('')); 
      dispatch(setIsComingFromTemplateEditing(false)); 
      dispatch(setTemplateMode('')); 

    }; 
    
    // -- 

    return (

      <Box 
        onClick={handleDropdown}
        sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center', 
      }}>

        { isModificationLoading ? 
          <CircularProgress size={14} />:
          <CloudDoneOutlinedIcon sx={{ fontSize: 20, height: 30, color: 'rgb(130, 130, 130)' }} /> }

        { !templateMode ? 
        
        <Box
            sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center', 
                padding: 1, 
                fontSize: 12, 
                marginLeft: 3, 
                borderRadius: 1, 
                marginRight: 2, 
                height: 30,
                color: colors?.text, 
                cursor: isTemplateModified ? 'pointer' : 'default', 
                backgroundColor: isTemplateModified ? colors?.templateModified : colors?.templateNotModified, 
                '&:hover': { backgroundColor: isTemplateModified ? colors?.templateModifiedHover : colors?.templateNotModified }
            }}
        >

          <Box>{'Save Template'}</Box>

          <DownloadIcon sx={{ marginLeft: 1, fontSize: 16 }} /> 

        </Box>: 

          <Box
          onClick={cancelUpload}
          sx={{ 
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center', 
              padding: 1, 
              fontSize: 12, 
              marginLeft: 3, 
              borderRadius: 1, 
              marginRight: 2, 
              height: 30,
              color: 'white', 
              cursor: 'pointer',
              backgroundColor: colors?.deleteIcon, 
              '&:hover': { backgroundColor: 'rgb(221, 69, 32)' }
          }}
          >

            <Box>{'Cancel Upload'}</Box>

            <HighlightOffOutlinedIcon sx={{ marginLeft: 1, fontSize: 16 }} /> 

          </Box>}



        { isDropdownOpened && <UploadOptions />}

      </Box>

  );

};

export default SaveTemplate;