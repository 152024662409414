import React, { useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTheme } from '../../../themeContext';

type LogoProps = {
  size?: number;
};

const Logo: React.FC<LogoProps> = ({ size = 30 }) => {
  const { isLightMode } = useTheme();

  const type = useAppSelector(state => state.theme.type); 

  const [logoSrc, setLogoSrc] = useState(''); 

  useEffect(() => { 

    if (type === 'light' || !type) { 

      setLogoSrc(require('../../../assets/logos/medca_logo_blue_dark_blue.png')); 

    } else if (type === 'dark') { 

      setLogoSrc(require('../../../assets/logos/medca_logo_blue_white.png')); 

    }; 

  },[type]); 

  return (
    <Box
      component="img"
      sx={{
        height: `${size}px`,
        width: 'auto',
      }}
      alt="Solutions Medca Inc."
      src={logoSrc}
    />
  );
};

export default Logo;