import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from './../../../../redux/hooks';

// Redux 

import { setSection } from '../../../../redux/features/template/template';

// Components 

import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

type Props = {
    children?: React.ReactNode,
    title: string, 
    section: string, 
  };


const SidebarItem: React.FC<Props> = ({ children, title, section }) => {

    const dispatch = useAppDispatch(); 

    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const templateSection = useAppSelector(state => state.template.section); 

    // -- 

    const changeSection = () => { 

        if (section) { 

            dispatch(setSection(section)); 

        };

    }; 

    // -- 

    return (

        <Box
          onClick={changeSection} 
          sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            width: '75%', 
            height: 100,
            borderRadius: 2,
            cursor: 'pointer',
            color: section === templateSection ? colors?.selectedSideBarItem : 'transparent',
          }}
        >

            <ListItemIcon 
                sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                borderRadius: 4,
                paddingTop: 1,
                paddingBottom: 1,
                marginBottom: 0.3,
                fontSize: 35, 
                color: section === templateSection ? colors?.selectedSideBarItem : colors?.sidebarUnselectedItem,
                '&:hover': { backgroundColor: colors?.templateSidebarItemHover}}
                }>
                  {children}
            </ListItemIcon>


          <Box
            sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center', 
              justifyContent: 'space-evenly', 
              width: '100%', 
              fontSize: 12,
              color: section === templateSection ? colors?.highlightedText : colors?.text
            }}
          >{t(title)}</Box>

        </Box>

    );

};

export default SidebarItem;