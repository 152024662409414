import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Routes 

import { getNotifications, getPatientLogs } from '../../../../../../../routes/doctor/patients';

// Redux 

import { setDashboardSection } from '../../../../../../../redux/features/general/navigation';
import { setCurrentPatientId, setIsRefreshNotifications, setNotifications, setInitialRendering } from '../../../../../../../redux/features/doctor/Dashboard/patients/notifications';

// Components 

import NotificationsHeader from './NotificationsHeader';
import NotificationRow from './NotificationRow';

// Icons 

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const NotificationsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const colors = useAppSelector(state => state.theme.colors);
    const notifications = useAppSelector((state) => state.notifications.notifications); 
    const initialRendering = useAppSelector((state) => state.notifications.initialRendering); 

    const isRefreshNotifications = useAppSelector((state) => state.notifications.isRefreshNotifications); 

    const [isLoading, setIsLoading] = useState(false); 

    // -- 

    const getNotificationsList = async () => { 

      setIsLoading(true); 

      const res = await getNotifications() as any;

      setIsLoading(false); 

      if (res?.data.status === 200) { 

        dispatch(setNotifications(res.data.notifications)); 

      };

    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

        getNotificationsList(); 

        dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // --

    useEffect(() => { 

      if (isRefreshNotifications) { 

        getNotificationsList(); 

        dispatch(setIsRefreshNotifications(false)); 

      }; 

    },[isRefreshNotifications]); 
    
    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '95%',
            marginTop: '1.5%', 
            width: '95%',
            backgroundColor: colors?.container,
        }}>

            <NotificationsHeader />

            { notifications?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2.5vh',
                scrollbarWidth: 'none',
                height: '82.5' }}>
        
                <List dense>
        
                {notifications?.map((item, index) => (
        
                    <ListItem button key={index}>

                        <NotificationRow
                            type={item.type} 
                            description={item.description}
                            createdAt={moment.utc(item.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {isLoading &&         
          
              <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly', 
                  height: '100%',
                  width: '80%', 
              }}>

                  <Box
                      sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}>

                      <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                      <Box>Retrieving Recent Notifications...</Box>

                  </Box>

              </Box>}

            </Box>
    
      );

};

export default NotificationsList;