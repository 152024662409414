import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface PatientState {
  description: string, 
  isRefreshNotes: boolean, 
  dropdownOpened: string, 
};

// ---------------

const initialState: PatientState = {
  description: '', 
  isRefreshNotes: false, 
  dropdownOpened: '',  
};

// ---------------

export const patientNoteSlice = createSlice({

  name: 'patientNote',

  initialState,

  reducers: {

    setPatientNoteDescription: (state, action: PayloadAction<any>) => {

      state.description = action.payload; 

    },
    setIsRefreshNotes: (state, action: PayloadAction<boolean>) => {

        state.isRefreshNotes = action.payload; 
  
    },
    setDropdownOpened: (state, action: PayloadAction<any>) => {

      state.dropdownOpened = action.payload; 

    },
    resetPatientNote: (state) => { 
        
        state.description = ''; 
        state.dropdownOpened = ''; 

        // Refreshers purposely omitted. 

    } 
  },

}); 

// ---------------

export const { 
    setPatientNoteDescription, 
    setIsRefreshNotes, 
    setDropdownOpened, 
    resetPatientNote
 } = patientNoteSlice.actions;

export default patientNoteSlice.reducer;