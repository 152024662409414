import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setLastName } from '../../../../redux/features/hacker/users/createHacker';

// Components 

import TextField from '@mui/material/TextField';

const LastName: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const lastName = useAppSelector(state => state.createHacker.lastName);

  const [name, setName] = useState(''); 

  // Redux 

  useEffect(() => {

    dispatch(setLastName(name)); 

  },[name]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginBottom: '3vh',
    }}>

        <TextField 
          sx={{ width: '40%', alignSelf: 'center' }} 
          onChange={(e) => setName(e.target.value)}
          id="standard-basic" 
          label="Last Name" 
          value={lastName}
          variant="standard" />


    </Box>

  );

};

export default LastName;