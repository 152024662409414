import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';

// Redux 

import { setIsEditedDoctorPreferences, setIsRefreshDoctorPreferences, setLanguage, setNotifications, setTheme } from '../../redux/features/doctor/Account/preferences';

// Routes 

import { getDoctorPreferences, updateDoctorPreferences } from '../../routes/doctor/patients';

// Components 

import Preferences from './preferences/Preferences';
import Account from './account/Account';
import Notifications from './notifications/Notifications';

const Container: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const navigate = useNavigate(); 

  const colors = useAppSelector(state => state.theme.colors);

  const section = useAppSelector(state => state.navigation.section);
  const doctorPreferences = useAppSelector(state => state.doctorPreferences);
  const isRefreshDoctorPreferences = useAppSelector(state => state.doctorPreferences.isRefreshDoctorPreferences);
  const isEditedDoctorPreferences = useAppSelector(state => state.doctorPreferences.isEditedDoctorPreferences);

  const handleDoctorPreferences = async () => { 

    const res = await getDoctorPreferences() as any; 

    if (res.data.status === 200) { 

      dispatch(setLanguage(res?.data.doctorPreferences.language)); 
      dispatch(setTheme(res.data.doctorPreferences.theme)); 
      dispatch(setNotifications(res.data.doctorPreferences.notifications)); 
      
    }; 

  }; 

  const getUpdatedDoctorPreferences = async () => { 

    const res = await updateDoctorPreferences(doctorPreferences) as any; 

    if (res.data.status === 200) { 

      dispatch(setLanguage(res?.data.doctorPreferences.language)); 
      dispatch(setTheme(res.data.doctorPreferences.theme)); 
      dispatch(setNotifications(res.data.doctorPreferences.notifications)); 
      
    }; 

  }; 

  // -- 

  useEffect(() => { 

    handleDoctorPreferences(); 

  },[]); 

  // -- 

  useEffect(() => { 

    if (isRefreshDoctorPreferences) { 

      handleDoctorPreferences(); 

      dispatch(setIsRefreshDoctorPreferences(false));

    }; 

  },[isRefreshDoctorPreferences]); 

  // -- 

  useEffect(() => { 

    if (isEditedDoctorPreferences) { 

      getUpdatedDoctorPreferences(); 

      dispatch(setIsEditedDoctorPreferences(false)); 

    };

  },[isEditedDoctorPreferences]);

  // -- 

  return (

    <React.Fragment>

        <CssBaseline />

        <Box component="main" sx={{ 
            display: 'flex', 
            height: '100vh', 
            width: '100vw',
            left: 0, 
            overflowX: 'hidden',
            position: 'absolute',
            backgroundColor: colors?.interface, 
            color: colors?.highlightedText }}>

            { section === 'preferences' && <Preferences />}

            { section === 'notifications' && <Notifications />}

            { section === 'account' && <Account />}

        </Box>

    </React.Fragment>

  );

};

export default Container;