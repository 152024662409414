import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Utils 

import getRandomColor from '../../../../../../../util/style/getRandomColor';
import getStatusColor from '../../../../../../../util/style/getStatusColor';

// Routes 

// Components 

import MoreVertIcon from '@mui/icons-material/MoreVert';

type Props = {
    type?: String, 
    description?: string, 
    createdAt?: string, 
};


const NotificationRow: React.FC<Props> = ({ type, description, createdAt }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    const [descriptionColor, setDescriptionColor] = useState(''); 
    const [notificationTitle, setNotificationTitle] = useState(''); 

    // -- 

    useEffect(() => { 

        if (type) { 

            type === 'newPatientFile' && setDescriptionColor('rgb(45, 207, 62)');
            type === 'newPatientFile' && setNotificationTitle(String(t('newFile'))); 

        }; 

    },[type]); 


  return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
    }}>
            {/* Initials / User avatar */}

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                color: colors?.text, 
                width: '18%', 
                marginLeft: '2%',
                textAlign: 'left',
                overflow: 'hidden',
            }}>{notificationTitle}
            </div>

            <div style={{ 
                width: '50%', 
                marginLeft: '5%',
                color: descriptionColor, 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{description}</div>

            <div style={{ 
                width: '15%', 
                color: colors?.text, 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{createdAt}</div>

            <div style={{ 
                width: '10%', 
                color: colors?.text, 
                textAlign: 'center',
                overflow: 'hidden', 
            }}>
                <MoreVertIcon></MoreVertIcon>
            </div>

    </Box>

  );

};

export default NotificationRow;