import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { colorToComponents, degrees, PDFDocument, rgb, StandardFonts, setFontAndSize, TextAlignment  } from 'pdf-lib';

// Components 

import Box from '@mui/material/Box';
import PatientsList from './PatientsList';
import AutoCompleteInfo from './AutoCompleteInfo';
import Header from './Header';
import Loading from './Loading';
import DocumentOptions from './DocumentOptions';

const AutoComplete: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const selectedPatient = useAppSelector(state => state.template.selectedPatient); 
    const isAutoCompleteProcessing = useAppSelector(state => state.template.isAutoCompleteProcessing); 
    const isDocumentAutoCompleted = useAppSelector(state => state.template.isDocumentAutoCompleted); 


    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: '100%',
          paddingTop: 1, 
      }}>

        { !selectedPatient && <Header />} 

        { !selectedPatient && <PatientsList />}

        { (selectedPatient && !isAutoCompleteProcessing && !isDocumentAutoCompleted) && <AutoCompleteInfo />}

        { (isAutoCompleteProcessing && !isDocumentAutoCompleted) && <Loading />}

        { isDocumentAutoCompleted && <DocumentOptions />}

      </Box>

  );

};

export default AutoComplete;