import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface UserAccessState {
  usersListLength: number,
  selectedUser: any,
  refreshUsersList: boolean,
  selectedDoctor: any, 
  isRefreshDoctorsList: boolean, 
}

// ---------------

const initialState: UserAccessState = {
    usersListLength: 0, 
    selectedUser: null,
    refreshUsersList: false, 
    selectedDoctor: null, 
    isRefreshDoctorsList: false, 
};

// ---------------


export const userAccessSlice = createSlice({

  name: 'userAccess',

  initialState,

  reducers: {

    setUsersListLength: (state, action: PayloadAction<number>) => {

        state.usersListLength = action.payload; 
    },
    setSelectedUser: (state, action: PayloadAction<any>) => { 
        
        state.selectedUser = action.payload;  
    },
    setRefreshUsersList: (state, action: PayloadAction<boolean>) => { 

        state.refreshUsersList = action.payload; 

    },
    setSelectedDoctor: (state, action: PayloadAction<any>) => { 

      state.selectedDoctor = action.payload; 

    },
    setIsRefreshDoctorsList: (state, action: PayloadAction<boolean>) => { 

      state.isRefreshDoctorsList = action.payload; 

    },
    setResetUserAccess: (state) => { 
        
      state.selectedUser = null;
      state.refreshUsersList = false;
      state.selectedDoctor = null;
      state.isRefreshDoctorsList = false;
    } 

  },

}); 

// ---------------

export const { 
  setUsersListLength, 
  setSelectedUser, 
  setSelectedDoctor, 
  setIsRefreshDoctorsList, 
  setResetUserAccess,
  setRefreshUsersList } = userAccessSlice.actions;

export default userAccessSlice.reducer;