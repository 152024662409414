import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Components 

import OptionRow from './OptionRow';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DescriptionIcon from '@mui/icons-material/Description';
import NotesIcon from '@mui/icons-material/Notes';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import BubbleChartOutlinedIcon from '@mui/icons-material/BubbleChartOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,

};

const Options: React.FC<Props> = ({ children, isPortraitMode }) => {

    const colors = useAppSelector(state => state.theme.colors);

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '5%',
        height: '95%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
    }}>

        <OptionRow iconColor={colors?.account} hoverColor={colors?.accountHover} section='info'>
            <AccountBoxIcon />
        </OptionRow>

        <OptionRow iconColor={colors?.editPatient} hoverColor={colors?.editPatientHover} section='editPatient'>
            <AppRegistrationIcon />
        </OptionRow>

        <OptionRow iconColor={colors?.filesOption} hoverColor={colors?.filesOptionHover} section='files'>
            <DescriptionIcon />
        </OptionRow>

        <OptionRow iconColor={colors?.notesOption} hoverColor={colors?.notesOptionHover} section='notes'>
            <SpeakerNotesIcon />
        </OptionRow>

        <OptionRow iconColor={colors?.directivesOption} hoverColor={colors?.directivesOptionHover} section='directives'>
            <CrisisAlertIcon />
        </OptionRow>

        <OptionRow iconColor={colors?.logsOption} hoverColor={colors?.logsOptionHover} section='patientLogs'>
            <BubbleChartOutlinedIcon />
        </OptionRow>

        <OptionRow iconColor={colors?.settingsOption} hoverColor={colors?.settingsOptionHover} section='settings'>
            <SettingsIcon />
        </OptionRow>

    </Box>
  );
};

export default Options;