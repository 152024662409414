import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Action from '../Action';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';

const FolderDropdown: React.FC = () => {

    const { t } = useTranslation(); 

    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                width: '100%', 
                cursor: 'pointer',
            }}
        >
            <Action title={'addFolder'} action={'createFolder'} type='dropdown'>
                <AddCircleOutlineIcon /> 
            </Action> 

            <Action title={'uploadFile'} action={'uploadFile'} type='dropdown'>
                <UploadOutlinedIcon /> 
            </Action>

            { !selectedFolder?.protected &&
            <Action title={'renameFolder'} action={'renameFolder'} type='dropdown'>
                <DriveFileRenameOutlineIcon /> 
            </Action>}

            <Action title={'moveTo'} action={'moveFolder'} type='dropdown'>
                <DriveFileMoveOutlinedIcon /> 
            </Action>

            { selectedFolder?.pinned ? 
            <Action title={'removePin'} action={'removePin'} type='dropdown'>
                <PushPinOutlinedIcon /> 
            </Action>: 
            <Action title={'pinFolder'} action={'pinFolder'} type='dropdown'>
                <PushPinOutlinedIcon /> 
            </Action>} 

            { !selectedFolder?.protected && 
            <Action title={'deleteFolder'} action={'deleteFolder'} type='dropdown'>
                <DeleteOutlineIcon /> 
            </Action>}

            { selectedFolder?.relatedPatient && 
            <Action title={'patientInfo'} action={'patientInfo'} type='dropdown'>
                <InfoOutlinedIcon /> 
            </Action>}

        </Box>



    )

}; 

export default FolderDropdown; 