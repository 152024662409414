import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../redux/hooks';

// Redux 

import { setRefreshUsersList, setSelectedUser } from './../../../../redux/features/admin/dashboard/users/userAccess';
import { setNotificationMessage } from './../../../../redux/features/general/notification';

// Routes 

import { revokeUserAccess } from './../../../../routes/admin/users';

// Icons 

import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const UserActions: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch();

    const selectedUser = useAppSelector(state => state.adminUserAccess.selectedUser); 

    const listItems = [

        { type: 'header', title: t('name')},

        { text: `${selectedUser?.firstName} ${selectedUser?.lastName}`, action: '', icon: <PersonIcon sx={{ color: 'white' }} /> },

        { type: 'header', title: t('actions')},

        { text: 'Revoke', action: 'revoke', icon: <PersonOffIcon sx={{ color: 'white' }} /> },
    ];

    const handleAction = async (action: string | any) => { 

      if (selectedUser) { 

        if (action === 'revoke') { 

          const status = await revokeUserAccess(selectedUser); 

          if (status === 200) { 

            dispatch(setRefreshUsersList(true)); 

            dispatch(setSelectedUser('')); 

            dispatch(setNotificationMessage('Access Revoked')); 

          }; 

        }; 
  
      };

    };
    
    
      return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '20%',
            height: '95%', 
            borderRadius: 5,
            backgroundColor: '#383A40',
        }}>

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '95%'
          }}>
  
            {selectedUser &&
            
            <Box sx={{ 
              overflow: 'auto',
              width: '90%',
              marginTop: '2vh' }}>
      
              <List dense>
      
                {listItems.map((item, index) => (
      
                  <ListItem onClick={() => handleAction(item.action)} button key={index}>
      
                      { item.type !== 'header' && <ListItemIcon sx={{ color: '#C2C5C8' }}>{item.icon}</ListItemIcon>}
      
                      { item.type !== 'header' && <ListItemText sx={{ color: '#C2C5C8' }} primary={item.text} />}

                      { item.type === 'header' && 
                          <ListItemText 
                              sx={{ 
                                  marginTop: '2vh',
                                  marginBottom: '2vh',
                                  textAlign: 'left',
                                  color: '#C2C5C8', 
                              }}
                              primary={item.title}
                          />}
      
                  </ListItem>
                ))}
      
              </List>
      
            </Box>}

            {!selectedUser &&         
          
              <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                height: '100%',
                width: '100%',
                color: '#C2C5C8'
              }}>

                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                  height: '30%',
                  width: '100%',
                  color: '#C2C5C8'
                }}>

                    <AccountBoxIcon sx={{ fontSize: 40 }} />

                    <div>No user selected.</div>

                </div>



              </Box>}

          </Box>
          
        </Box>
      );

};

export default UserActions;