import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from './../../../../../../../redux/hooks';

// Redux 

import { setNote } from './../../../../../../../redux/features/admin/dashboard/documents/note';

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const NoteViewer: React.FC= () => {

    const dispatch = useAppDispatch(); 

    const note = useAppSelector(state => state.adminNote.note); 

    const closeNote = () => { 

        dispatch(setNote(null)); 

    }; 


    return (

    <div 

    style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '90vw',
        height: '95vh',
        bottom: 0,
        right: 0,
        position: 'absolute', 
        zIndex: 1,
        backgroundColor: 'rgba(56, 58, 64, 0.75)'
    }}>


        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            width: '50%',
            height: '50%',
            borderRadius: 10,
            zIndex: 2,
            backgroundColor: '#313338',
            overflow: 'auto',
            scrollbarWidth: 'none',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                marginTop: '3vh',
                marginBottom: '2vh', 
                marginLeft: '1vw', 
            }}>Author</div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#C2C5C8',
                fontSize: 15,
                marginLeft: '1vw', 
            }}>

                <PersonIcon sx={{ color: 'lightblue', marginRight: '1vw' }}/> 

                <div>{note?.author}</div>

            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                marginTop: '3vh',
                marginBottom: '2vh', 
                marginLeft: '1vw', 
            }}>Tags #</div>

            {note?.tags.includes('review') &&
            
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#C2C5C8',
                fontSize: 15,
                marginLeft: '1vw', 
            }}>

                <RateReviewIcon sx={{ color: 'purple', marginRight: '1vw' }} />

                <div>Requested Review</div>

            </div>}

            {note?.tags.includes('revoked') &&
            
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#C2C5C8',
                fontSize: 15,
                marginTop: '1vh',
                marginLeft: '1vw', 
            }}>

                <RemoveCircleOutlineIcon sx={{ color: 'red', marginRight: '1vw' }} />

                <div>Revoked Document</div>

            </div>}

            {!note?.tags &&
            
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontSize: 14,
                marginTop: '3vh',
                marginBottom: '3vh', 
                marginLeft: '1vw', 
                fontStyle: 'italic', 
                color: '#C2C5C8',
            }}>The author left not tags.</div>}


            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                marginTop: '3vh',
                marginBottom: '2vh', 
                marginLeft: '1vw', 
            }}>Comments</div>

            {note?.description &&

            <div style={{
                flex: 1,
                flexWrap: 'wrap',
                flexFlow: 'column',
                maxWidth: '95%',
                overflowWrap: 'normal',
                color: '#C2C5C8',
                fontSize: 12,
                marginTop: '2vh',
                marginBottom: '5vh', 
                marginLeft: '1vw', 
            }}>{note.description}</div>}

            {!note?.description &&
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontSize: 14,
                marginTop: '3vh',
                marginBottom: '3vh', 
                marginLeft: '1vw', 
                fontStyle: 'italic', 
                color: '#C2C5C8',
            }}>The author left no comments.</div>}

        </div>

        
        <div 
            onClick={closeNote}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            left: 0,
        }}></div>

        <div 
            onClick={closeNote}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            top: 0,
        }}></div>

        <div 
            onClick={closeNote}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            right: 0,
        }}></div>

        <div 
            onClick={closeNote}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            bottom: 0,
        }}></div>


    </div>

    );

};

export default NoteViewer;