import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './../redux/hooks';

// -- Components 

import Notification from '../components/general/Notification';

import DoctorContainer from './../components/doctor/dashboard/Container';
import AdminContainer from './../components/admin/dashboard/Container';

const Dashboard: React.FC = () => {

  const accountType = useAppSelector(state => state.meta.accountType);

  return (

    <>

    { accountType === 'doctor' && <DoctorContainer />}

    { accountType === 'admin' && <AdminContainer />}

    <Notification /> 

    </>

  );
};

export default Dashboard;
