import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateNoteState {
  author: string,
  tags: string[], 
  file: string, 
  description: string, 
}

// ---------------

const initialState: CreateNoteState = {
    author: '', 
    tags: [], 
    file: '', 
    description: '', 
};

// ---------------


export const adminCreateNoteSlice = createSlice({

  name: 'adminCreateNote',

  initialState,

  reducers: {

    setAuthor: (state, action: PayloadAction<string>) => {
        state.author = action.payload
    },
    addTag: (state, action: PayloadAction<string>) => { 
        
        state.tags.push(action.payload); 
    }, 
    removeTag: (state, action: PayloadAction<string>) => { 

        const currentTags = state.tags; 

        const tagToBeRemoved = currentTags.indexOf(action.payload); 

        currentTags.splice(tagToBeRemoved, 1); 

        state.tags = currentTags; 

    },
    setFile: (state, action: PayloadAction<string>) => { 

        state.file = action.payload; 
    }, 
    setDescription: (state, action: PayloadAction<string>) => { 
        
        state.description = action.payload; 

    },
    resetCreateNote: (state) => { 
        
        state.author = ''; 
        state.tags = []; 
        state.file = ''; 
        state.description = ''; 
    } 
  },

}); 

// ---------------

export const { setAuthor, addTag, removeTag, setDescription, setFile, resetCreateNote } = adminCreateNoteSlice.actions;


export default adminCreateNoteSlice.reducer;