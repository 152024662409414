import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface NavigationState {
  section: string,
  subSection: string,
  sideBarMenu: any, 
  subSideBarMenu: any, 
}

// ---------------

const initialState: NavigationState = {
  section: '', 
  subSection: '', 
  sideBarMenu: null, 
  subSideBarMenu: null, 
};

// ---------------


export const navigationSlice = createSlice({

  name: 'navigation',

  initialState,

  reducers: {

    setDashboardSection: (state, action: PayloadAction<string>) => {
        state.section = action.payload
    },
    setDashboardSubSection: (state, action: PayloadAction<string>) => { 
        state.subSection = action.payload  
    },
    setSideBarMenu: (state, action: PayloadAction<any>) => { 
        state.section = action.payload[0].section; 
        state.sideBarMenu = action.payload; 
    }, 
    setSubSideBarMenu: (state, action: PayloadAction<any>) => { 

      state.subSection = action.payload[0].section; 
      state.subSideBarMenu = action.payload; 

    },
    setResetNavigation: (state) => { 
        
      state.section = ''; 
      state.subSection = ''; 
      state.sideBarMenu = null; 

    } 
  },

}); 

// ---------------

export const { setDashboardSection, setDashboardSubSection, setSubSideBarMenu, setResetNavigation, setSideBarMenu } = navigationSlice.actions;

export default navigationSlice.reducer;