import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../redux/hooks';

// Components 

import UsersList from './users/UsersList';
import UserActions from './users/UserActions';

const UsersInterface: React.FC = () => {

  return (

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      width: '90%',
      height: '95%', 
      left: '10%',
      bottom: 0,
      position: 'absolute',
      backgroundColor: '#201F1E',
    }}>

      <UsersList />

      <UserActions />

    </Box>

  );

};

export default UsersInterface;