import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


// Icons 

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props { 
    icon?: React.ReactNode, 
    title?: any,
    description?: any, 
    navigateTo?: any
}

const DescriptionBox: React.FC<Props> = ({ icon, title, description, navigateTo }) => {

    const { t } = useTranslation();
    
    const navigate = useNavigate(); 

    const colors = useAppSelector(state => state.theme.colors);

    // -- 

    const handleNavigation = () => {

        navigate(navigateTo);

    }; 

    return (

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: 'auto',
                width: '25%',
                color: colors?.legalDescription,
            }}
        > 

        {icon}

        <Box
            sx={{
                fontSize: 18, 
                marginBottom: 2,
            }}
        >{title}</Box>

        <Box>{description}</Box>

        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 3,
                cursor: 'pointer',
                color: colors?.legalLearMore,
                '&:hover': {
                    color: colors?.legalLearnMoreHover,
                },
            }}>
            <Box 
            onClick={() => handleNavigation()}
            sx={{ 
                marginRight: 2
            }}>Learn More</Box>
            <ArrowForwardIcon />
        </Box>

        </Box>
  );
};

export default DescriptionBox;