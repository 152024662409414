import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Components 

import SectionHeader from './formItems/SectionHeader';
import Author from './formItems/Author';
import Status from './formItems/Status';
import Category from './formItems/Category'; 
import Description from './formItems/Description'; 
import SubmitButton from './formItems/SubmitButton'; 
import BackButton from './formItems/BackButton';

const CreateSupportTicket: React.FC = () => {

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '90%',
        marginTop: '10vh', 
        width: '80vw',
        marginLeft: '5vw',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
    }}>

        <BackButton />

        <SectionHeader title={'Create a Support Ticket'} />

        <Author />

        <SectionHeader title={'Status'} />

        <Status />

        <SectionHeader title={'Description'} />

        <Category />

        <Description />

        <SubmitButton />

    </div>

  );

};

export default CreateSupportTicket;