import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import BackButton from './BackButton';
import Box from '@mui/material/Box';
import NotificationRow from './NotificationRow';

const Notifications: React.FC = () => {

  const { t } = useTranslation();

  const notifications = useAppSelector((state) => state.doctorPreferences.notifications);

  return (

    <Box style={{ 
        display: 'flex',
        flexDirection: 'column',
        height: '95vh',
        width: '95vw',
        bottom: 0,
        right: 0, 
        overflowX: 'hidden',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        position: 'absolute', 
    }}>

        <BackButton /> 

        <NotificationRow title={t('allowNotifications')} section={'disabled'} switchValue={notifications?.disabled} />
        <NotificationRow title={t('securityAlerts')} section={'securityAlerts'} switchValue={notifications?.securityAlerts} />
        <NotificationRow title={t('accessRequests')} section={'accessRequests'} switchValue={notifications?.accessRequests} />
        <NotificationRow title={t('newPatientFile')} section={'newPatientFile'} switchValue={notifications?.newPatientFile} />
        <NotificationRow title={t('newPatientNote')} section={'newPatientNote'} switchValue={notifications?.newPatientNote} />
        <NotificationRow title={t('supportNotif')} section={'support'} switchValue={notifications?.support} />
        <NotificationRow title={t('insurersNotif')} section={'insurers'} switchValue={notifications?.insurers} />

    </Box>

  );

};

export default Notifications;