import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Redux 

import { setAccountType } from './../../../../../redux/features/admin/dashboard/users/createUser';

// Components 

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const AccountType: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const email = useAppSelector(state => state.adminCreateUser.email); 

  const [switchValue, setSwitchValue] = useState(false); 

  // Redux 

  useEffect(() => {

    if (!switchValue) { 

      dispatch(setAccountType('employee'));

    } else if (switchValue) { 

      // If the switch is on. 

      dispatch(setAccountType('admin')); 

    }; 
 
  },[switchValue]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginLeft: '1vw',
        marginBottom: '3vh',
    }}>

        <FormGroup>
            <FormControlLabel 
              control={<Switch 
              onChange={(e) => setSwitchValue(e.target.checked)}
              defaultChecked={false} />} label="Administrator" />
        </FormGroup>


    </Box>

  );

};

export default AccountType;