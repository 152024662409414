import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';

type Props = {
    children?: React.ReactNode;
};

const OptionsRow: React.FC<Props> = ({ children }) => {

    return ( 

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                height: 40, 
            }}
        >
            {children}

        </Box>

    )

}; 

export default OptionsRow; 