import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Components 

import Box from '@mui/material/Box';
import FontSize from './items/FontSize';
import TextStyle from './items/TextStyle';
import TextStructure from './items/TextStructure';
import ModificationStatus from './items/ModificationStatus';
import SaveTemplate from './items/SaveTemplate';

const Navbar: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedTextField = useAppSelector(state => state.template.selectedTextField); 

    // -- 

    return (

      <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-between', 
            width: '100vw',
            height: 40,
            top: '5vh', 
            left: 0, 
            flexShrink: 0, 
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            zIndex: 6000,
            padding: '0 1vw',
            backgroundColor: colors?.templateNavBar, 
            position: 'absolute' 
      }}>

        <ModificationStatus /> 

        <Box
          sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            height: '100%', 
          }}
        >

          <TextStructure /> 

          <FontSize /> 

          <TextStyle />

        </Box>

        <SaveTemplate />

      </Box>

  );

};

export default Navbar;