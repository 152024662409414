import React from 'react';
import Box from '@mui/material/Box';
import ContactPageIcon from '@mui/icons-material/ContactPage';

const DocumentViewer: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '50%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#383A40',
    }}>

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '95%',
        height: '95%', 
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'dashed',
        borderColor: 'rgba(255, 255, 255, 0.2)',
      }}>

        <ContactPageIcon sx={{ fontSize: 70, marginBottom: 1, color: 'rgba(255, 255, 255, 0.4)' }} />

      </Box>

    </Box>

  );

};

export default DocumentViewer;