import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Routes


// Components 

import Box from '@mui/material/Box';
import SearchBar from './search/SearchBar';
import Options from './options/Options';

const Overview: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const patient = useAppSelector(state => state.patient.patient);

    // -- 

    return (

    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
            padding: '2rem',
            height: '90vh',
            overflow: 'auto',
            alignItems: 'center',
            scrollbarWidth: 'none',
        }}
    >

        <SearchBar /> 

        <Options /> 

    </Box>

  );
};

export default Overview;