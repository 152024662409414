import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import DataBox from './DataBox';

import GroupsIcon from '@mui/icons-material/Groups';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import TollIcon from '@mui/icons-material/Toll';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import GppGoodIcon from '@mui/icons-material/GppGood';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';

const Overview: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const data = useAppSelector(state => state.hackerData.data); 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center', 
            width: '95%', 
            marginTop: 5,
        }}>

            <DataBox title={'Active Doctors'} data={data?.activeDoctors}>
                <GroupsIcon sx={{ color: 'rgb(45, 153, 207)' }} />
            </DataBox>

            <DataBox title={'Patients'} data={data?.patients}>
                <ContactMailIcon sx={{ color: 'rgb(45, 207, 62)' }} />
            </DataBox>

            <DataBox title={'Tickets'} data={data?.tickets}>
                <TollIcon sx={{ color: 'rgb(188, 45, 207)' }} />
            </DataBox>

            <DataBox title={'Pending Accounts'} data={data?.pendingAccounts}>
                <PendingActionsIcon sx={{ color: 'rgb(207, 137, 45)' }} />
            </DataBox>

            <DataBox title={'Access Requests'} data={data?.accessRequests}>
                <GppGoodIcon sx={{ color: 'rgb(45, 207, 62)' }} />
            </DataBox>

            <DataBox title={'Software Version'} data={data?.softwareVersion}>
                <BrowserUpdatedIcon sx={{ color: 'rgb(45, 153, 207)' }} />
            </DataBox>

        </Box>

  );

};

export default Overview;