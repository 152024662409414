import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';

// Redux 

// Components 

import Box from '@mui/material/Box';

import PatientInfo from './PatientInfo';
import ContactInfo from './ContactInfo';
import EmergencyContact from './EmergencyContact';
import InsuranceDetails from './InsuranceDetails';
import ExtraInformation from './ExtraInformation';

import ConfirmationTitle from './items/ConfirmationTitle';

const Confirmation: React.FC = () => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors); 

    const dispatch = useAppDispatch(); 

    // -- 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '100%',
        }}>

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                color: colors?.confirmPatientInfoText,
                fontSize: 15,
                marginBottom: 3,
            }}>{t('confirmPatientInfo')}</Box>

            <ConfirmationTitle title={'patientInformation'} />

            <PatientInfo /> 

            <ConfirmationTitle title={'contactInfo'} />

            <ContactInfo /> 

            <ConfirmationTitle title={'emergencyContact'} />

            <EmergencyContact />

            <ConfirmationTitle title={'insuranceDetails'} />

            <InsuranceDetails />

            <ConfirmationTitle title={'extraInformation'} />

            <ExtraInformation /> 

        </Box>
    );
};

export default Confirmation;