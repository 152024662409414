import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { colorToComponents, degrees, PDFDocument, rgb, StandardFonts, setFontAndSize, TextAlignment, NonFullScreenPageMode  } from 'pdf-lib';

// Components 

import Box from '@mui/material/Box';
import PatientsList from './PatientsList';
import AutoCompleteInfo from './AutoCompleteInfo';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { setFilteredPatientsList, setPatientSearchQuery } from '../../../../../../redux/features/template/template';

const Header: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedPatient = useAppSelector(state => state.template.selectedPatient); 
    const patientsList = useAppSelector(state => state.template.patientsList); 
    const filteredPatientsList = useAppSelector(state => state.template.filteredPatientsList); 
    const patientSearchQuery = useAppSelector(state => state.template.filteredPatientsList); 

    const [search, setSearch] = useState(''); 

    // -- 

    useEffect(() => { 

        dispatch(setPatientSearchQuery(search)); 

        if (search) { 

            const filteredList = patientsList?.filter((patient) => patient.fullName.includes(search)); 

            dispatch(setFilteredPatientsList(filteredList)); 

        };

    },[search]); 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: 'auto',
      }}>

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '95%',
            borderRadius: 3,
            marginTop: 1,  
            paddingTop: 1, 
            paddingBottom: 1, 
            marginBottom: 1, 
            height: 40, 
            fontSize: 12, 
            color: 'black',
            backgroundColor: 'rgba(45, 215, 247, 0.5)',
        }}>{t('selectPatientInfo')}</Box>

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            width: '95%',
            borderRadius: 3,
            paddingTop: 1, 
            paddingBottom: 1, 
            marginBottom: 1, 
            height: 40, 
            backgroundColor: colors?.selectedFormRow,
        }}>
            <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: colors?.text }}/>
            <TextField
                id="standard-search"
                placeholder={t('findPatient') || 'Find a patient'}
                type="search"
                variant="standard"
                value={search}
                InputProps={{
                    style: {fontSize: 12, color: colors?.text },
                    disableUnderline: true, 
                }}
                onChange={(e) => setSearch(e.target.value)}
                sx={{
                    marginLeft: 2,
                    fontSize: 12,
                    width: '80%',
                    borderBottomColor: colors?.templatePatientsListSearchBackground
                }}
            />
        </Box>


      </Box>

  );

};

export default Header;