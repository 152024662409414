import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../redux/hooks';

// Routes


// Redux 


// Components 

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import getRandomColor from '../../../../../../../../../util/style/getRandomColor';


const Avatar: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const filters = useAppSelector(state => state.searchPatient.filters); 
    const patientsList = useAppSelector(state => state.searchPatient.patientsList); 
    const patientFound = useAppSelector(state => state.searchPatient.patientFound); 

    const [avatarName, setAvatarName] = useState(''); 


    // -- 

    useEffect(() => { 

        if (patientFound) { 

            const firstNameLetter = Array.from(patientFound.firstName)[0];
            const lastNameLetter = Array.from(patientFound.lastName)[0];

            setAvatarName(`${firstNameLetter}${lastNameLetter}`); 

        }; 

    },[patientFound]); 

    // -- 

    return (
            
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'space-evenly', 
            width: '25%', 
            height: '100%', 
            borderRadius: 5, 
            fontSize: 12,
        }}>

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly', 
                width: 100, 
                height: 100, 
                borderRadius: '50%', 
                backgroundColor: colors?.patientFoundAvatar,
                fontSize: 20,
            }}>{avatarName}</Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    fontSize: 20, 
                }}
            >
                <Box sx={{ marginRight: 1 }}>{patientFound?.firstName}</Box>
                <Box>{patientFound?.lastName}</Box>

            </Box>

        </Box>

    );

};

export default Avatar;