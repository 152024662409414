import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select, FormControl } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import { US } from 'country-flag-icons/react/3x2';
import { FR } from 'country-flag-icons/react/3x2'

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChange = (newLanguage: string) => {

    i18n.changeLanguage(newLanguage);

    setIsOpenMenu(false);

  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);  

  const open = Boolean(anchorEl);

  const handleClick = (event) => {

    setAnchorEl(event.currentTarget);

    setIsOpenMenu(!isOpenMenu);

  };

  const handleClose = () => {
    setAnchorEl(null);
  }; 

  return (

    <div>
      <Box
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{ 
          display: 'flex', 
          flexDirection: 'row', 
          alignItems: 'center', 
          height: '100%', 
          cursor: 'pointer', 
          marginLeft: 1, 
          marginRight: 1,
          '&:hover': {
            color: '#05E5D3',
          },
        }}
        onClick={handleClick}
      >
        { (i18n?.language === 'en' || i18n?.language === 'en-US') && 
          <Box style={{ height: 15}}>English</Box>}
        { i18n?.language === 'fr' && <Box style={{ height: 15}}>Français</Box>}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={isOpenMenu}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        { i18n?.language !== 'fr' && 
        <MenuItem onClick={() => handleChange('fr')}>
          <Box style={{ height: 15 }}>Français</Box>
        </MenuItem>}

        { (i18n?.language !== 'en' &&  i18n?.language !== 'en-US') &&
          <MenuItem onClick={() => handleChange('en')}>
          <Box style={{ height: 15}}>English</Box>
        </MenuItem>}
      </Menu>
    </div>
  );
};

export default LanguageSelector;
