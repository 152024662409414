import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '../themeContext';

// Components 

import { Box } from '@mui/material';
import Container from '../components/hackerLogin/Container';
import Notification from '../components/general/Notification';

const HackerLogin: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isLightMode, toggleTheme } = useTheme();

  // -- 

  useEffect(() => { 

    if (isLightMode) {

      toggleTheme(); 

    }; 

  },[isLightMode]); 

  // -- 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'space-evenly', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: 'white'
      }}
    >
      <Container /> 

      <Notification /> 

    </Box>
  );
};

export default HackerLogin;