import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Redux 

import { useTranslation } from 'react-i18next';
import { setDeletedAccountId, setIsRefreshDoctorsList, setSelectedDoctor } from '../../../redux/features/hacker/users/doctorsAccess';
import { setNotificationMessage } from '../../../redux/features/general/notification';

// Routes

import { deactivateDoctor, reactivateDoctor, requestDoctorDeletion, confirmDoctorDeletion } from '../../../routes/hacker/users';

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NotesIcon from '@mui/icons-material/Notes';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import { setOpenPopup } from '../../../redux/features/general/popup';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  positionX: number,
  positionY: number,
};


const Dropdown: React.FC<Props> = ({positionX, positionY }) => {

    const { t } = useTranslation(); 

    const dispatch = useAppDispatch(); 

    const selectedDoctor = useAppSelector((state) => state.doctorsAccess.selectedDoctor); 

    const [isLoading, setIsLoading] = useState(false); 

    // -- 

    const handleDoctorDeactivation = async () => { 

        setIsLoading(true); 

        const res = await deactivateDoctor(selectedDoctor._id); 

        setIsLoading(false); 

        if (res?.data.status === 200) { 

            dispatch(setIsRefreshDoctorsList(true)); 
            dispatch(setSelectedDoctor(null));
            dispatch(setNotificationMessage('Doctor account now inactive.')); 

        }; 

    }; 

    // -- 

    const handleDoctorReactivation = async () => { 

        setIsLoading(true); 

        const res = await reactivateDoctor(selectedDoctor._id); 

        setIsLoading(false); 

        if (res?.data.status === 200) { 

            dispatch(setIsRefreshDoctorsList(true)); 
            dispatch(setSelectedDoctor(null));
            dispatch(setNotificationMessage('Doctor account successfully reactivated.')); 

        }; 

    }; 

    // -- 

    const handleDeleteDoctor = async () => { 
        
        setIsLoading(true); 

        const res = await requestDoctorDeletion(selectedDoctor._id); 

        setIsLoading(false); 

        if (res?.data.status === 200) { 

            dispatch(setDeletedAccountId(res.data.deletedAccountId)); 
            dispatch(setOpenPopup()); 
            dispatch(setNotificationMessage('An authentication code has been sent.')); 

        }; 

    };   
    
    // -- 
    
    return (

    <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: 200,
            left: positionX - 170, 
            top: positionY, 
            position: 'absolute',
            backgroundColor: 'rgb(82,84,89)',
            borderRadius: 4,
            cursor: 'pointer',
            zIndex: 5, 
        }}
    >
            { selectedDoctor?.accountStatus === 'active' && 
            <Box
                    onClick={handleDoctorDeactivation}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 50,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.7)'}
                    }}
                >

                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                    >
                        { isLoading ? <CircularProgress /> : <RemoveCircleOutlineOutlinedIcon />}
                        <Box sx={{ marginLeft: 3 }}>Make Inactive</Box>
                </Box>

            </Box>}

            { ((selectedDoctor?.accountStatus === 'inactive') || (selectedDoctor?.accountStatus === 'blocked') || (selectedDoctor?.accountStatus === 'deleted')) && 
            <Box
                    onClick={handleDoctorReactivation}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 50,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.7)'}
                    }}
                >

                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                    >
                        { isLoading ? <CircularProgress /> : <SwitchAccessShortcutAddIcon />} 
                        <Box sx={{ marginLeft: 3 }}>Reactivate</Box>
                </Box>

            </Box>}


            {(selectedDoctor?.accountStatus !== 'deleted') &&
                
                <Box
                    onClick={handleDeleteDoctor}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 50,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: 'rgba(77, 75, 89, 0.7)'}
                    }}
                >

                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                    >
                        { isLoading ? <CircularProgress /> : <DeleteOutlineIcon />} 
                        <Box sx={{ marginLeft: 3 }}>Delete doctor</Box>
                </Box>

            </Box>}

        </Box>

    ); 

};

export default Dropdown;













