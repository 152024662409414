import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 
import { StringMap } from 'i18next';

// ---------------
 
interface ThemeState {
    colors: any, 
    type: string, 
}

// ---------------

const initialState: ThemeState = {
  colors: null, 
  type: '', 
};

export const themeSlice = createSlice({

  name: 'theme',

  initialState,

  reducers: {

    setColors: (state, action: PayloadAction<any>) => {
      
        state.colors = action.payload; 
        state.type = action.payload.theme; 
    },
    resetTheme: (state) => { 
        
      state.colors = ''; 
      state.type = ''; 
    } 
  },

}); 

// ---------------

export const { 
    setColors, 
    resetTheme 
} = themeSlice.actions;

export default themeSlice.reducer;