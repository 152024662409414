
// -- Gets the appropriate background color for the hacker's 
// support tickets list. 

const getStatusColor = (status: any) => {

    if ((status === 'active') || (status === 'accepted')) { 

        return 'rgba(73, 176, 55, 0.5)'; 

    } else if ((status === 'archived') || (status === 'inactive') || (status === 'pending')) { 

        return 'rgba(150, 75, 0, 0.5)'; 
        
    } else if (status === 'deleted') { 

        return 'rgb(135, 0, 0)'; 
        
    } else { 

        return 'transparent'; 
    };

};

export default getStatusColor; 