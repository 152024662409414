import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Components 

import Box from '@mui/material/Box';

const FileViewer: React.FC = () => {

    const blobFileUrl = useAppSelector(state => state.fileViewer.blobFileUrl); 
    const fileExtension = useAppSelector(state => state.fileViewer.fileExtension); 
    const fileName = useAppSelector(state => state.fileViewer.fileName); 

    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    return (

        <Box
            sx={{
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: '65%',
                height: '95%', 
            }}>

                { ((fileExtension === 'pdf') || (fileExtension !== 'docx')) && 
                    <iframe
                        ref={iframeRef}
                        src={blobFileUrl}
                        style={{ 
                            width: '100%', 
                            height: '100%',
                        }}
                        title="PDF Viewer"
                /> }
                        
                { fileExtension === 'docx' && 

                    <div
                    ref={containerRef}
                    style={{ 
                        width: '50vw', 
                        height: '80vh',
                        position: 'absolute', 
                    }}
                />}
        
        </Box>



    );

};

export default FileViewer;