import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setOpenPopup } from '../../../../../../../redux/features/general/popup';

// Components 

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

const CategoryTitle: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const section = useAppSelector((state) => state.createPatient.section); 

    // -- 

    const handleOpenPopup = () => { 

        dispatch(setOpenPopup()); 
    }; 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between', 
            marginTop: '1vh',
            fontWeight: 'bold',
            marginBottom: 5,
            width: '100%',
            height: 'width',
            color: colors?.text, 
        }}>
            <Box>{t(section)}</Box>
            <CancelOutlinedIcon 
                    onClick={() => handleOpenPopup()}
                    sx={{ 
                        fontSize: 30, 
                        color: colors?.cancelButtonText, 
                        '&:hover': 
                        { 
                            color: colors?.cancelButtonColor,
                        },
                        cursor: 'pointer' 
                    }} />
        </Box>
  );
};

export default CategoryTitle;