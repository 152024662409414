import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';

// Routes 

import { getSupportTickets } from '../../../routes/hacker/users';
import { getTicketDetails } from '../../../routes/hacker/users';

// Redux 

import { setSupportTicket } from '../../../redux/features/support/supportTicket';
import { setOpenPopup } from '../../../redux/features/general/popup';
import { setAuthorPersonalInfo } from '../../../redux/features/support/supportTicket';
import { setDashboardSection } from '../../../redux/features/general/navigation';
import { setIsRefreshSupportTicketsList, setSupportTickets, setInitialRendering } from '../../../redux/features/hacker/users/supportTicketsAccess';

// Components 

import SupportTicketHeader from './SupportTicketHeader';
import SupportTicketRow from './SupportTicketRow';
import Popup from '../../general/Popup';
import TicketDetails from './TicketDetails';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LinearProgress from '@mui/material/LinearProgress';

const SupportTicketsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const isPopupOpened = useAppSelector((state) => state.popup.isPopupOpened); 
    const supportTickets = useAppSelector((state) => state.supportTicketsAccess.supportTickets); 
    const initialRendering = useAppSelector((state) => state.supportTicketsAccess.initialRendering); 
    const isRefreshSupportTicketsList = useAppSelector((state) => state.supportTicketsAccess.isRefreshSupportTicketsList); 

    // -- 

    const getSupportTicketsList = async () => { 

      const res = await getSupportTickets() as any; 

      if (res?.data.status === 200) { 

        dispatch(setSupportTickets(res.data.supportTickets)); 

      };

    }; 

    // -- 

    const selectSupportTicket = (supportTicket: any) => { 

      dispatch(setSupportTicket(supportTicket)); 

      dispatch(setOpenPopup()); 

      getAdditionalInfo(supportTicket.author); 

    }; 

    // --

    const getAdditionalInfo = async (author: string) => { 

      const content = await getTicketDetails(author); 

      dispatch(setAuthorPersonalInfo(content)); 

  }; 

    // -- 

    const changeSection = () => { 

      dispatch(setDashboardSection('createSupportTicket')); 

    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

          getSupportTicketsList(); 

          dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // --

    useEffect(() => { 

      if (isRefreshSupportTicketsList) { 

        getSupportTicketsList(); 

        dispatch(setIsRefreshSupportTicketsList(false)); 

      }; 

    },[isRefreshSupportTicketsList]); 
    
    // -- 

    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '95vw',
              backgroundColor: '#383A40',
            }}>

            <SupportTicketHeader />

            { supportTickets?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2.5vh',
                height: '82.5' }}>
        
                <List dense>
        
                {supportTickets?.map((item, index) => (
        
                    <ListItem onClick={() => selectSupportTicket(item)} button key={index}>

                        <SupportTicketRow
                            author={item.author} 
                            ticketId={item._id}
                            status={item.status} 
                            category={item.category}
                            description={item.description}
                            createdAt={moment.utc(item.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {supportTickets?.length === 0 &&         
          
              <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly', 
                  height: '100%',
                  width: '80%', 
              }}>

                  <Box
                      sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}>

                      <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                      <Box>Retrieving Support Tickets...</Box>

                  </Box>

              </Box>}

          <AddCircleIcon 
            onClick={changeSection}
            sx={{ 
              width: 70,
              height: 70, 
              color: 'rgb(45, 207, 196)', 
              position: 'absolute', 
              bottom: 30, 
              right: 30, 
              cursor: 'pointer',
              '&:hover' : { color: 'rgb(65, 227, 216)'}
            }}
          />

            </Box>

            { isPopupOpened && 
              <Popup>
                <TicketDetails />
              </Popup>}

        </Box>
    
      );

};

export default SupportTicketsList;