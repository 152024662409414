import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NotesIcon from '@mui/icons-material/Notes';

type Props = {
  type: string,
  author?: any,
};


const BoxRow: React.FC<Props> = ({type, author}) => {


    const [description, setDescription] = useState(''); 

    // To define the type 

    useEffect(() => { 

        if (type === 'author') { 

            setDescription(author); 

        } else if (type === 'review') { 

            setDescription('Requested Review'); 

        } else if (type === 'revoked') { 

            setDescription('Revoked Document'); 

        } else if (type === 'notes') { 

            setDescription('Commented'); 
        }; 

    },[type, author]);

  return (

    <div style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      marginTop: 4,
      marginBottom: 5,
      fontSize: 12,
    }}>

        { type === 'author' && <PersonIcon sx={{ color: 'lightblue', fontSize: 15, marginRight: 1 }} />}
        { type === 'review' && <RateReviewIcon sx={{ color: 'purple', fontSize: 15, marginRight: 1 }} />}
        { type === 'revoked' && <RemoveCircleOutlineIcon sx={{ color: 'red', fontSize: 15, marginRight: 1 }} />}
        { type === 'notes' &&  <NotesIcon sx={{ color: 'lightblue', fontSize: 15, marginRight: 1 }} />}

        <div style={{ color: '#C2C5C8', fontSize: 12, textAlign: 'left' }}>{description}</div>

    </div>

  );

};

export default BoxRow;