import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface ScanTemplateState {
    section: string, 
    images: any[], 
    resetInputValue: boolean, 
  };
  
  // ---------------
  
  const initialState: ScanTemplateState = {
    section: 'options', 
    images: [], 
    resetInputValue: false, 
  };
  
  // ---------------
  
  export const scanTemplateSlice = createSlice({
  
    name: 'scanTemplate',
  
    initialState,
  
    reducers: {
      
      setScanTemplateSection: (state, action: PayloadAction<any>) => {
  
        state.section = action.payload; 
  
      },
      setImage: (state, action: PayloadAction<any>) => {
  
        state.images.push(action.payload); 
  
      },
      setResetInputValue: (state, action: PayloadAction<boolean>) => {
  
        state.resetInputValue = action.payload; 
  
      },
      resetScanTemplates: (state) => { 
          
          state.section = 'options'; 
          state.images = []; 
  
      } 
    },
  
  }); 
  
  // ---------------
  
  export const { 
      setScanTemplateSection,
      setImage, 
      setResetInputValue, 
      resetScanTemplates
   } = scanTemplateSlice.actions;
  
  export default scanTemplateSlice.reducer;