import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

import { setRefreshFileExplorer, selectFile, selectFolder, setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { resetEditFile, setEditedFileTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFile';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';

// Routes

import { updateFile, updateFolder } from '../../../../../routes/doctor/files';

// Components 

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';
import Dropdown from '../actions/dropdowns/Dropdown';
import TextField from '@mui/material/TextField';
import { resetEditFolder, setEditedFolderTitle } from '../../../../../redux/features/doctor/Dashboard/documents/editFolder';
import { getDoctorName } from '../../../../../routes/doctor/patients';

type Props = {
    index: any, 
    _id?: string,
    title: string,
    path: string, 
    createdAt?: string,
    updatedAt?: string,
    type?: string,
    searchQuery?: boolean, 
};


const FolderDataRow: React.FC<Props> = ({ index, _id, title, path, createdAt, updatedAt, type, searchQuery }) => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedFile = useAppSelector((state) => state.fileExplorer.selectedFile); 
    const selectedFolder = useAppSelector((state) => state.fileExplorer.selectedFolder); 
    const lastInternalFileIndex = useAppSelector((state) => state.fileExplorer.lastInternalFileIndex); 
    const folderData = useAppSelector((state) => state.fileExplorer.folderData); 

    const mode = useAppSelector((state) => state.fileExplorer.mode); 
    const isDropdownOpened = useAppSelector((state) => state.fileExplorer.isDropdownOpened); 

    const editedFileTitle = useAppSelector((state) => state.editFile.title); 
    const editedFile = useAppSelector((state) => state.editFile.editedFile); 

    const [formattedTitle, setFormattedTitle] = useState(''); 
    const [formattedType, setFormattedType] = useState(''); 

    const editedFolderTitle = useAppSelector((state) => state.editFolder.editedFolderTitle); 
    const editedFolder = useAppSelector((state) => state.editFolder.editedFolder); 

    const [titleInput, setTitleInput] = useState(''); 
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');  

    const [editedTitle, setEditedTitle] = useState('');

    // -- 

    const handleFileUpdate = async () => { 

        if (((mode === 'editFile') && (editedFileTitle == selectedFile?.title)) || ((mode === 'editFolder') && (editedFolderTitle == selectedFolder?.title))) { 

            dispatch(selectFile(null)); 
            dispatch(selectFolder(null)); 
            dispatch(resetEditFolder()); 
            dispatch(resetEditFile()); 

        } else { 

            let res = {} as any; 

            if (mode === 'editFile') { 

                res = await updateFile(selectedFile._id, editedFileTitle) as any; 

            } else if (mode === 'editFolder') { 

                res = await updateFolder(selectedFolder._id, editedFolderTitle) as any; 

            }; 

            if (res.data.status === 200) { 

                dispatch(setNotificationMessage((mode === 'editFile') ? t('fileUpdatedOK') : t('folderUpdatedOK'))); 

                dispatch(setRefreshFileExplorer(true)); 
                dispatch(selectFile(null)); 
                dispatch(selectFolder(null)); 
                dispatch(resetEditFolder()); 
                dispatch(resetEditFile()); 

            } else if (res.data.status === 401) { 

                if (res.data.error === 'existing_file') { 

                    setError(true); 
                    setErrorMessage(String(t('existingTitle'))); 

                } else if (res.data.error === 'invalid_title') { 

                    setError(true); 
                    setErrorMessage(String(t('invalidTitle'))); 

                };

            }; 

        }; 

    }; 

    // --

    const handleInput = (key: string) => { 

        if (key == 'Enter') { 

            handleFileUpdate(); 

        } else if (key === 'Escape') { 

            dispatch(resetEditFile());
            dispatch(resetEditFolder());
            dispatch(setMode(''));

        };

    };

    // -- 

    const handleGetDoctorName = async () => { 

        const res = await getDoctorName(title) as any; 
        
        if (res.data.status === 200) { 

            setFormattedTitle(res.data.fullName);

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (mode === 'editFile') { 

            setTitleInput(editedFileTitle);

        } else if (mode === 'editFolder') { 

            setTitleInput(editedFolderTitle);

        }; 

    },[mode]); 

    // -- 

    useEffect(() => { 

        if (mode === 'editFile') { 

            dispatch(setEditedFileTitle(titleInput)); 

        } else if (mode === 'editFolder') { 

            dispatch(setEditedFolderTitle(titleInput)); 

        }; 

    },[titleInput]); 

    // --

    useEffect(() => { 

        if (title && type) { 

            if (type === 'rootSharedDoctorFolder') { 

                handleGetDoctorName(); 

            } else { 

                setFormattedTitle(title); 

            }; 

        }; 

    },[title]); 

    // -- 

    useEffect(() => {
        
        if (type) { 

            type === 'file' && setFormattedType('File'); 
            type === 'folder' && setFormattedType('Folder'); 
            type === 'patient' && setFormattedType('Patient'); 
            type === 'sharedFolder' && setFormattedType('External');
            type === 'sharedFile' && setFormattedType('Shared File'); 
            type === 'rootSharedDoctorFolder' && setFormattedType('Folder'); 
            type === 'rootSharedFolder' && setFormattedType('Folder'); 

        };

    },[type]); 

    // -- 

    // -- 

    useEffect(() => { 

        if (mode === 'editFile') { 

            setEditedTitle(editedFileTitle); 

        } else if (mode === 'editFolder') { 

            setEditedTitle(editedFolderTitle); 

        }; 

    },[editedFileTitle, editedFolderTitle]); 

    return (

        <>

            <Box 
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                fontSize: 12, 
                height: 50,
                cursor: 'pointer',
                backgroundColor: ((selectedFile?._id === _id) || (selectedFolder?._id === _id)) ? colors?.hiddenRow : 'transparent',
                '&:hover': { backgroundColor: colors?.sectionHover }
            }}>
        
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    minWidth: '10%', 
                    marginLeft: '2%',  
                }}>

                    { (type === 'file' && !searchQuery) && <InsertDriveFileIcon sx={{ color: colors?.file, fontSize: 20, marginLeft: 1 }} />}
                    { (type === 'folder' && !searchQuery) && <FolderIcon sx={{ color: colors?.folder, fontSize: 20, marginLeft: 1 }} />}
                    { (type === 'rootSharedFolder' && !searchQuery) && <FolderIcon sx={{ color: colors?.sharedFolder, fontSize: 20, marginLeft: 1 }} />}
                    { (type === 'sharedFolder' && !searchQuery) && <FolderIcon sx={{ color: colors?.sharedFolder, fontSize: 20, marginLeft: 1 }} />}
                    { (type === 'sharedFile' && !searchQuery) && <InsertDriveFileIcon sx={{ color: colors?.sharedFolder, fontSize: 20, marginLeft: 1 }} />}                    
                    { (type === 'rootSharedDoctorFolder' && !searchQuery) && <FolderIcon sx={{ color: colors?.sharedFolder, fontSize: 20, marginLeft: 1 }} />}

                    { (type === 'file' && searchQuery) && <InsertDriveFileIcon sx={{ color: colors?.searchQueryFile, fontSize: 20, marginLeft: 1 }} />}
                    { (type === 'folder' && searchQuery) && <FolderIcon sx={{ color: colors?.searchQueryFolder, fontSize: 20, marginLeft: 1 }} />}
                    
                </div>


                { (((mode === 'editFile') && selectedFile?._id === _id) || ((mode === 'editFolder') && selectedFolder?._id === _id)) ? 
                
                <TextField 
                    sx={{ 
                        width: '30%',
                        color: colors?.text, 
                        textAlign: 'left',
                    }} 
                    onChange={(e) => setTitleInput(e.target.value)}
                    id="standard-basic" 
                    value={titleInput}
                    variant="standard" 
                    autoFocus={true}
                    error={error}
                    helperText={errorMessage}
                    onKeyDown={(e) => handleInput(e.key)}
                />: 
                <div style={{ 
                    width: '30%',
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap'
                }}>{formattedTitle}</div>}

                <div style={{ 
                    width: '30%', 
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden',
                    marginLeft: '1%' 
                }}>{formattedType}</div>

                <div style={{ 
                    width: '15%', 
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden', 
                }}>{moment.utc(createdAt).format('L')}</div>

                <div style={{ 
                    width: '15%', 
                    color: colors?.text, 
                    textAlign: 'left',
                    overflow: 'hidden', 
                }}>{moment.utc(updatedAt).format('L')}</div>
    
            </Box>

            { ((lastInternalFileIndex === index) && ((folderData?.length - 1) > lastInternalFileIndex)) && 
                <Box sx={{ 
                    width: '100%', 
                    height: '1px', 
                    borderStyle: 'solid',
                    borderWidth: 1, 
                    borderColor: colors?.folderDataRowBorderColor, 
                    position: 'absolute', 
                    bottom: 0,
                }}></Box>}


            { (isDropdownOpened && ((selectedFile?._id === _id) || (selectedFolder?._id === _id))) && <Dropdown /> }

            {(((mode === 'moveFile') || (mode === 'moveFolder')) && (type !== 'folder')) && 
                <Box 
                    sx={{ 
                        width: '100%', 
                        height: '100%', 
                        backgroundColor: colors?.folderDataRowBorderColor, 
                        position: 'absolute', 
                        bottom: 0,
                    }}
                >
                </Box>}

        </>

    );

};

export default FolderDataRow;