import React, { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import Logo from '../../general/navBar/Logo';
import CameraAltRoundedIcon from '@mui/icons-material/CameraAltRounded';
import CropOriginalOutlinedIcon from '@mui/icons-material/CropOriginalOutlined';
import { setImage, setResetInputValue, setScanTemplateSection } from '../../../redux/features/doctor/Dashboard/templates/scanTemplate';
import Button from '@mui/material/Button';


const Options: React.FC = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const colors = useAppSelector(state => state.theme.colors);

  const fileInput = document.getElementById("imagesInput") as any; 

  const resetInputValue = useAppSelector(state => state.scanTemplate.resetInputValue);

  // -- 

  const handleCamera = () => { 

    dispatch(setScanTemplateSection('scan')); 

  }; 

  // -- 

  useEffect(() => { 

    if (resetInputValue) { 

        // fileInput.value = null; 

        dispatch(setResetInputValue(false)); 
    }; 

  },[resetInputValue]); 

  // -- 

  const handlePictureSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileReaders: Promise<string>[] = [];
      Array.from(files).forEach(file => {
        const reader = new FileReader();
        const filePromise = new Promise<string>((resolve, reject) => {
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = reject;
        });
        reader.readAsDataURL(file);
        fileReaders.push(filePromise);
      });

      Promise.all(fileReaders).then(images => {
        
        images.forEach((image) => { 

            dispatch(setImage(image)); 

        }); 

      });

      dispatch(setScanTemplateSection('upload')); 
    }
  };

  // -- 

  return (

    <Box 
        sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw',
        right: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        backgroundColor: colors?.container,
        color: colors?.highlightedText }}>

        <Box
            sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            marginTop: 6,
        }}>

            <Logo /> 

            <Box 
                sx={{ 
                    color: colors?.text, 
                    fontSize: 12,
                    marginTop: 2, 
                }}>{t('secureDocUpload')}</Box>
        
        </Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                marginTop: 6,
                width: '100%', 
                height: '40%'
            }}>

        <Box
            sx={{
                width: 'auto',
                height: '100%', 
                marginTop: '5%',
            }}
            component="img"
            alt="Solutions Medca Inc."
            src={require('../../../assets/logos/empty_list.png')}
            />
        </Box>

        <Box
            sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center', 
            marginTop: 6,
            width: '100%', 
            height: '30%',
        }}>
            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    width: '100%', 
                    color: colors?.text, 
                }}
            >
                {t('selectOptionToBegin')}
            </Box>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row', 
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    marginTop: 3,
                    width: '100%', 
                    height: '90%', 
                    color: colors?.text,
                }}
            >
                {navigator?.userAgent?.includes('Mobi') &&
                <Box
                    onClick={handleCamera}
                    sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    color: colors?.text, 
                    padding: 2
                }}
                >
                    <CameraAltRoundedIcon sx={{ fontSize: 30, color: colors?.scanTemplateCameraIcon, marginBottom: 1 }} /> 
                    <Box>{t('camera')}</Box>
                </Box>}


                <Box
                    sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    color: colors?.text, 
                    padding: 2
                }}
                >
                    <CropOriginalOutlinedIcon sx={{ fontSize: 30, color: colors?.scanTemplateFromPhotosIcon, marginBottom: 1 }} /> 
                    <Box>{t('fromPhotos')}</Box>
                    <Button
                        component="label"
                        sx={{ 
                            all: 'initial',                    
                            width: 100, 
                            height: 30,
                            position: 'absolute', 
                            cursor: 'pointer',
                            '&:hover': { backgroundColor: 'transparent'},
                            zIndex: 1}}
                        >
                            <input
                                id='imagesInput'
                                type="file"
                                accept="image/*"
                                multiple
                                hidden
                                onChange={handlePictureSelection}
                            />
                    </Button>
                </Box>

            </Box>

        </Box>
        
    </Box>

  );
};

export default Options;