import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setClosePopup } from '../../../redux/features/general/popup';
import { setIsRefreshDoctorsList, setSelectedDoctor } from '../../../redux/features/hacker/users/doctorsAccess';

// Components 

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { confirmDoctorDeletion } from '../../../routes/hacker/users';
import { setNotificationMessage } from '../../../redux/features/general/notification';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const AuthCode: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const deletedAccountId = useAppSelector(state => state.doctorsAccess.deletedAccountId);
    
    const [authCode, setAuthCode] = useState('');
    const [error, setError] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('');  

    // -- 

    const handleDeletionConfirmation = async () => { 

        const res = await confirmDoctorDeletion(deletedAccountId, authCode) as any; 

        if (res.data.status === 200) { 

            dispatch(setNotificationMessage('Account status updated.')); 
            dispatch(setIsRefreshDoctorsList(true)); 
            dispatch(setClosePopup()); 
            dispatch(setSelectedDoctor(null)); 

        } else { 

            dispatch(setNotificationMessage(t('verificationCodeNotFound')));
            setErrorMessage(String(t('verificationCodeNotFound'))); 
            setError(true); 

        }; 


    }; 

    // -- 

    const closePopup = () => { 

        dispatch(setSelectedDoctor(null)); 
        dispatch(setClosePopup()); 

    }; 

    // --

    const handleInput = (key: string) => { 

        if (key === 'Enter') { 

            handleDeletionConfirmation(); 

        }; 
    };

    // -- 

    return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: '#313338',
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>

        <Box>Hacker Account 2FA</Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%'}}>

            <TextField 
                sx={{ width: '60%', marginBottom: '2vh', fontSize: 10, alignSelf: 'center' }} 
                onChange={(e) => setAuthCode(e.target.value)}
                id="standard-basic" 
                label={t('verificationCode')} 
                value={authCode}
                variant="standard" 
                error={error}
                helperText={errorMessage}
                autoFocus
                onKeyDown={(e) => handleInput(e.key)}
            />
 
        <Button 
          sx={{ 
            width: 200,
            backgroundColor: 'rgb(127, 127, 127)',
            borderColor: 'rgb(127, 127, 127)',
            color: 'rgb(177, 177, 177)',
            '&:hover': 
            { 
                backgroundColor: 'rgb(147, 147, 147)',
                color: 'rgb(207, 207, 207)',
                borderColor: 'rgb(147, 147, 147)'
             }
          }}
          onClick={closePopup}
          variant="outlined">{t('back')}</Button>
          </Box>

    </Box>
  );
};

export default AuthCode;