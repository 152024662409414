import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

// Components

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';

const Filters: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const filters = useAppSelector(state => state.searchPatient.filters); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '100%',
            height: 30,  
            marginLeft: 10, 
            marginBottom: 5,
            paddingLeft: '5%',
            fontSize: 15,
            color: colors?.text
        }}>

        {filters?.map((item, index) => (

             <Box 
                key={index}
                sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: 3
            }}>

                { item === 'firstName' && <AccountCircleIcon sx={{ color: colors?.firstName }} />}
                { item === 'lastName' && <AccountBoxIcon sx={{ color: colors?.lastName }} />}
                { item === 'dateOfBirth' && <EventNoteIcon sx={{ color: colors?.dateOfBirth }} />}
                { item === 'healthCardNumber' && <ContactMailIcon sx={{ color: colors?.healthCareNumber }} />}
                { item === 'phone' && <LocalPhoneIcon sx={{ color: colors?.phone}} /> }
                { item === 'email' && <EmailIcon sx={{ color: colors?.email }} />}
                { item === 'address' && <AddHomeIcon sx={{ color: colors?.address }} /> }
                { item === 'patientId' && <AccountBoxIcon sx={{ color: colors?.patientId }} /> }

                <Box sx={{ marginLeft: 1 }}>{t(item)}</Box>
            
            </Box>

        ))}
            
        </Box>

    );

};

export default Filters;