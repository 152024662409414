import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NotesIcon from '@mui/icons-material/Notes';

type Props = {
  firstName: string,
  lastName: string,
  type?: string,
  email: string,
  team?: string,
  createdAt: string, 
};


const UserRow: React.FC<Props> = ({firstName, lastName, type, email, team, createdAt }) => {

  return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: '5vh',
    }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>{firstName} {lastName}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>{type}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{email}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{team}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{createdAt}</div>

    </Box>

  );

};

export default UserRow;