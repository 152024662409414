const publicRoutes = [
    '/', 
    '/login', 
    '/legal', 
    '/hacker/login', 
    /^\/signup\/doctor\/[^/]+$/, 
    /^\/signup\/insurer\/[^/]+$/, 
    /^\/signup\/admin\/[^/]+$/, 
    '/tos', 
    '/privacy',
    '/support',
    '/faq',
    /^\/scan-template\/[^/]+$/, 
    /^\/patient\/access\/request\/[^/]+$/ 
];

export default publicRoutes;