
// -- Gets a random color (not so random for now); 

const getRandomColor = () => {

    const number = Math.floor(Math.random() * 5) + 1; 

    if (number === 1) { 

        return '#1F97A4'

    } else if (number === 2) { 

        return '#7FBA00'; 

    } else if (number === 3) { 

        return '#FFB900'; 

    } else if (number === 4) { 

        return '#c716c7'; 

    } else if (number === 5) { 

        return '#d16c06'; 
    }; 


};

export default getRandomColor; 