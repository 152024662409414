import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Redux 

import { setMode } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';

// Components

import ConfirmButton from './ConfirmButton';
import FileActions from './actions/FileActions';
import FolderActions from './actions/FolderActions';
import SelectedDataInfo from './SelectedDataInfo';
import FileUpload from './FileUpload';
import RelatedPatientOption from './RelatedPatientOption';
import DataDeletion from './DataDeletion';
import MoveFile from './MoveFile';
import MoveFolder from './MoveFolder';


const Footer: React.FC = () => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const selectedFile = useAppSelector(state => state.fileExplorer.selectedFile); 
    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 
    const mode = useAppSelector(state => state.fileExplorer.mode); 

    const file = useAppSelector(state => state.uploadFile.file); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            justifyContent: 'space-evenly',
            width: '100%',
            height: 40, 
            backgroundColor: colors?.container
        }}>

            { (((selectedFile || selectedFolder) && !mode) || (((selectedFile || selectedFolder) && (mode === 'editFile' || mode === 'editFolder')))) && 
            
            <>
                <SelectedDataInfo />

                { selectedFile && <FileActions />} 

                { selectedFolder && <FolderActions />}

                <ConfirmButton />
            
            </>}

            { (mode === 'uploadFile') && <FileUpload />}

            { (mode === 'attachToPatient') && <RelatedPatientOption />}

            { ((mode === 'deleteFile') || (mode === 'deleteFolder')) && <DataDeletion />}

            { (mode === 'moveFile') && <MoveFile />}

            { (mode === 'moveFolder') && <MoveFolder />}
            
        </Box>

    );

};

export default Footer;