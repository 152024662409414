import axios from 'axios';
import Cookies from 'js-cookie';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// --- 

export const getFileNotes = async (fileId: string) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/documents/notes/${fileId}`, getConfig()); 

        if (res.status === 200) { 

            const fileNotes = res.data.fileNotes as Array<object>; 

            return fileNotes; 
              
        };

    } catch (error) {
        console.error('Error getting the documents notes:', error);
    };

};

// -- 

export const createNote = async (note: any) => {

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/documents/notes`, note, getConfig()); 

        return res.status; 

    } catch (error) {
        console.error('Error getting the documents notes:', error);
    };

};


// -- 

export const getFiles = async () => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/documents`, getConfig()); 

        if (res.status === 200) { 

            return res.data.files as Array<any>; 

        }; 

    } catch (error) {
        console.error('Error getting the documents notes:', error);
    };

}; 

// -- 

export const revokeFile = async (fileId: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/documents/revoke/${fileId}`, getConfig()); 

        if (res.status === 200) { 

            return res; 

        }; 

    } catch (error) {
        console.error('Error getting the documents notes:', error);
    };

}; 

// --

export const acceptFile = async (fileId: any) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/admin/dashboard/documents/accept/${fileId}`, getConfig()); 

        if (res.status === 200) { 

            return res; 
              
        }; 
        
    } catch (error) {
        console.error('Error fetching the teams:', error);
    };

};