import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { QRCodeCanvas } from 'qrcode.react';

// Components 

import Template from '../list/templatesList/Template';
import Typography from '@mui/material/Typography';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';

// Utils 


const CodeBox: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);
    const qrValue = useAppSelector(state => state.templates.qrValue); 

    const [isCopied, setIsCopied] = useState(false); 

    // -- 

    const handleCopy = () => { 

        navigator.clipboard.writeText(qrValue);

        setIsCopied(true); 

        dispatch(setNotificationMessage(t('linkCopied')));

    }; 

    // -- 

    return (

        <Box 
            sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly', 
            minWidth: '50%',
            height: '60%', 
            borderRadius: 5,
            overflow: 'hidden',
            backgroundColor: colors?.codeBox,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            flexShrink: 0,
        }}>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-evenly', 
                    width: '100%', 
                    height: '80%', 
                    flexShrink: 0,
                }}
            >
                <QRCodeCanvas style={{ marginLeft: '10%', marginTop: '5%' }} value={qrValue} size={window.innerWidth * 0.15} />

                <Box 
                    sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly', 
                        width: '50%', 
                        marginLeft: '5%',
                        padding: 2,
                        color: colors?.text
                    }}            
                >
                    <Box
                        sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        fontSize: 12,
                        height: '50%'
                    }}                     
                    >
                        <Box sx={{ marginBottom: 1 }}>{t('scanTemplateDir1')}</Box>
                        <Box sx={{ marginBottom: 1 }}>{t('scanTemplateDir2')}</Box>
                        <Box sx={{ marginBottom: 1 }}>{t('scanTemplateDir3')}</Box>
                    </Box>
            </Box>

            </Box>

            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                    height: '20%', 
                }}
            >

                <a 
                    href={qrValue} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    style={{ 
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: '15%',
                        textDecoration: 'none',
                }}
            >
                <Box sx={{ 
                    backgroundColor: colors?.qrValueBackground, 
                    borderRadius: 3, 
                    textAlign: 'center',
                    wordWrap: 'break-word',
                    padding: 1,
                    paddingLeft: 2,
                    paddignRight: 2,
                    borderStyle: 'solid',
                    borderWidth: 1,
                    fontSize: 15,
                    borderColor: colors?.qeValueBorder,
                    color: colors?.highlightedText,
                    cursor: 'pointer',
                }}>{qrValue}</Box>

            </a>

            

            <Box
                sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly', 
                height: '100%',
                marginLeft: 2
            }}>

                { isCopied ? 
                <FileCopyIcon 
                    sx={{ 
                        fontSize: 25,
                        color:colors?.uploadFileIcon, 
                        cursor: 'pointer',
                        '&:hover': { color: colors?.uploadFileIcon } }} 
                />:
                <ContentCopyIcon  
                    onClick={handleCopy} 
                    sx={{ 
                        cursor: 'pointer', 
                        fontSize: 25, 
                        color: colors?.text,
                        '&:hover': { color: colors?.highlightedText }}}>
                </ContentCopyIcon>}

            </Box>

            </Box>
            
        </Box>

    );

};

export default CodeBox;