import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import SearchBar from './SearchBar';
import CreateButton from './CreateButton';

// Utils 


const ListHeader: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    return (

        <Box 
        sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: '10%', 
            marginTop: '1%'
        }}>

            <CreateButton /> 

            <SearchBar /> 
            
        </Box>

    );

};

export default ListHeader;