import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FirstName from '../../../../../components/admin/dashboard/createUser/formItems/FirstName';
import { object } from 'prop-types';

// ---------------

interface InitialState {
  patients: any[];
}

// ---------------

const initialState: InitialState = {
  patients: [],
};

// ---------------

const patientsSlice = createSlice({
  name: 'patientList',
  initialState,
  reducers: {
    setPatientList: (state, action: PayloadAction<any>) => {
      state.patients = action.payload;
    },
    setListbyAscOrder: (state) => {
      state.patients.sort((a,b) => a.lastName.localeCompare(b.lastName));
    },
    setListbyDescOrder: (state) => {
      state.patients.sort((a, b) => b.lastName.localeCompare(a.lastName));
    },
    // Sort by oldest (from the oldest updatedAt to the most recent)
    setListbyOldestOrder: (state) => {
      state.patients.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateA - dateB;
      });
    },

    // Sort by latest (from the most recent updatedAt to the oldest)
    setListbyLatestOrder: (state) => {
      state.patients.sort((a, b) => {
        const dateA = new Date(a.updatedAt).getTime();
        const dateB = new Date(b.updatedAt).getTime();
        return dateB - dateA;
      });
    },
    addPatient(state, action: PayloadAction<any>) {

      if (state.patients) { 

        state.patients.push(action.payload);

      } else { 

        state.patients = [action.payload];

      };

    },
    resetPatientList: (state) => {
      state.patients = [];
    },
  },
});

export const { setPatientList, setListbyAscOrder, setListbyDescOrder, setListbyOldestOrder, setListbyLatestOrder, addPatient, resetPatientList } = patientsSlice.actions;
export default patientsSlice.reducer;
