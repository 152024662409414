import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateFolderState {
    isOpened: boolean, 
    title: string;
    description: string;
    status?: string;
    folder?: string; 
    relatedPatient?: string;
};

// ---------------

const initialState: CreateFolderState = {
    isOpened: false, 
    title: '',
    description: '',
    status: '',
    folder: '', 
    relatedPatient: '',
};

// ---------------


export const CreateFolderSlice = createSlice({

  name: 'createFolder',

  initialState,

  reducers: {

    setIsOpened: (state, action: PayloadAction<boolean>) => {
        state.isOpened = action.payload;
    }, 
    setTitle: (state, action: PayloadAction<any>) => {
        state.title = action.payload;
    },
    setDescription: (state, action: PayloadAction<any>) => {
        state.description = action.payload;
    },
    setStatus: (state, action: PayloadAction<any>) => {
        state.status = action.payload;
    },
    setFolder: (state, action: PayloadAction<any>) => {
        state.folder = action.payload;
    },
    setRelatedPatient: (state, action: PayloadAction<any>) => {
        state.relatedPatient = action.payload;
    },
    resetCreateFolder: (state) => { 

        state.isOpened = false; 
        state.title = ''; 
        state.description =  '';
        state.folder = ''; 
        state.relatedPatient = ''; 

    },

  },

}); 

// ---------------

export const { 
    setIsOpened, 
    setDescription,
    setTitle,
    setFolder,
    setRelatedPatient,
    setStatus,
    resetCreateFolder, 
} = CreateFolderSlice.actions;

export default CreateFolderSlice.reducer;