import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../../redux/hooks';

// Routes 

import { getFileNotes } from './../../../../../../routes/admin/documents';

// Redux 

import { setNote } from './../../../../../../redux/features/admin/dashboard/documents/note';
import { refreshNotes } from './../../../../../../redux/features/admin/dashboard/documents/refreshNotes';
import { setEmptyNotesList } from './../../../../../../redux/features/admin/dashboard/documents/refreshNotes';

// Components 

import BoxRow from './notes/BoxRow';
import NoteViewer from './notes/NoteViewer';

// Icons 

import NotesIcon from '@mui/icons-material/Notes';


const DocumentNotes: React.FC = () => {

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const note = useAppSelector(state => state.adminNote.note); 
  const file = useAppSelector(state => state.adminFile.file); 
  const refreshNotesList = useAppSelector(state => state.adminRefreshNotes.refreshNotes); 
  const emptyNotes = useAppSelector(state => state.adminRefreshNotes.emptyNotes); 

  const [notes, setNotes] = useState(Array<any>); 


    const selectNote = (item: any) => { 

      dispatch(setNote(item)); 

    }; 

    const getNotes = async () => { 

      const notesList = await getFileNotes(file._id) as Array<any>; 
      setNotes(notesList); 

    }; 

    useEffect(() => { 

      if (file) { 

        getNotes(); 

      }; 

    },[file]); 


    useEffect(() => { 

      if (refreshNotesList) { 

        getNotes(); 

        dispatch(refreshNotes(false)); 

      }; 

    },[refreshNotesList]); 

    useEffect(() => { 

      if (emptyNotes) { 

        setNotes([]); 

        dispatch(setEmptyNotesList(false)); 

      }; 

    },[emptyNotes]); 


    return (

      <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          alignItems: 'center',
          width: '20%',
          height: '95%', 
          borderRadius: 5,
          backgroundColor: '#383A40',
        }}>

          {notes?.length >= 1 &&
          
          <Box sx={{ 
            overflow: 'auto',
            minWidth: '95%',
            marginTop: '2vh' }}>
    
            <List dense>
    
              {notes?.map((item, index) => (
    
                <ListItem 
                  onClick={() => selectNote(item)}
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center', 
                  width: '90%',
                  minHeight: '10vh', 
                  borderRadius: 5,
                  marginTop: '2vh',
                  backgroundColor: '#313338',
                }} button key={index}>

                  { item.author && <BoxRow type='author' author={item.author} />}

                  { item?.tags.includes('review') && <BoxRow type='review' />}

                  { item?.tags.includes('revoked') && <BoxRow type='revoked' />}

                  { item?.description && <BoxRow type='notes' />}

                </ListItem>
              ))}
    
            </List>
    
          </Box>}

          {notes?.length === 0 &&         
          
          <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            height: '30%',
            width: '100%',
            fontSize: 15,
            color: '#8C8D91'
          }}>

            <NotesIcon sx={{ fontSize: 50 }} />

            <div>No notes detected.</div>

        </Box>}

          { note && <NoteViewer />} 

        </Box>
    );
};

export default DocumentNotes;