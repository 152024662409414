import React, {useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';
import Button from '@mui/material/Button';

// Routes

import { createTeam } from './../../../../../routes/admin/team';

// Redux 

import { resetCreateTeam } from './../../../../../redux/features/admin/dashboard/team/createTeam';
import { setNotificationMessage } from '../../../../../redux/features/general/notification';


const SubmitButton: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const teamName = useAppSelector(state => state.adminCreateTeam.teamName); 
  const teamMembers = useAppSelector(state => state.adminCreateTeam.teamMembers); 

  const [allowTeamCreation, setAllowTeamCreation] = useState(false); 

  useEffect(() => { 

    if (teamName && (teamMembers.length >= 1)) { 

        setAllowTeamCreation(true); 

    } else { 

        setAllowTeamCreation(false); 

    };

  },[teamName, teamMembers]); 

  // -- 

  const handleCreateTeam = async () => { 

     const res = await createTeam(teamName, teamMembers) as any; 

     if (res.status === 200) { 

      // Redux is reset. 

      dispatch(resetCreateTeam()); 

      dispatch(setNotificationMessage('Team Successfully Created')); 

     } else { 



    }; 

  }; 

  // -- 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '5vh',
        height: '5vh',
        marginBottom: '3vh',
    }}>

        { allowTeamCreation ? 
        <Button onClick={() => handleCreateTeam()} variant="contained">Create Team</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">Create Team</Button>}


    </Box>

  );

};

export default SubmitButton;