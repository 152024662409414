import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';


// Components 

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';

// Redux 

type TitleProps = {
    children: React.ReactNode, 
    title: string, 
    description: string
    copyEnabled?: boolean
};

const SectionRow: React.FC<TitleProps> = ({ children, title, description, copyEnabled }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    const [isCopied, setIsCopied] = useState(false); 

    const handleCopy = () => { 

        navigator.clipboard.writeText(description);

        setIsCopied(true); 

        dispatch(setNotificationMessage('patientFieldCopied'));

    }; 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between', 
            width: '100%',
            minHeight: 40, 
            marginBottom: 1, 
            overflowY: 'hidden',
            color: colors?.text,
            cursor: 'default'
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {children}
                <Box sx={{ marginLeft: 2 }}>{t(title)}</Box>
            </Box>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ marginRight: 1 }}>{description}</Box>
                
                { copyEnabled && 

                    <>
                        { isCopied ? 
                        
                        <FileCopyIcon 
                            sx={{ 
                                color:colors?.uploadFileIcon, 
                                cursor: 'pointer',
                                '&:hover': { color: colors?.uploadFileIcon } }} 
                        />:
                        <ContentCopyIcon  
                            onClick={handleCopy} 
                            sx={{ 
                                cursor: 'pointer', 
                                fontSize: 20, 
                                color: colors?.text,
                                '&:hover': { color: colors?.highlightedText }}}>
                        </ContentCopyIcon>}

                    </>}

            </Box>



        </Box>
  );
};

export default SectionRow;