import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface SearchPatientState {
  filters: any[], 
  patientsList: any[], 
  firstName: string, 
  lastName: string,
  dateOfBirth: string,
  email: string,
  phone: string,
  address: string, 
  patientId: string, 
  healthCardNumber: string,
  patientFound: any, 
  existingPendingAccessRequest: boolean, 
  newlyAttachedPatientId: any, 
  isFiltersDropdownOpened: boolean, 
};

// ---------------

const initialState: SearchPatientState = {
    filters: [], 
    patientsList: [], 
    firstName: '', 
    lastName: '',
    dateOfBirth: '', 
    email: '',
    phone: '', 
    address: '', 
    patientId: '', 
    healthCardNumber: '',
    newlyAttachedPatientId: null, 
    patientFound: null,  
    existingPendingAccessRequest: false, 
    
    isFiltersDropdownOpened: false, 
};

// ---------------

export const searchPatientSlice = createSlice({

  name: 'searchPatient',

  initialState,

  reducers: {

    setFilter: (state, action: PayloadAction<any>) => {

        state.filters.push(action.payload); 
  
    }, 
    setPatientsList: (state, action: PayloadAction<any>) => {

        state.patientsList = action.payload; 
  
    }, 
    setFirstName: (state, action: PayloadAction<string>) => {

      state.firstName = action.payload; 

    }, 
    setLastName: (state, action: PayloadAction<string>) => {

      state.lastName = action.payload; 

    }, 
    setDateOfBirth: (state, action: PayloadAction<string>) => {

      state.dateOfBirth = action.payload; 

    }, 
    setEmail: (state, action: PayloadAction<string>) => {

      state.email = action.payload; 

    }, 
    setPhone: (state, action: PayloadAction<string>) => {

      state.phone = action.payload; 

    }, 
    setAddress: (state, action: PayloadAction<string>) => {

      state.address = action.payload; 

    }, 
    setPatientId: (state, action: PayloadAction<string>) => {

      state.patientId = action.payload; 

    }, 
    setHealthCardNumber: (state, action: PayloadAction<string>) => {

      state.healthCardNumber = action.payload; 

    },
    setPatientFound: (state, action: PayloadAction<any>) => {

      state.patientFound = action.payload; 

    },
    setExistingPendingAccessRequest: (state, action: PayloadAction<boolean>) => {

      state.existingPendingAccessRequest = action.payload; 

    },
    setNewlyAttachedPatientId: (state, action: PayloadAction<any>) => {

      state.newlyAttachedPatientId = action.payload; 

    }, 
    setIsFiltersDropdownOpened: (state, action: PayloadAction<boolean>) => {

      state.isFiltersDropdownOpened = action.payload; 

    },  
    resetSearchPatient: (state) => { 
        
        state.filters = [];
        state.patientsList = []; 
        state.firstName = '';
        state.lastName = ''; 
        state.dateOfBirth = '';
        state.email = ''; 
        state.phone = ''; 
        state.address = ''; 
        state.patientId = ''; 
        state.healthCardNumber = ''; 
        state.patientFound = null; 
        state.existingPendingAccessRequest = false; 
        state.isFiltersDropdownOpened = false; 

    } 
  },

}); 

// ---------------

export const { 
    setFilter, 
    setPatientsList, 
    setFirstName,
    setLastName, 
    setDateOfBirth,
    setEmail,
    setPhone,
    setPatientId,
    setHealthCardNumber,
    setAddress,
    setPatientFound, 
    setExistingPendingAccessRequest, 
    setNewlyAttachedPatientId,
    setIsFiltersDropdownOpened, 
    resetSearchPatient
 } = searchPatientSlice.actions;

export default searchPatientSlice.reducer;