import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Redux 

import { setTemplatesSection } from '../../../../../redux/features/doctor/Dashboard/templates/templates';

// Components 

import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const BackButton: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const colors = useAppSelector(state => state.theme.colors);


    // -- 

    const handleNavigation = () => { 

        dispatch(setTemplatesSection('list'));
    };

    // -- 

    return (

        <Box 
            onClick={handleNavigation} 
            sx={{ 
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: 50,
            height: 50, 
            top: 80, 
            right: 100,
            cursor: 'pointer',
            position: 'absolute', 
            color: colors?.highlightedText
        }}>
            <HighlightOffIcon sx={{ fontSize: 40 }} /> 
        </Box>

    );

};

export default BackButton;