import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

import { setDropdownOpened, setIsRefreshNotes } from '../../../../../../../redux/features/doctor/Dashboard/patients/patientNote';
import { setNotificationMessage } from '../../../../../../../redux/features/general/notification';

// Routes

import { deletePatientNote, editPatientNote, getDoctorName } from '../../../../../../../routes/doctor/patients';

// Components 

import Box from '@mui/material/Box';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TextField from '@mui/material/TextField';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Button from '@mui/material/Button';

interface Props { 
    patientNoteId: string
    doctorId: string, 
    description: string, 
    updatedAt: string,
    createdAt: string,
}; 

const Note: React.FC<Props> = ({ patientNoteId, doctorId, description, updatedAt, createdAt }) => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const dropdownOpened = useAppSelector(state => state.patientNote.dropdownOpened);

    const [isNoteEdited, setIsNoteEdited] = useState(false); 
    const [isEditModeOpened, setIsEditModeOpened] = useState(false); 

    const [doctorName, setDoctorName] = useState(''); 

    const [editedDescription, setEditedDescription] = useState(''); 

    const [positionX, setPositionX] = useState(0); 
    const [positionY, setPositionY] = useState(0); 

    // -- 

    const handleDropdown = (e: any) => { 

        if (dropdownOpened === patientNoteId) { 

            dispatch(setDropdownOpened('')); 

        } else { 

            setPositionX(e.pageX - 410); 
            setPositionY(e.pageY - 60); 

            dispatch(setDropdownOpened(patientNoteId)); 

        }; 

    }; 

    // -- 

    const handleEditMode = async () => { 

        setEditedDescription(description); 
        setIsEditModeOpened(true); 
        
        dispatch(setDropdownOpened('')); 

    }; 

    // --

    const handleDoctorInfo = async () => { 

        const res = await getDoctorName(doctorId) as any;

        if (res?.data.status === 200) { 

            setDoctorName(res.data.fullName); 

        }; 

    }; 

    // -- 

    const handleDeleteNote = async () => { 

        const res = await deletePatientNote(patientNoteId); 

        if (res?.data.status === 200) { 

            dispatch(setDropdownOpened('')); 

            dispatch(setNotificationMessage(t('noteRemoved'))); 
            dispatch(setIsRefreshNotes(true)); 

        }; 

    }; 

    const handleEditPatientNote = async () => { 

        const res = await editPatientNote(patientNoteId, editedDescription); 

        if (res?.data.status === 200) { 

            setIsEditModeOpened(false); 

            dispatch(setNotificationMessage(t('noteEdited'))); 
            dispatch(setIsRefreshNotes(true)); 

        }; 

    }; 

    // -- 

    const handleEnterKey = async (e: any) => { 
    
        if (e.key === 'Enter' && e.ctrlKey) { 

            handleEditPatientNote(); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        handleDoctorInfo(); 

    },[]); 

    // -- 

    useEffect(() => { 

        if (updatedAt && createdAt) { 

            const creationDate = moment(createdAt); 
            const updateDate = moment(updatedAt); 

            const duration = moment.duration(updateDate.diff(creationDate));
            const minutes = duration.asMinutes();

            if (minutes > 10) { 

                setIsNoteEdited(true); 

            }; 

        }; 

    },[updatedAt, createdAt]); 

    // --

    return (

    <Box
        sx={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            padding: 2,
            borderRadius: 5,
            marginBottom: 2, 
            backgroundColor: colors?.optionContainer,   
            fontSize: 15
        }}
    >
        <Box 
            sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between', 
                width: '100%',
                height: 40, 
                marginBottom: 2
            }}
        >
            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    color: colors?.text,
                }}
            >
                <SpeakerNotesIcon sx={{ color: 'rgb(188, 45, 207)' }} /> 
                <Box sx={{ marginLeft: 2 }}>By {doctorName}</Box>

                { isNoteEdited && <Box sx={{ marginLeft: 2 }}>(Edited)</Box>}
            </Box>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >

                <Box sx={{ color: colors?.text, marginRight: 2 }}>{moment.utc(updatedAt).format('LLL')}</Box>

                <MoreVertIcon 
                    onClick={(e) => handleDropdown(e)}
                    sx={{ 
                        width: 25,
                        height: 25, 
                        borderRadius: '50%', 
                        cursor: 'pointer', 
                        fontSize: 15,
                        '&:hover': { color: colors?.highlightedText, backgroundColor: colors?.createNoteHover }
                    }}
                /> 

            </Box>

        </Box>

        { isEditModeOpened ? 
        
            <TextField
                value={editedDescription}
                placeholder={String(t('addANote'))}
                onChange={(e) => setEditedDescription(e.target.value)}
                variant="standard"
                autoFocus
                multiline
                rows={5}
                autoComplete='off'
                InputProps={{
                    style: {fontSize: 15, height: '100%' },
                    disableUnderline: true, 
                }}
                sx={{
                    marginLeft: 2,
                    fontSize: 15,
                    width: '100%',
                }}
                onKeyDown={(e) => handleEnterKey(e)}
            />:
            <Box
                sx={{ 
                    width: '100%',
                    textAlign: 'left',
                    color: colors?.noteDescription,
                    fontSize: 15
                }}
            >{description}</Box> }

        { (isEditModeOpened && (editedDescription !== description)) && 

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%', 
                }}
            >
                <Button 
                    onClick={() => handleEditPatientNote()}
                    sx={{ width: 'auto', paddingLeft: 5, paddingRight: 5, backgroundColor: colors?.createNote }} 
                    variant="contained">{t('save')}
                </Button>
            </Box>}





        { (dropdownOpened === patientNoteId) && 

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center', 
                    width: 200,
                    height: 100,
                    left: positionX, 
                    top: positionY, 
                    position: 'absolute',
                    backgroundColor: colors?.noteDropdown,
                    borderRadius: 4,
                    cursor: 'pointer',
                    zIndex: 5, 
                }}
            >

                <Box
                    onClick={handleEditMode}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 50,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: colors?.noteDropdownHover}
                    }}
                >

                    <Box
                        style={{ 
                            display: 'flex', 
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: 10
                        }}
                    >
                        <DriveFileRenameOutlineIcon /> 
                        <Box sx={{ marginLeft: 3 }}>{t('editNote')}</Box>
                    </Box>

                </Box>


                <Box
                    onClick={handleDeleteNote}
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%', 
                        height: 50,
                        fontSize: 12,  
                        borderRadius: 3,
                        '&:hover': { backgroundColor: colors?.noteDropdownHover}
                    }}
                >

                <Box
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: 10
                    }}
                    >
                        <DeleteOutlineIcon /> 
                        <Box sx={{ marginLeft: 3 }}>{t('removeNote')}</Box>
                    </Box>

                </Box>
                
            </Box>}

    
    </Box>

  );
};

export default Note;