import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../../redux/hooks';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

// Routes


// Redux 


// Components 

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import TollIcon from '@mui/icons-material/Toll';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ShieldMoonIcon from '@mui/icons-material/ShieldMoon';

const PatientInfo: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const patientFound = useAppSelector(state => state.searchPatient.patientFound); 

    // -- 

    return (
            
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            width: '35%', 
            height: '100%', 
            borderRadius: 5, 
            fontSize: 12,
        }}>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    marginTop: 3, 
                }}
            >
                <TollIcon sx={{ color: colors?.patientFoundInfo}}/> 
                <Box sx={{ marginLeft: 2, color: colors?.text }}>{patientFound?._id}</Box>
            </Box>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    marginTop: 3, 
                }}
            >
                <AccountCircleIcon sx={{ color: colors?.patientFoundInfo}}/> 
                <Box sx={{ marginLeft: 2, color: colors?.text }}>On Medca since {moment(patientFound?.createdAt).format('LL')}</Box>
            </Box>

            <Box
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    marginTop: 3, 
                }}
            >
                <ShieldMoonIcon sx={{ color: colors?.patientFoundInfoSecure}}/> 
                <Box sx={{ marginLeft: 2, color: colors?.text }}>2-Step Verification Enabled</Box>
            </Box>

        </Box>

    );

};

export default PatientInfo;