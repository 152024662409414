import React, { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Redux 

// Routes

import { getPatientDirectives } from '../../../../../../../routes/doctor/patients';

// Components 

import Box from '@mui/material/Box';
import Directive from './Directive';
import CreateDirective from './CreateDirective';
import { setIsRefreshDirectives } from '../../../../../../../redux/features/doctor/Dashboard/patients/patientDirective';


const Directives: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const patient = useAppSelector(state => state.patient.patient);
    const isRefreshDirectives = useAppSelector(state => state.patientDirective.isRefreshDirectives); 
    
    const [directives, setDirectives] = useState([]) as any; 

    // -- 

    const handleDirectives = async () => { 

        const res = await getPatientDirectives(patient._id); 

        if (res?.data.status === 200) { 

            setDirectives(res.data.directives); 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (patient) { 

            handleDirectives(); 

        }; 

    },[]); 

    // -- 

    useEffect(() => { 

        if (isRefreshDirectives) { 

            handleDirectives(); 

            dispatch(setIsRefreshDirectives(false)); 

        }; 

    },[isRefreshDirectives]); 

    // -- 

    return (

    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '2rem',
            maxHeight: '80vh',
            overflow: 'auto',
            alignItems: 'center',
            scrollbarWidth: 'none',
        }}
    >
        <CreateDirective /> 

        {directives?.map((item, index) => (

            <Directive doctorId={item.doctorId} description={item.description} updatedAt={item.updatedAt} /> 

        ))}

    </Box>

  );
};

export default Directives;