import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface HackersAccessState {
  hackers: any[],
  selectedHacker: any, 
  isRefreshHackersList: boolean, 
  initialRendering: boolean, 
}

// ---------------

const initialState: HackersAccessState = {
    hackers: [], 
    selectedHacker: null, 
    isRefreshHackersList: false, 
    initialRendering: true, 
};

// ---------------


export const hackersAccessSlice = createSlice({

  name: 'hackersAccess',

  initialState,

  reducers: {

    setHackers: (state, action: PayloadAction<any>) => { 

        state.hackers = action.payload; 

    },
    setSelectedHacker: (state, action: PayloadAction<any>) => { 

      state.selectedHacker = action.payload; 

    },
    setIsRefreshHackersList: (state, action: PayloadAction<boolean>) => { 

      state.isRefreshHackersList = action.payload; 

    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
      state.initialRendering = action.payload; 

    }, 
    resetHackersAccess: (state) => { 
        
      state.hackers = []; 
      state.selectedHacker = null;
      state.isRefreshHackersList = false;
      state.initialRendering = true; 
    } 

  },

}); 

// ---------------

export const { 
  setHackers, 
  setSelectedHacker, 
  setIsRefreshHackersList, 
  setInitialRendering, 
  resetHackersAccess } = hackersAccessSlice.actions;

export default hackersAccessSlice.reducer;