import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface PatientsAccessState {
  patients: any[],
  selectedPatient: any, 
  isRefreshPatientsList: boolean, 
  initialRendering: boolean, 
}

// ---------------

const initialState: PatientsAccessState = {
    patients: [], 
    selectedPatient: null, 
    isRefreshPatientsList: false, 
    initialRendering: true, 
};

// ---------------


export const patientsAccessSlice = createSlice({

  name: 'patientsAccess',

  initialState,

  reducers: {

    setPatients: (state, action: PayloadAction<any>) => { 

        state.patients = action.payload; 

    },
    setSelectedPatient: (state, action: PayloadAction<any>) => { 

      state.selectedPatient = action.payload; 

    },
    setIsRefreshPatientsList: (state, action: PayloadAction<boolean>) => { 

      state.isRefreshPatientsList = action.payload; 

    },
    setInitialRendering: (state, action: PayloadAction<boolean>) => {
  
        state.initialRendering = action.payload; 
  
    }, 
    resetPatientsAccess: (state) => { 
        
      state.patients = []; 
      state.selectedPatient = null;
      state.isRefreshPatientsList = false;
      state.initialRendering = true; 

    } 

  },

}); 

// ---------------

export const { 
  setPatients, 
  setSelectedPatient, 
  setIsRefreshPatientsList, 
  setInitialRendering, 
  resetPatientsAccess } = patientsAccessSlice.actions;

export default patientsAccessSlice.reducer;