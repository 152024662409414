import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../redux/hooks';

// Components 

import SectionHeader from './formItems/SectionHeader';
import TeamName from './formItems/TeamName';
import TeamMembers from './formItems/TeamMembers';
import SubmitButton from './formItems/SubmitButton';

const FormContainer: React.FC = () => {

//   const subSection = useAppSelector(state => state.navigation.subSection);

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '90%',
        width: '95%',
        marginTop: '2vh',
        overflowY: 'scroll',
        scrollbarWidth: 'none'
    }}>

        <SectionHeader title='General' />

        <TeamName />

        <SectionHeader title='Members' />

        <TeamMembers />
        
        <SubmitButton />

    </div>

  );

};

export default FormContainer;