import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Components 

import TextField from '@mui/material/TextField';

interface Props { 
    title?: string
}

const ListHeader: React.FC<Props> = ({title}) => {

  const usersListLength = useAppSelector(state => state.adminUserAccess.usersListLength); 

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '10%',
        width: '95%', 
        marginTop: '1%',
        paddingLeft: '1.5%',
        backgroundColor: '#383A40',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10, 
    }}>

      { usersListLength >= 1 && 

        <>

          <div style={{  width: '20%' }}>User</div>

          <Box sx={{ width: '20%' }}>Type</Box>

          <Box sx={{ width: '20%' }}>Email</Box>

          <Box sx={{ width: '20%' }}>Team</Box>

          <Box sx={{ width: '20%' }}>Active Since</Box>

        </>}


    </Box>

  );

};

export default ListHeader;