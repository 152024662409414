import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateUserState {
  firstName: string,
  lastName: string,
  email: string,
  team: string,
  accountType: string
}

// ---------------

const initialState: CreateUserState = {
    firstName: '', 
    lastName: '',
    email: '',
    team: '', 
    accountType: '', 
};

// ---------------


export const createUserSlice = createSlice({

  name: 'createUser',

  initialState,

  reducers: {

    setFirstName: (state, action: PayloadAction<string>) => {

        state.firstName = action.payload; 
    },
    setLastName: (state, action: PayloadAction<string>) => { 
        
        state.lastName = action.payload;  
    },
    setEmail: (state, action: PayloadAction<string>) => { 
        
        state.email = action.payload; 
    },
    setAssignedTeam: (state, action: PayloadAction<string>) => { 

        state.team = action.payload; 

    },
    setAccountType: (state, action: PayloadAction<string>) => {

        state.accountType = action.payload; 

    }, 
    resetCreateUser: (state) => { 
        
        state.firstName = '';
        state.lastName = '';
        state.email = '';
        state.team = ''; 
        state.accountType = ''; 

    } 

  },

}); 

// ---------------

export const { 
    setFirstName, setLastName, 
    setEmail, setAssignedTeam, 
    resetCreateUser, setAccountType } = createUserSlice.actions;

export default createUserSlice.reducer;