import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import {default as MuiTextField} from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { addTextField, setIsCreatingTextField, setSelectedTextField, setSelectedTextFieldId, setTextFields, updateTextFields } from '../../../../redux/features/template/template';
import { deleteTemplateTextField, updateTemplateTextField } from '../../../../routes/doctor/templates';
import TitleIcon from '@mui/icons-material/Title';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WcIcon from '@mui/icons-material/Wc';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TollIcon from '@mui/icons-material/Toll';
import OptionsBar from './OptionsBar';
import NotesIcon from '@mui/icons-material/Notes';
import formatAutoCompleteData from '../../../../util/dashboard/doctor/template/formatAutoCompleteData';

interface Props { 
    key: number, 
    textFieldId: string, 
    x: number,
    y: number,
    width: number,
    height: number, 
    description?: string,
    attribute?: string,
    style: any, 
    textField: any
}; 

const TextField: React.FC<Props> = ({textFieldId, x, y, width, height, description, attribute, style, textField }) => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const textFieldRef = useRef() as any;

    const colors = useAppSelector(state => state.theme.colors); 
    const selectedTextFieldId = useAppSelector(state => state.template.selectedTextFieldId); 
    const fontSize = useAppSelector(state => state.template.fontSize); 
    const textColor = useAppSelector(state => state.template.textColor); 
    const textAlign = useAppSelector(state => state.template.textAlign) as any; 
    const textDecoration = useAppSelector(state => state.template.textDecoration); 
    const fontWeight = useAppSelector(state => state.template.fontWeight); 
    const fontStyle = useAppSelector(state => state.template.fontStyle); 
    const textFields = useAppSelector(state => state.template.textFields); 
    const templateMode = useAppSelector(state => state.template.templateMode); 

    const [text, setText] = useState(''); 
    const [attributeColor, setAttributeColor] = useState(''); 
    const [attributeBackgroundColor, setAttributeBackgroundColor] = useState('');

    // -- 

    const handleSelection = () => { 

        if (selectedTextFieldId != textFieldId) { 
    
            dispatch(setSelectedTextField(textField)); 
    
        }; 

    }; 

    // - 

    const handleKey = async (e: any) => { 

        if (e.key === 'Backspace' && e.ctrlKey) { 

            if (!description) { 

                const res = await deleteTemplateTextField(textFieldId) as any; 

                if (res?.data.status === 200) { 

                    const updatedTextFieldsList = textFields?.filter((textField) => textField._id != textFieldId) as any; 

                    dispatch(setTextFields(updatedTextFieldsList)); 

                }; 

            }; 


        }  else if (e.key === 'Enter') { 

            dispatch(setSelectedTextField(null)); 

        }; 

    };

    // -- 

    useEffect(() => { 

        if (attribute) { 

            attribute === 'patientId' && setAttributeColor(colors?.patientId); 
            attribute === 'firstName' && setAttributeColor(colors?.firstName);
            attribute === 'lastName' && setAttributeColor(colors?.lastName);
            attribute === 'sex' && setAttributeColor(colors?.gender);
            attribute === 'dateOfBirth' && setAttributeColor(colors?.dateOfBirth);  
            attribute === 'healthCardNumber' && setAttributeColor(colors?.healthCardNumber);
            attribute === 'phone' && setAttributeColor(colors?.phone); 
            attribute === 'address' && setAttributeColor(colors?.address);  
            attribute === 'email' && setAttributeColor(colors?.email);
            attribute === 'emergencyContactName' && setAttributeColor(colors?.emergencyContactName);
            attribute === 'emergencyContactRelation' && setAttributeColor(colors?.emergencyContactRelation);  
            attribute === 'emergencyContactPhone' && setAttributeColor(colors?.emergencyContactPhone);

        } else { 

            setAttributeColor('rgb(150, 150, 150)');

        };

    },[attribute]); 

    // -- 

    useEffect(() => { 

        if (attributeColor) { 

            let newColorString = attributeColor; 

            let rgbString = newColorString.replace('rgb', 'rgba'); 
            let updatedString = rgbString.replace(')', ', 0.4)');

            setAttributeBackgroundColor(updatedString); 

        }; 

    },[attributeColor]); 


    // -- 

    useEffect(() => { 

        if (description) { 
            
            setText(formatAutoCompleteData(attribute, description)); 

        }; 

    },[description]);

    // -- 

    return (

      <Box 
        onClick={handleSelection}
        ref={textFieldRef}
        sx={{ 
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute', 
            left: x,
            top: y, 
            width: width, 
            height: height, 
            borderWidth: (selectedTextFieldId == textFieldId) ? 2 : 0,
            borderRadius: 2, 
            borderStyle: 'solid',
            borderColor: (selectedTextFieldId == textFieldId) ? colors?.templateToolEnabled : 'black',
            cursor: 'default'
        }}
        onKeyDown={(e) => handleKey(e)}
        >

        { ((selectedTextFieldId == textFieldId) || text) && 
        
            <MuiTextField
                id="standard-search"
                variant="standard"
                value={text}
                autoFocus
                autoComplete='off'
                multiline={true}
                sx={{ 
                    marginLeft: 1, 
                    width: width,
                    marginRight: 1, 
                }}
                inputProps={{ 
                    style: { 
                        textAlign: style?.textAlign,
                        textDecorationLine: style?.textDecoration,
                        textDecorationStyle: style?.textDecoration ? 'solid' : undefined, 
                        textDecorationThickness: style?.textDecoration ? 1 : 0,
                        fontSize: style?.fontSize, 
                        color: style?.textColor,
                        fontWeight: style?.fontWeight, 
                        fontStyle: style?.fontStyle, 
                    }
                }}
                InputProps={{
                    disableUnderline: true, 
                }}
                onChange={(e) => setText(e.target.value)}
            />}

        { ((selectedTextFieldId !== textFieldId) && !text && !templateMode) && 
            <Box
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center', 
                    width: '100%', 
                    marginLeft: 1, 
                }}
            >

                { attribute === 'patientId' && <TollIcon sx={{ fontSize: 18, color: attributeColor }}/> }
                { attribute === 'firstName' && <AccountCircleIcon sx={{ fontSize: 18, color: attributeColor }}/> }
                { attribute === 'lastName' && <AccountBoxIcon sx={{ fontSize: 18, color: attributeColor}}/>}
                { attribute === 'sex' && <WcIcon sx={{ fontSize: 18, color: colors?.gender }}/>}
                { attribute === 'dateOfBirth' && <EventNoteIcon sx={{ fontSize: 18, color: attributeColor }}/>}
                { attribute === 'healthCardNumber' && <AccountCircleIcon sx={{ fontSize: 18, color: attributeColor }}/> }
                { attribute === 'phone' && <LocalPhoneIcon sx={{ fontSize: 18, color: attributeColor }}/>}
                { attribute === 'address' && <AddHomeIcon sx={{ fontSize: 18, color: attributeColor }}/>}
                { attribute === 'email' && <EmailIcon sx={{ fontSize: 18, color: attributeColor}}/>}
                { attribute === 'emergencyContactName' && <AccountCircleIcon sx={{ fontSize: 18, color: attributeColor }}/>}
                { attribute === 'emergencyContactRelation' && <AccountBoxIcon sx={{ fontSize: 18, color: attributeColor }}/>}
                { attribute === 'emergencyContactPhone' && <AddHomeIcon sx={{ fontSize: 18, color: attributeColor }}/>}
                { !attribute && <NotesIcon sx={{ fontSize: 18, color: attributeColor }} />}
                
                <Box
                    sx={{ 
                        height: 18,
                        minWidth: '70%', 
                        backgroundColor: attributeBackgroundColor,
                        marginLeft: 1,
                        marginRight: 1, 
                        borderRadius: 1, 
                        flexShrink: 3,
                    }}
                />



            </Box>}

            { (selectedTextFieldId == textFieldId) && <OptionsBar />}

      </Box>

  );

};

export default TextField;