import axios from 'axios';
import Cookies from 'js-cookie';
import { setPatientId } from '../../redux/features/doctor/Dashboard/patients/searchPatient';

// Axios auth config 

const getConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }; 

    return config; 

}; 

// -- 

const getBlobHeadersConfig = () => { 

    const token = Cookies.get('jwt');

    const config = { 
        headers: {
            Authorization: `Bearer ${token}`
        },
        responseType: 'blob'
    }; 

    return config as any; 

}; 

// --

export const getTemplates = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/templates`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent templates:', error);
    }
};

// -- 

export const createTemplate = async () => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/generate-link`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting doctor recent templates:', error);
    }
};

// -- 

export const uploadTemplateImages = async (tempId: any, formData: any) => {

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/upload/${tempId}`, formData, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error uploading template images:', error);
    }
};

export const verifyTempLinkStatus = async (tempLinkId: any) => {

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/status/${tempLinkId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error uploading template images:', error);
    }
};

// -- 

export const serveTemplate = async (templateId?: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/serve/${templateId}`, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving a template.', error);
    }; 

}; 

// -- 

export const saveTemplate = async (formData: any) => { 

    try {

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/templates/save`, formData, getBlobHeadersConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving a template.', error);
    }; 

}; 

// -- 

export const createTemplateTextField = async (templateId: string, x: number, y: number, width: number, height: number, style: any) => { 

    try {

        const content = { 
            templateId: templateId,
            x: x,
            y: y,
            width: width,
            height: height,
            style: style
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/templates/fields`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving a template.', error);
    }; 

}; 

// -- 

interface updatedContentProps { 
    textFieldId: string, 
    x?: number,
    y?: number, 
    width?: number,
    height?: number, 
    attribute?: string, 
    description?: string,
    style?: any
}

export const updateTemplateTextField = async (updatedContent: updatedContentProps) => { 

    try {

        const content = { 
            textFieldId: updatedContent.textFieldId,
            x: updatedContent.x,
            y: updatedContent.y,
            width: updatedContent.width,
            height: updatedContent.height,
            attribute: updatedContent.attribute,
            description: updatedContent.description,
            style: updatedContent.style
        }; 

        const res = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/templates/fields`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error serving a template.', error);
    }; 

}; 

// -- 

export const getTemplateTextFields = async (templateId: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/fields/${templateId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting a template text Field.', error);
    }; 

}; 

// -- 

export const getTemplateTextField = async (textFieldId: string) => { 

    try {

        const res = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/templates/fields/${textFieldId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error getting a template text Field.', error);
    }; 

}; 


// -- 

export const deleteTemplateTextField = async (textFieldId: string) => { 

    try {

        const res = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/templates/fields/${textFieldId}`, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error deleting a template text Field.', error);
    }; 

}; 

// -- 

export const getTemplateTextFieldAutoComplete = async (textFieldId: string, patientId: string) => { 

    try {

        const content = { 
            textFieldId: textFieldId,
            patientId: patientId,
        }; 

        const res = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/templates/autoComplete/field`, content, getConfig());

        return res; 
        
    } catch (error) {
        console.error('Error auto completing a template', error);
    }; 

}; 