import darkTheme from '../../../../../../../styles/themes/darkTheme';
import lightTheme from '../../../../../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const textFieldStyle = {
    marginLeft: 2,
    fontSize: 15,
    width: '90%',
    height: '100%',
    "& .MuiInputBase-input.Mui-disabled": { WebkitTextFillColor: currentTheme?.text }
}; 

export default textFieldStyle; 



