import React from 'react';
import Box from '@mui/material/Box';

import { LineChart } from '@mui/x-charts';

const TeamGraph: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '95%',
        height: '40%', 
        borderRadius: 5,
        backgroundColor: '#313338',
    }}>

    <LineChart
      xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
      series={[
        {
          data: [2, 5.5, 2, 8.5, 1.5, 5],
        },
      ]}
      width={700}
      height={300}
    />

    </Box>

  );

};

export default TeamGraph;