import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import DropdownRow from './DropdownRow';

import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import EventLimiter from '../../../../util/events/EventLimiter';

const UploadOptions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    const [height, setHeight] = useState(0) as any; 

    useEffect(() => { 

        const handleTransition = async () => { 

            await EventLimiter.schedule(() => setHeight(80));

        };  

        handleTransition(); 

    },[]);

    return (

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center', 
                justifyContent: 'space-evenly',
                width: 160,
                height: height, 
                position: 'absolute',
                backgroundColor: colors?.templateDropdown,
                borderRadius: 4,
                top: 40, 
                right: 20, 
                zIndex: 5, 
                transition: 'height 0.3s',
                color: colors?.highlightedText
            }}
        >
          
            <DropdownRow title={'saveTemplate'} action={'saveTemplate'}>
                <CloudDoneOutlinedIcon /> 
            </DropdownRow>

            <DropdownRow title={'saveAsPdf'} action={'saveAsPdf'}>
                <UploadFileOutlinedIcon /> 
            </DropdownRow>

            {/* <DropdownRow title={'saveAsCopy'} action={'saveAsCopy'}>
                <DifferenceOutlinedIcon /> 
            </DropdownRow> */}

        </Box>

  );

};

export default UploadOptions;