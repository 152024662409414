import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

// Components 

import Template from '../templatesList/Template';

// Utils 

// Components 

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    const isSmallScreen = useMediaQuery('(max-width: 1300px)');

    const [text, setText] = useState(''); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            width: '60%',
            height: '80%', 
            borderRadius: 4,
            marginLeft: 5,
            backgroundColor: colors?.createTemplate,
            // backgroundColor: '#383A40',
        }}>
            <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: colors?.templateSearch }}/>
            <TextField
                // onKeyDown={hanleEnterOption}
                id="standard-search"
                placeholder={'Search For a template'}
                variant="standard"
                autoComplete='off'
                value={text}
                InputProps={{
                    style: {fontSize: 12, color: colors?.text },
                    disableUnderline: true, 

                }}
                onChange={(e) => setText(e.target.value)}
                sx={{
                    marginLeft: 2,
                    fontSize: 12,
                    width: '100%',
                    borderBottomColor: colors?.templateSearch
                }}
            />

        </Box>
            
    );

};

export default SearchBar;