import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WcIcon from '@mui/icons-material/Wc';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddHomeIcon from '@mui/icons-material/AddHome';
import EmailIcon from '@mui/icons-material/Email';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import TollIcon from '@mui/icons-material/Toll';
import AttributeRow from './AttributeRow';

const Attributes: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors);

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: '100%', 
          overflowY: 'scroll', 
          scrollbarWidth: 'none'
      }}>

            <AttributeRow title={'patientId'}>
                <TollIcon sx={{ color: colors?.patientId}}/> 
            </AttributeRow>

            <AttributeRow title={'firstName'}>
                <AccountCircleIcon sx={{ color: colors?.firstName }}/> 
            </AttributeRow>

            <AttributeRow title={'lastName'} >
                <AccountBoxIcon sx={{ color: colors?.lastName }}/> 
            </AttributeRow>

            <AttributeRow title={'sex'} >
                <WcIcon sx={{ color: colors?.gender }}/> 
            </AttributeRow>

            <AttributeRow title={'dateOfBirth'}>
                <EventNoteIcon sx={{ color: colors?.dateOfBirth }}/> 
            </AttributeRow>

            <AttributeRow title={'healthCardNumber'}>
                <AccountCircleIcon sx={{ color: colors?.healthCardNumber }}/> 
            </AttributeRow>

            <AttributeRow title={'phone'}>
                <LocalPhoneIcon sx={{ color: colors?.phone }}/> 
            </AttributeRow>

            <AttributeRow title={'address'}>
                <AddHomeIcon sx={{ color: colors?.address }}/> 
            </AttributeRow>

            <AttributeRow title={'email'}>
                <EmailIcon sx={{ color: colors?.email }}/> 
            </AttributeRow>

            <AttributeRow title={'emergencyContactName'}>
              <AccountCircleIcon sx={{ color: colors?.emergencyContactName }}/> 
            </AttributeRow>

            <AttributeRow title={'emergencyContactRelation'}>
                <AccountBoxIcon sx={{ color: colors?.emergencyContactRelation }} /> 
            </AttributeRow>

            <AttributeRow title={'emergencyContactPhone'}>
                <AddHomeIcon sx={{ color: colors?.emergencyContactPhone }}/> 
            </AttributeRow>
        

      </Box>

  );

};

export default Attributes;