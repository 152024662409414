import React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../../../../../redux/hooks';

// Icons 

import GroupWorkIcon from '@mui/icons-material/GroupWork';


const TeamsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const listItems = [

      { type: 'header', title: t('myTeams')},

      { text: 'Legal', icon: <GroupWorkIcon sx={{ color: '#50C7C7' }} /> },
      { text: 'Urgent', icon: <GroupWorkIcon sx={{ color: '#50C7C7' }} /> },
      { text: 'Dispatchers', icon: <GroupWorkIcon sx={{ color: '#50C7C7' }} /> },
      { text: 'Support', icon: <GroupWorkIcon sx={{ color: '#50C7C7' }} /> },
    ];
    
      return (

        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '50%',
          width: '100%',
        }}>

          <Box sx={{ 
            overflow: 'auto',
            width: '95%',
            marginTop: '2vh' }}>
    
            <List dense>
    
              {listItems.map((item, index) => (
    
                <ListItem button key={index}>
    
                    { item.type !== 'header' && <ListItemIcon sx={{ color: '#C2C5C8' }}>{item.icon}</ListItemIcon>}
    
                    { item.type !== 'header' && <ListItemText sx={{ color: '#C2C5C8' }} primary={item.text} />}

                    { item.type === 'header' && 
                        <ListItemText 
                            sx={{ 
                                marginTop: '2vh',
                                marginBottom: '2vh',
                                textAlign: 'left',
                                color: '#C2C5C8' }} 
                                primary={item.title} 
                        />}
    
                </ListItem>
              ))}
    
            </List>
    
          </Box>

        </Box>
    
      );

};

export default TeamsList;