import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0AC5CF', // Teal blue color for primary buttons
    },
    background: {
      default: '#2B2D31', // Dark gray background
      paper: '#2B2D31',
    },
    text: {
      primary: '#ffffff', // White text color
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff', // White text color
          backgroundColor: '#0AC5CF', // Teal blue background color
          '&:hover': {
            backgroundColor: '#0898A3', // Darker teal blue on hover
          },
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
  },
});

export default theme;