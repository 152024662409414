import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UploadFileState {
  file: File | null;
  title: string;
  description: string;
  isFileUploaded: boolean
}

const initialState: UploadFileState = {
  file: null,
  title: '',
  description: '',
  isFileUploaded: false, 
};

const uploadFileSlice = createSlice({
  name: 'uploadFile',
  initialState,
  reducers: {
    setFile: (state, action: PayloadAction<File>) => {
      state.file = action.payload;
      state.title = action.payload.name; 
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    setDescription: (state, action: PayloadAction<string>) => {
      state.description = action.payload;
    },
    setIsFileUploaded: (state, action: PayloadAction<boolean>) => {
      state.isFileUploaded = action.payload;
    },
    resetUploadFile: (state) => { 
        
      state.file = null;
      state.title = '';
      state.description = ''; 

      // state.isFileUploaded is omitted on purpose. 

    } 
  },
});

export const { setFile, setTitle, setDescription, setIsFileUploaded, resetUploadFile } = uploadFileSlice.actions;

export default uploadFileSlice.reducer;