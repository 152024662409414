import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './../redux/hooks';
import { useTheme } from '../themeContext';

// -- Redux 

import { setSideBarMenu } from '../redux/features/general/navigation';

// -- Components 

import Notification from '../components/general/Notification';
import Container from '../components/settings/Container';
import NavBar from '../components/general/navBar/NavBar';
import DoctorSettings from '../components/doctor/navigation/navBar/Settings'; 
import menuItems from '../util/navigation/sidebarMenu/settings';
import Sidebar from '../components/general/navigation/Sidebar';


const Settings: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const colors = useAppSelector(state => state.theme.colors);

  const { isLightMode, toggleTheme } = useTheme();

  const navigate = useNavigate();
  const { t } = useTranslation();

  // -- 

  useEffect(() => { 

    dispatch(setSideBarMenu(menuItems(t))); 

  }, [t]); 

  // -- 

  return (
    <Box
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        height: '100vh', 
        width: '100vw',
        left: 0, 
        overflowX: 'hidden',
        position: 'absolute',
        color: colors?.highlightedText
      }}
    >
        <NavBar>
            <DoctorSettings /> 
        </NavBar>

        <Sidebar /> 

        <Container />

        <Notification />

    </Box>
  );
};

export default Settings;