import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setSupportTicketCategory } from '../../../../redux/features/general/createSupportTicket';

// Components 

import TextField from '@mui/material/TextField';

const Category: React.FC = () => {

  const dispatch = useAppDispatch(); 

  const category = useAppSelector(state => state.createSupportTicket.category);

  const [text, setText] = useState(''); 

  // Redux 

  useEffect(() => {

    dispatch(setSupportTicketCategory(text)); 

  },[text]); 

  return (

    <Box sx={{ 
        marginTop: '3vh',
        marginBottom: '3vh',
    }}>

        <TextField 
          sx={{ width: '20%', alignSelf: 'center' }} 
          onChange={(e) => setText(e.target.value)}
          id="standard-basic" 
          label="Category" 
          value={category}
          variant="standard" />


    </Box>

  );

};

export default Category;