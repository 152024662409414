import React from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

type TitleProps = {
    title: string
};

const ConfirmationTitle: React.FC<TitleProps> = ({ title }) => {

    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            fontWeight: 'bold',
            width: '100%',
            minHeight: 50,
            marginBottom: 5,
            color: colors?.text, 
        }}>{t(title)}
        </Box>
  );
};

export default ConfirmationTitle;