import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface ConfirmationState {
  message: string,
  buttonMessage: string,
}

// ---------------

const initialState: ConfirmationState = {
  message: '',
  buttonMessage: '',
};

// ---------------


export const adminConfirmationSlice = createSlice({

  name: 'adminConfirmation',

  initialState,

  reducers: {

    setConfirmationMessage: (state, action: PayloadAction<string>) => {

        state.message = action.payload;
    },
    setConfirmationButtonMessage: (state, action: PayloadAction<string>) => { 
        
        state.buttonMessage = action.payload; 

    } 
  },

}); 

// ---------------

export const { setConfirmationMessage, setConfirmationButtonMessage } = adminConfirmationSlice.actions;


export default adminConfirmationSlice.reducer;