

const requiredFields = [ 
    'firstName', 
    'lastName',
    'gender',
    'dateOfBirth', 
    'healthCardNumber',
    'phone',
    'address', 
    'email', 
]; 

export default requiredFields; 