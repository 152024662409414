import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Routes 

import { getAccessRequests } from '../../../../../../../routes/doctor/patients';

// Redux 

import { setDashboardSection } from '../../../../../../../redux/features/general/navigation';
import { setAccessRequests, setInitialRendering, setIsRefreshAccessRequests } from '../../../../../../../redux/features/doctor/Dashboard/patients/accessRequests';

// Components 

import AccessRequestsHeader from './AccessRequestsHeader';
import AccessRequestRow from './AccessRequestRow';

// Icons 

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const AccessRequestsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState(false); 

    const colors = useAppSelector(state => state.theme.colors); 

    const accessRequests = useAppSelector((state) => state.accessRequests.accessRequests); 
    const initialRendering = useAppSelector((state) => state.accessRequests.initialRendering); 
    const isRefreshAccessRequests = useAppSelector((state) => state.accessRequests.isRefreshAccessRequests); 

    // -- 

    const getNotificationsList = async () => { 

      setIsLoading(true); 

      const res = await getAccessRequests() as any;

      setIsLoading(false); 

      if (res?.data.status === 200) { 

        dispatch(setAccessRequests(res.data.accessRequests)); 

      };

    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

        getNotificationsList(); 

        dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // --

    useEffect(() => { 

      if (isRefreshAccessRequests) { 

        getNotificationsList(); 

        dispatch(setIsRefreshAccessRequests(false)); 

      }; 

    },[isRefreshAccessRequests]); 
    
    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '95%',
            marginTop: '1.5%', 
            width: '95%',
            backgroundColor: colors?.container,
        }}>

            <AccessRequestsHeader />

            { accessRequests?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2.5vh',
                scrollbarWidth: 'none',
                height: '82.5' }}>
        
                <List dense>
        
                {accessRequests?.map((item, index) => (
        
                    <ListItem button key={index}>

                        <AccessRequestRow
                            accessRequestId={item._id}
                            patientId={item.patientId}
                            status={item.status}
                            updatedAt={moment.utc(item.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {isLoading &&         
          
              <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly', 
                  height: '100%',
                  width: '80%', 
              }}>

                  <Box
                      sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}>

                      <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                      <Box>Retrieving Access Requests...</Box>

                  </Box>

              </Box>}

            </Box>
    
      );

};

export default AccessRequestsList;