import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Box from '@mui/material/Box';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Forms from './categories/Forms';
import AutoComplete from './categories/autoComplete/AutoComplete';

const Category: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const colors = useAppSelector(state => state.theme.colors); 
    const section = useAppSelector(state => state.template.section); 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '15vw',
          height: '90vh',
          position: 'absolute', 
          left: '5vw',
          bottom: 0,  
          backgroundColor: colors?.interface, 
      }}>

        { section === 'forms' && <Forms />} 

        { section === 'autoComplete' && <AutoComplete />} 

      </Box>

    );

};

export default Category;