import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components 

import TextField from '@mui/material/TextField';



const Legend: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '7.5vh',
        width: '100%',
        paddingLeft: '1%',
        backgroundColor: '#2B2D31',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        padding: '0 1vw',
    }}>

        <>

          <div style={{  width: '13%', marginLeft: '2%' }}>Category</div>
          
          <Box sx={{ width: '15%' }}>Volatility</Box>

          <Box sx={{ width: '15%' }}>Last 24 hours</Box>

          <Box sx={{ width: '15%' }}>Variation</Box>

          <Box sx={{ width: '15%' }}>Monthly Average</Box>

          <Box sx={{ width: '15%' }}>Total</Box>

          <Box sx={{ width: '10%', textAlign: 'center', }}>More</Box>

        </>


    </Box>

  );

};

export default Legend;