import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../redux/hooks';

import CssBaseline from '@mui/material/CssBaseline';

// Redux 

import { setSideBarMenu } from '../redux/features/general/navigation';

// Utils

import menuItems from '../util/navigation/sidebarMenu/hacker';

// Components 

import NavBar from '../components/general/navBar/NavBar';
import Sidebar from '../components/general/navigation/Sidebar';
import Container from '../components/hacker/Container';
import Notification from '../components/general/Notification';
import Settings from '../components/hacker/navigation/Settings'; 


const Hacker: React.FC = () => {

  const dispatch = useAppDispatch(); 

  useEffect(() => { 

    dispatch(setSideBarMenu(menuItems)); 

  },[]); 

  return (

    <React.Fragment>

        <CssBaseline />

        <Box component="main" sx={{ 
            display: 'flex', 
            height: '100vh', 
            width: '100vw',
            left: 0, 
            overflowX: 'hidden',
            position: 'absolute',
            color: 'white' }}>

          <NavBar>
            <Settings />
          </NavBar>
          
          <Sidebar />

          <Container />

          <Notification />

        </Box>

    </React.Fragment>

  );

};

export default Hacker;