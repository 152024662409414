import React from 'react';
import Box from '@mui/material/Box';

// Components 

import InfoList from './InfoList';
import UpdateTeam from './UpdateTeam';

const TeamInfoContainer: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        width: '20%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#383A40',
    }}>

      <InfoList />

      <UpdateTeam />

    </Box>

  );

};

export default TeamInfoContainer;