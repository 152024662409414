import darkTheme from '../../../styles/themes/darkTheme';
import lightTheme from '../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const menuItems = [

    { type: 'item', section: 'documents', text: 'docs', iconName: 'home', iconColor: currentTheme?.sidebarIconColor, key:'i-documents'},

    { type: 'item', section: 'teams', text: 'teams', iconName: 'group', iconColor: currentTheme?.sidebarIconColor, key:'i-teams'},

    { type: 'item', section: 'createTeam', text: 'createTeam', iconName: 'add_circle_outline', iconColor: currentTheme?.sidebarIconColor, key:'i-createTeam'},

    { type: 'item', section: 'users', text: 'users', iconName: 'people', iconColor: currentTheme?.sidebarIconColor, key:'i-users'},

    { type: 'item', section: 'createUser', text: 'add', iconName: 'person_add', iconColor: currentTheme?.sidebarIconColor, key:'i-createUser'},


]; 

export default menuItems; 