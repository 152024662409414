import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Routes 


// Redux 

import { setDashboardSection } from '../../../../../../../redux/features/general/navigation';
import { setCurrentPatientId, setIsRefreshPatientLogs, setPatientLogs, setInitialRendering } from '../../../../../../../redux/features/doctor/Dashboard/patients/patientLogs';

// Components 

import PatientLogsHeader from './PatientLogsHeader';
import PatientLogsRow from './PatientLogsRow';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LinearProgress from '@mui/material/LinearProgress';
import { getPatientLogs } from '../../../../../../../routes/doctor/patients';

const PatientLogsList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const colors = useAppSelector(state => state.theme.colors);
    const patient = useAppSelector(state => state.patient.patient);
    const currentPatientId = useAppSelector(state => state.patientLogs.currentPatientId); 
    const initialRendering = useAppSelector(state => state.patientLogs.initialRendering); 
    const patientLogs = useAppSelector((state) => state.patientLogs.patientLogs); 
    const isRefreshPatientLogs = useAppSelector((state) => state.patientLogs.isRefreshPatientLogs); 

    // -- 

    const getPatientLogsList = async () => { 

      const res = await getPatientLogs(patient._id) as any; 

      if (res?.data.status === 200) { 

        dispatch(setPatientLogs(res.data.patientLogs)); 
        dispatch(setCurrentPatientId(patient._id)); 

      };

    }; 

    // -- 

    const selectPatientLog = (patientLog: any) => { 


    }; 

    // --

    const getAdditionalInfo = async (author: string) => { 


    }; 

    // -- 

    useEffect(() => { 

      if (initialRendering) { 

          getPatientLogsList(); 

          dispatch(setInitialRendering(false)); 

      }; 

    },[initialRendering]); 

    // --

    useEffect(() => { 

      if (isRefreshPatientLogs) { 

        getPatientLogsList(); 

        dispatch(setIsRefreshPatientLogs(false)); 

      }; 

    },[isRefreshPatientLogs]); 

    // -- 

    useEffect(() => { 

      if (currentPatientId && patient) { 

        if (currentPatientId != patient?._id) { 

          getPatientLogsList(); 

        }; 

      }; 

    },[currentPatientId, patient]);
    
    // -- 

    return (

        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '95%',
            marginTop: '1.5%', 
            width: '95%',
            backgroundColor: colors?.container,
        }}>

            <PatientLogsHeader />

            { patientLogs?.length >= 1 && 
            <Box sx={{ 
                overflow: 'auto',
                width: '100%',
                marginTop: '2.5vh',
                scrollbarWidth: 'none',
                height: '82.5' }}>
        
                <List dense>
        
                {patientLogs?.map((item, index) => (
        
                    <ListItem onClick={() => selectPatientLog(item)} button key={index}>

                        <PatientLogsRow
                            patientId={item.patientId} 
                            doctorId={item.doctorId}
                            type={item.type} 
                            description={item.description}
                            createdAt={moment.utc(item.createdAt).format('LL')}
                        /> 
       
                    </ListItem>
                ))}
        
                </List>
        
            </Box>}

            {patientLogs?.length === 0 &&         
          
              <Box sx={{ 
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-evenly', 
                  height: '100%',
                  width: '80%', 
              }}>

                  <Box
                      sx={{ 
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}>

                      <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                      <Box>Retrieving Support Tickets...</Box>

                  </Box>

              </Box>}

            </Box>
    
      );

};

export default PatientLogsList;