import React from 'react';
import Box from '@mui/material/Box';

// Components 

import TeamGraph from './TeamGraph';
import TeamNumbersOverview from './TeamNumbersOverview';

const TeamDataContainer: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '75%',
        height: '95%', 
        borderRadius: 5,
        backgroundColor: '#383A40',
    }}>

        <TeamGraph />

        <TeamNumbersOverview />

    </Box>

  );

};

export default TeamDataContainer;