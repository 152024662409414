import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import SectionHeader from './formItems/SectionHeader';
import Category from './formItems/Category';
import EmailDescription from './formItems/EmailDescription';
import SubmitButton from './formItems/SubmitButton';

const CreateTicket: React.FC = () => {

  const { t } = useTranslation();
  const accountInfo = useAppSelector((state) => state.meta.accountInfo);

  return (

    <div style={{ 
        overflow: 'hidden',
        height: '87%',
        marginTop: '13vh', 
        marginLeft: '15vw',
        width: '85vw',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
    }}>

      <SectionHeader title={`${t('from')} ${accountInfo.firstName} ${accountInfo.lastName}`} /> 

      <Category />

      <EmailDescription />

      <SubmitButton />

    </div>

  );

};

export default CreateTicket;