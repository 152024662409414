import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Components 

import TextField from '@mui/material/TextField';

interface Props { 
    title?: string
}

const PatientLogsHeader: React.FC<Props> = ({title}) => {

  const colors = useAppSelector(state => state.theme.colors);

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '7.5vh',
        width: '100%',
        paddingLeft: '1%',
        backgroundColor: colors?.listHeaderBackground,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        padding: '0 1vw',
    }}>

        <>

          <div style={{  width: '18%', marginLeft: '2%' }}>Doctor</div>

          <div style={{ width: '50%', marginLeft: '5%' }}>Description</div>

          <div style={{ width: '15%' }}>Since</div>

          <div style={{ width: '10%', textAlign: 'center', }}>More</div>

        </>


    </Box>

  );

};

export default PatientLogsHeader;