import React from 'react';
import Box from '@mui/material/Box';
import ContactPageIcon from '@mui/icons-material/ContactPage';

import DataBox from './items/DataBox';

const CurrentData: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '45%',
        height: '95%', 
        borderRadius: 5,
    }}>

      <DataBox title='Waiting' type='waiting' data='54' />

      <DataBox title='Being Reviewed' type='review' data='21' />

      <DataBox title='Revoked' type='revoked' data='13' />

    </Box>

  );

};

export default CurrentData;