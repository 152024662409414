import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../../redux/hooks';

// Routes

// Redux 

// Components 

import Box from '@mui/material/Box';
import Avatar from './patientFound/Avatar';
import PatientInfo from './patientFound/PatientInfo';
import AuthSection from './patientFound/AuthSection';


const PatientFound: React.FC = () => {

    const colors = useAppSelector(state => state.theme.colors);

    return (
            
        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            width: '60%', 
            height: 200, 
            backgroundColor: colors?.patientFoundContainer,
            borderRadius: 5, 
            top: '30%',
            position: 'absolute', 
            fontSize: 12,
        }}>

            <Avatar /> 

            <PatientInfo /> 

            <AuthSection /> 

        </Box>

    );

};

export default PatientFound;