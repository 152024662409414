import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import Action from '../../actions/Action';

// Icons 

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';

const FolderActions: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);

    const selectedFolder = useAppSelector(state => state.fileExplorer.selectedFolder); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center', 
            zIndex: 10,
            right: '25%',
            height: 30, 
            position: 'absolute', 
            backgroundColor: colors?.container,
        }}>

            <Action title={'newFolder'} iconColor={colors?.folder} action={'createFolder'} type='default' hideLabel>
                <AddCircleOutlineIcon /> 
            </Action>

            { !selectedFolder?.protected && 
            <Action title={'renameFolder'} iconColor={colors?.renameFileIcon} action={'renameFolder'} type='default' hideLabel>
                <DriveFileRenameOutlineIcon /> 
            </Action>}

            <Action title={'moveTo'} iconColor={colors?.moveToIcon} action={'moveFolder'} type='default' hideLabel>
                <DriveFileMoveIcon /> 
            </Action>

            { selectedFolder?.pinned ? 
            <Action title={'removePin'} iconColor={colors?.removePinIcon} action={'removePin'} type='default' hideLabel>
                <PushPinOutlinedIcon /> 
            </Action>: 
            <Action title={'pinFolder'} iconColor={colors?.pinFolderIcon} action={'pinFolder'} type='default' hideLabel>
                <PushPinOutlinedIcon /> 
            </Action>} 

            { !selectedFolder?.protected && 
            <Action title={'deleteFolder'} iconColor={colors?.deleteIcon} action={'deleteFolder'} type='default' hideLabel>
                <DeleteIcon /> 
            </Action>}

        </Box>

    );

};

export default FolderActions;