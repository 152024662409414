import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Redux 

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { setSearchQuery } from '../../../../../redux/features/doctor/Dashboard/documents/fileExplorer';
import { useTranslation } from 'react-i18next';

// Components 

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar: React.FC = () => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation(); 
    
    const colors = useAppSelector(state => state.theme.colors);

    const searchQuery = useAppSelector((state) => state.fileExplorer.searchQuery);
    const currentFolder = useAppSelector((state) => state.fileExplorer.currentFolder);  

    const [text, setText] = useState(''); 
    const [displayedText, setDisplayedText] = useState(''); 

    // --

    useEffect(() => { 

        if (text) { 

            dispatch(setSearchQuery(text)); 

            setDisplayedText(text); 

        } else { 

            dispatch(setSearchQuery('no-search')); 

            setDisplayedText(''); 

        }; 

    },[text]); 

    // -- 

    useEffect(() => { 

        setDisplayedText(''); 
        setText(''); 

    },[currentFolder]); 

    // -- 

    return (

        <Box style={{ 
            display: 'flex', 
            flexDirection: 'row-reverse',
            alignItems: 'center', 
            width: '24%',
            height: '100%',
            marginRight: '1%', 
            marginBottom: '1%',
            fontSize: 12, 
        }}>

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: '100%', 
            borderRadius: 2,
            backgroundColor: colors?.fileExplorerSearchBar,
        }}>
            <SearchIcon sx={{ fontSize: 12, marginLeft: 2, color: colors?.searchIcon }}/>
            <TextField
                id="standard-search"
                placeholder={t('search') || 'Search'}
                type="search"
                variant="standard"
                value={displayedText}
                InputProps={{
                    style: {fontSize: 12, color: colors?.text },
                    disableUnderline: true, 
                }}
                onChange={(e) => setText(e.target.value)}
                sx={{
                    marginLeft: 2,
                    fontSize: 12,
                    width: '80%',
                    borderBottomColor: colors?.searchIcon
                }}
            />
        </Box>
    
     </Box>

    );

};

export default SearchBar;