


const getPdfUrl = (pdfBytes: any) => { 

   const url = URL.createObjectURL(new Blob([pdfBytes], { type: "application/pdf" }));

   return url; 

}; 

export default getPdfUrl; 