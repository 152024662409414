import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

// Routes 

import { requestAuthCode, resendAuthCode, verifyAuthCode } from '../../routes/general/authentication';
import { createDevHackerAccount } from '../../routes/hacker/users';

// Redux 

import { setNotificationMessage } from '../../redux/features/general/notification';
import { setAccountType, setAccountInfo } from '../../redux/features/general/meta';

// Utils 

import clearCookies from '../../util/storage/clearCookies';
import clearLocalStorage from '../../util/storage/clearLocalStorage';

// Components 

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const Container: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const navigate = useNavigate(); 

    const [email, setEmail] = useState(''); 

    const [showResendCode, setShowResendCode] = useState(false); 

    const [allowSubmit, setAllowSubmit] = useState(false); 
    const [isCodeSent, setIsCodeSent] = useState(false); 
    const [error, setError] = useState(false); 
    const [errorMessage, setErrorMessage] = useState('') as any; 
    const [verificationCode, setVerificationCode] = useState(''); 
    const [loginAttemptId, setLoginAttemptId] = useState(''); 

    const [isAccountCreationOpened, setIsAccountCreationOpened] = useState(false); 

    // Account creation 

    const [newEmail, setNewEmail] = useState(''); 
    const [firstName, setFirstName] = useState(''); 
    const [lastName, setLastName] = useState(''); 

    // -- 

    const handleLogin = async () => { 

        if (!isCodeSent) { 

            clearCookies(); 
            clearLocalStorage(); 

            const res = await requestAuthCode(email, 'hacker') as any; 

            if (res.data.status === 200) { 
    
                setLoginAttemptId(res.data.loginAttemptId); 
                
                setIsCodeSent(true);

                dispatch(setNotificationMessage(t('authCodeSent')));

                setError(false); 
                setErrorMessage('');  
    
            } else { 
    
                dispatch(setNotificationMessage(t('invalidEmail')));

                setError(true); 
                setErrorMessage(t('invalidEmail')); 
    
            }; 

        } else if (isCodeSent) { 

            const res = await verifyAuthCode(email, verificationCode, 'hacker', loginAttemptId) as any; 

            if (res.data.status === 200) { 

                const { token } = res.data;
        
                document.cookie = `adminJwt=${token}; path=/`;

                dispatch(setAccountType(res.data.userType)); 
                dispatch(setAccountInfo(res.data.account)); 

                setError(false); 
                setErrorMessage(''); 

                navigate('/hacker'); 

            } else { 

                dispatch(setNotificationMessage(t('verificationCodeNotFound')));

                setError(true); 
                setErrorMessage(t('verificationCodeNotFound')); 

            }; 

        }; 

        setShowResendCode(true); 

    }; 

    // --  

    const handleResendCode = async () => { 

        const res = await resendAuthCode(email, 'hacker', loginAttemptId) as any; 

        if (res.data.status === 200) { 

            setIsCodeSent(true);

            dispatch(setNotificationMessage(t('authCodeSent')));

            setError(false); 
            setErrorMessage(''); 

        } else { 

            dispatch(setNotificationMessage(t('invalidEmail')));

            setError(true); 
            setErrorMessage(t('invalidEmail')); 

        }; 

        setShowResendCode(true); 

    }; 

    // -- 

    const handleInput = (key: string) => { 

        if (key == 'Enter') { 

            handleLogin(); 

            setShowResendCode(true); 

        }; 

    };

    // -- 

    const handleAccountCreation = async () => {

        if (newEmail && firstName && lastName) { 

            const res = await createDevHackerAccount(newEmail, firstName, lastName) as any; 

            if (res.data.status === '200') { 

                dispatch(setNotificationMessage('Account successfully created!')); 

                setNewEmail('');
                setFirstName('');
                setLastName(''); 

                setIsAccountCreationOpened(false); 

            }; 

        }; 

    }; 

    // -- 

    const goBackToEmail = () => { 

        setIsCodeSent(false); 
        setVerificationCode(''); 

    }; 

    // -- 

    useEffect(() => { 

        if ((email.length > 3) && email.includes('@')) { 

            setAllowSubmit(true); 

        } else { 

            setAllowSubmit(false); 

        }; 

    },[email]); 

    // -- 

    return (


        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '20vw',
            marginTop: '5vh',
            height: '60vh', 
            right: '40vw',
            borderRadius: 3, 
            backgroundColor: 'rgba(62, 87, 104, 0.2)',
            zIndex: 5,
            position: 'absolute'
        }}>

            { !isAccountCreationOpened &&
            
            <>

            {/* Header */}

            { isCodeSent ? 
            
                <Box 
                    onClick={() => goBackToEmail()}
                sx={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    color: 'rgb(204, 204, 204)',
                    marginTop: '5vh',
                    height: '15vh',
                    flexShrink: 3,
                    cursor: 'pointer',
                    '&:hover': {
                        color: 'rgb(254, 254, 254)',
                    },
                }}
                >   
                <ArrowCircleLeftIcon sx={{ marginRight: 1 }}/>
                <Box>Back to Email</Box>
                </Box>:
                <Box sx={{ 
                    color: 'rgb(204, 204, 204)',
                    marginTop: '5vh',
                    height: '15vh',
                    flexShrink: 3
                }}
                >
                    Hacker Login
                </Box>}

            {/* Email */}

            { !isCodeSent && 
            
            <TextField 
                sx={{ width: '60%', marginBottom: '2vh', fontSize: 10, alignSelf: 'center' }} 
                onChange={(e) => setEmail(e.target.value)}
                id="standard-basic" 
                label="Email" 
                value={email}
                variant="standard" 
                error={error}
                helperText={errorMessage}
                autoFocus
                onKeyDown={(e) => handleInput(e.key)}
            />}

            { isCodeSent && 
            
            <TextField 
                sx={{ width: '60%', marginBottom: '2vh', alignSelf: 'center' }} 
                onChange={(e) => setVerificationCode(e.target.value)}
                id="standard-basic" 
                label="Verification Code" 
                value={verificationCode}
                variant="standard" 
                error={error}
                helperText={errorMessage}
                autoFocus
                onKeyDown={(e) => handleInput(e.key)}
            />}


            { showResendCode ? 
            <Box
                onClick={() => handleResendCode()}
                sx={{ 
                    color: 'rgb(204, 204, 204)',
                    height: '8vh',
                    fontSize: 12,
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#05E5D3',
                    },
                }}>Resend Code

            </Box>:
            <Box
                sx={{ 
                    color: 'rgb(204, 204, 204)',
                    height: '8vh',
                }}>
            </Box>}

        { allowSubmit ? 
        <Button type="submit" onClick={() => handleLogin()} variant="contained" sx={{ width: '60%' }}>Login</Button>: 
        <Button 
          disabled={true}
          sx={{ 
            width: '60%',
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">Login</Button>}

            {/* Footer */}

            { process.env.NODE_ENV === 'development' &&

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                color: 'rgb(120, 120, 120)',
                fontSize: 15,
                marginTop: '5vh',
                marginBottom: '2vh',
            }}
            >
            
            <Box sx={{
                    marginRight: 1
                }}>No Account?</Box>
                <Box
                onClick={() => setIsAccountCreationOpened(true)}
                sx={{ 
                    color: 'rgb(9,109,108)', 
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#05E5D3',
                    },
                }}> Create One</Box>.
            </Box>}

            </>} 

            {/* Account creation */}

            { isAccountCreationOpened &&

            <>

                <TextField 
                    sx={{ width: '60%', marginTop: '3vh', marginBottom: '2vh', fontSize: 10, alignSelf: 'center' }} 
                    onChange={(e) => setNewEmail(e.target.value)}
                    id="standard-basic" 
                    label="Email" 
                    value={newEmail}
                    variant="standard" 
                    autoFocus
                />

                <TextField 
                    sx={{ width: '60%', marginBottom: '2vh', fontSize: 10, alignSelf: 'center' }} 
                    onChange={(e) => setFirstName(e.target.value)}
                    id="standard-basic" 
                    label="First Name" 
                    value={firstName}
                    variant="standard" 
                    autoFocus
                />

                <TextField 
                    sx={{ width: '60%', marginBottom: '10vh', fontSize: 10, alignSelf: 'center' }} 
                    onChange={(e) => setLastName(e.target.value)}
                    id="standard-basic" 
                    label="Last Name" 
                    value={lastName}
                    variant="standard" 
                    autoFocus
                />

                { process.env.NODE_ENV === 'development' &&
                <Button 
                    type="submit" 
                    onClick={() => handleAccountCreation()} 
                    variant="contained" 
                    sx={{ width: '60%' }}>Create
                </Button>}

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                color: 'rgb(120, 120, 120)',
                fontSize: 15,
                marginTop: '5vh',
                marginBottom: '2vh',
            }}
            >
                <Box sx={{
                    marginRight: 1
                }}>It was a Joke?</Box>
                <Box
                onClick={() => setIsAccountCreationOpened(false)}
                sx={{ 
                    color: 'rgb(9,109,108)', 
                    cursor: 'pointer',
                    '&:hover': {
                        color: '#05E5D3',
                    },
                }}> Back</Box>.
            </Box>
            
            </>} 

        </Box>
  );
};

export default Container;