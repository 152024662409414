import React from 'react';
import { useState, useEffect } from 'react'; 
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from '../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Redux 

import { setSection } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';

// Components 

import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';

const NavigationBar: React.FC = () => {

    const { t } = useTranslation();

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);

    const previousSection = useAppSelector((state) => state.createPatient.previousSection); 
    const nextSection = useAppSelector((state) => state.createPatient.nextSection); 
    const editedPatient = useAppSelector(state => state.createPatient.editedPatient);  

    // -- 

    const goToPreviousSection = () => { 

        dispatch(setSection(previousSection)); 

    }; 

    // --  

    const goToNextSection = () =>  {

        dispatch(setSection(nextSection)); 

    }; 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '65%',
            height: 40,
            color: colors?.text, 
            bottom: 30, 
            position: 'absolute',
        }}>

            { previousSection ?
            
            <Box 
            sx={{ 
                display: 'flex',
                flexDirection: 'row', 
                cursor: 'pointer',
                '&:hover': { color: colors?.highlightedText }
            }}
            onClick={() => goToPreviousSection()}>
                <ArrowCircleLeftOutlinedIcon sx={{ marginRight: 2 }} />
                <Box>{t(previousSection)}</Box>
            </Box>:
            <Box></Box>}
            

            { nextSection ?
            <Box 
                sx={{ 
                display: 'flex',
                flexDirection: 'row', 
                cursor: 'pointer',
                '&:hover': { color: colors?.highlightedText }
                }}
                onClick={() => goToNextSection()}
                >
                <Box sx={{ marginRight: 2 }}>{t(nextSection)}</Box>
                <ArrowCircleRightOutlinedIcon />
            </Box>: 
            <Box></Box>}

        </Box>
  );
};

export default NavigationBar;
