import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';

// Utils 

import getRandomColor from '../../../../../../../util/style/getRandomColor';
import getStatusColor from '../../../../../../../util/style/getStatusColor';

// Routes 

// Components 

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getDoctorName, getMetaPatientInfo } from '../../../../../../../routes/doctor/patients';

type Props = {
  patientId: string, 
  doctorId: string, 
  type: string,
  description?: string, 
  createdAt?: string, 
};


const PatientLogsRow: React.FC<Props> = ({patientId, doctorId, type, description, createdAt }) => {

    const dispatch = useAppDispatch(); 

    const colors = useAppSelector(state => state.theme.colors);
    const patient = useAppSelector(state => state.patient.patient); 

    const [patientName, setPatientName] = useState(''); 
    const [doctorName, setDoctorName] = useState(''); 
    const [descriptionColor, setDescriptionColor] = useState(''); 

    // -- 
    
    const getAdditionalInfo = async () => { 

    }; 

    // -- 

    const handleDoctorId = async () => { 

        const res = await getDoctorName(doctorId) as any;  

        if (res.data.status === 200) { 

            setDoctorName(res?.data.fullName); 
        };
    };

    // Initial rendering 

    useEffect(() => { 

        getAdditionalInfo(); 
        
    },[]); 

    // -- 

    useEffect(() => { 

        if (doctorId) { 

            handleDoctorId(); 

        };

    },[doctorId]); 

    // -- 

    useEffect(() => { 

        if (type) { 

            type === 'creation' && setDescriptionColor('rgb(45, 207, 62)');
            type === 'note' && setDescriptionColor('rgb(188, 45, 207)'); 
            type === 'directive' && setDescriptionColor('rgb(207, 137, 45)'); 
            type.includes('edit') && setDescriptionColor('rgb(45, 153, 207)');
            type.includes('delete') && setDescriptionColor('rgb(247, 29, 0)');
            type === 'requestAccess' && setDescriptionColor('rgb(45, 207, 196)');
            type === 'requestAccepted' && setDescriptionColor('rgb(45, 207, 62)'); 

        }; 

    },[type]); 


  return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
    }}>
            {/* Initials / User avatar */}

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                color: colors?.text, 
                width: '18%', 
                marginLeft: '2%',
                textAlign: 'left',
                overflow: 'hidden',
            }}>{doctorName}
            </div>

            <div style={{ 
                width: '50%', 
                marginLeft: '5%',
                color: descriptionColor, 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{description}</div>

            <div style={{ 
                width: '15%', 
                color: colors?.text, 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{createdAt}</div>

            <div style={{ 
                width: '10%', 
                color: colors?.text, 
                textAlign: 'center',
                overflow: 'hidden', 
            }}>
                <MoreVertIcon></MoreVertIcon>
            </div>

    </Box>

  );

};

export default PatientLogsRow;