import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 

// ---------------
 
interface CreateSupportTicketState {
  author: string,
  status: string,
  category: string, 
  description: string, 
}

// ---------------

const initialState: CreateSupportTicketState = {
  author: '', 
  status: 'active', 
  category: '', 
  description: '', 
};

// ---------------


export const createSupportTicketSlice = createSlice({

  name: 'createSupportTicket',

  initialState,

  reducers: {

    setSupportTicketAuthor: (state, action: PayloadAction<string>) => {
        state.author = action.payload
    },
    setSupportTicketStatus: (state, action: PayloadAction<string>) => { 
        state.status = action.payload  
    },
    setSupportTicketCategory: (state, action: PayloadAction<any>) => { 
        state.category = action.payload; 
    }, 
    setSupportTicketDescription: (state, action: PayloadAction<any>) => { 

      state.description = action.payload; 

    },
    setResetCreateSupportTicket: (state) => { 
        
      state.author = ''; 
      state.status = 'active'; 
      state.category = ''; 
      state.description = ''; 

    } 
  },

}); 

// ---------------

export const { setSupportTicketAuthor, setSupportTicketStatus, setSupportTicketCategory, setSupportTicketDescription, setResetCreateSupportTicket } = createSupportTicketSlice.actions;

export default createSupportTicketSlice.reducer;