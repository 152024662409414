import React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Box from '@mui/material/Box';

const BannerHeader: React.FC = () => {

  const colors = useAppSelector(state => state.theme.colors);
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly', 
        alignItems: 'center',
        minHeight: 400,
        width: '100vw',
        fontSize: 30,
        color: colors?.legalBannerText,
        backgroundColor: colors?.container,
      }}
    > Legal Information About Medca

    </Box>
  );
};

export default BannerHeader;