import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { colorToComponents, degrees, PDFDocument, rgb, StandardFonts, setFontAndSize, TextAlignment  } from 'pdf-lib';

// Components 

import Box from '@mui/material/Box';
import { setCurrentPdfBytes, setCurrentTextField, setFields, setIsRefreshFieldsList, setUpdatePdf } from '../../../../../redux/features/template/template';
import FontSize from '../../../navbar/items/FontSize';
import FormRow from './FormRow';
import CreateForm from './CreateForm';
import Attributes from './Attributes';

const Forms: React.FC = () => {

    const dispatch = useAppDispatch(); 
    const { t } = useTranslation(); 

    const fields = useAppSelector(state => state.template.fields);
    const isAttributesDropdownOpened = useAppSelector(state => state.template.isAttributesDropdownOpened); 
    const isRefreshFieldsList = useAppSelector(state => state.template.isRefreshFieldsList); 
    const currentPdfUrl = useAppSelector(state => state.template.currentPdfUrl); 
    const textFields = useAppSelector(state => state.template.textFields); 

    // -- 

    useEffect(() => { 

      if (isRefreshFieldsList) { 



      }; 

    },[isRefreshFieldsList]); 

    // -- 

    return (

      <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center', 
          width: '100%',
          height: '100%',
          overflow: 'hidden', 
          paddingTop: 1, 
      }}>

        { currentPdfUrl && <CreateForm />}

        { !isAttributesDropdownOpened && 
        
        <Box 
          sx={{ 
          overflow: 'auto',
          alignItems: 'center', 
          width: '100%',
          height: '80%',
          scrollbarWidth: 'none' }}>

        {(textFields?.length > 0) && textFields?.map((item, index) => (
            <FormRow
              key={index} 
              textFieldId={item._id}
              x={item.x}
              y={item.y}
              width={item.width}
              height={item.height}
              description={item.description}
              attribute={item.attribute}
              style={item.sytle}
              textField={item}
            />
        ))}

        </Box>}

        { isAttributesDropdownOpened && <Attributes />} 

      </Box>

  );

};

export default Forms;