

const getFormattedPathName = (section: string, directory: string, currentFolder: any) => { 

    if (section.includes('(')) { 

        const pathStart = directory.split(currentFolder.relatedPatient); 

        if (pathStart[0]) { 

            return `${pathStart[0]}${currentFolder.relatedPatient}`; 

        } else { 

            return section; 

        }; 

    } else {

        const pathStart = directory.split(section); 

        if (pathStart[0]) { 

            return `${pathStart[0]}${section}`; 

        } else { 

            return section; 

        }; 

    };

}; 

export default getFormattedPathName; 