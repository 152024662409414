import React, { useState, useEffect }from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { useNavigate } from 'react-router-dom';

// Components 

import Box from '@mui/material/Box';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import { useTheme } from '../../../themeContext';
import { setColors } from '../../../redux/features/general/theme';
import lightTheme from '../../../styles/themes/lightTheme';
import darkTheme from '../../../styles/themes/darkTheme';

const Theme: React.FC = () => {

  const navigate = useNavigate(); 
  const dispatch = useAppDispatch(); 

  const { isLightMode, toggleTheme } = useTheme();

  const colors = useAppSelector(state => state.theme.colors);
  const section = useAppSelector(state => state.navigation.section);

  // -- 

  const handleTheme = (type: string) => {

    if (type === 'light') { 
        
        localStorage.setItem('theme', 'light'); 

        dispatch(setColors(lightTheme));

    } else if (type === 'dark') { 

        localStorage.setItem('theme', 'dark'); 

        dispatch(setColors(darkTheme));

    }; 

  }; 

  // -- 

  return (

    <Box
        sx={{ 
            display: 'flex', 
            flexDirection: 'row',
            alignItems: 'center',
            height: 50, 
            marginLeft: 10, 
            marginTop: 5,
            color: colors?.text,
    }}>

        <Box sx={{ marginLeft: 2 }}>Theme</Box>

        <Box
            onClick={() => handleTheme('dark')}
            sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly', 
                width: 50,
                height: 50,
                marginLeft: 3,
                borderRadius: '50%',
                cursor: 'pointer',  
                borderStyle: 'solid',
                borderWidth: 2, 
                borderColor: isLightMode ? colors?.settingsThemeLightMode : colors?.settingsThemeDarkMode,
                backgroundColor: colors?.settingsThemeDarkMode
            }}
        >
            <BedtimeIcon sx={{ color: colors?.settingsDarkTheme }} /> 
        </Box>

        <Box
            onClick={() => handleTheme('light')}
            sx={{
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly', 
                width: 50,
                height: 50,
                marginLeft: 3,
                borderRadius: '50%',
                cursor: 'pointer',  
                borderStyle: 'solid',
                borderWidth: 2, 
                borderColor: isLightMode ? colors?.settingsThemeDarkMode : colors?.text, 
                backgroundColor: colors?.settingsThemeLightMode
            }}
        >
            <Brightness7Icon sx={{ color: colors?.settingsLightTheme }} /> 
        </Box>


    </Box>

  );

};

export default Theme;