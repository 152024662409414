import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components

import ListMetaHeader from './ListMetaHeader';
import Directory from './Directory';
import SearchBar from './SearchBar';

type Props = {
    children?: React.ReactNode;
};


const HeaderSection: React.FC<Props> = ({ children }) => {

    const dispatch = useAppDispatch(); 

    return (

        <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            width: '100%',
            height: '15%', 
            flexShrink: 0, 
        }}>

            <ListMetaHeader />

            <Box sx={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center', 
                width: '100%',
                height: '30%', 
                flexShrink: 0,
            }}>

                <Directory />

                <SearchBar />

            </Box>
            
        </Box>

    );

};

export default HeaderSection;