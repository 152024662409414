import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// Redux 

import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { setClosePopup } from '../../../../../../redux/features/general/popup';
import { setResetCreatePatient } from '../../../../../../redux/features/doctor/Dashboard/patients/createPatient';
import { setSection } from '../../../../../../redux/features/doctor/Dashboard/patients/patient';
import { useTranslation } from 'react-i18next';

type Props = {
    children?: React.ReactNode,
    isPortraitMode?: boolean,
};

const ExitProcess: React.FC<Props> = ({ children, isPortraitMode }) => {

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const colors = useAppSelector(state => state.theme.colors);


    const cancelPatientCreation = () => { 

        dispatch(setResetCreatePatient()); 

        dispatch(setSection('patient')); 

        dispatch(setClosePopup()); 

    }; 

    const goBackToPatientCreation = () => { 

        dispatch(setClosePopup()); 

    }; 


  return (
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%',
        borderRadius: 5,
        backgroundColor: colors?.container,
        overflowY: 'auto',
        overflowX: 'hidden',
    }}>

        <Box>{t('areYouSure')}</Box>

        <Box
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                width: '100%'}}>
        <Button 
          sx={{ 
            width: 200,
            backgroundColor: colors?.exitButtonColor,
            borderColor: colors?.exitButtonColor,
            color: 'white',
            marginBottom: 2,
            '&:hover': 
            { 
                backgroundColor: colors?.exitButtonColorHover,
                color: colors?.exitButtonText,
                borderColor: colors?.exitButtonColorHover
             }
          }}
          onClick={() => cancelPatientCreation()}
          variant="outlined">{t('exit')}</Button>
                  <Button 
          sx={{ 
            width: 200,
            backgroundColor: colors?.cancelButtonColor, 
            borderColor: colors?.text,
            color: colors?.text,
            '&:hover': 
            { 
                backgroundColor: colors?.cancelButtonBackground,
                color: colors?.text,
                borderColor: colors?.cancelButtonBackground
             }
          }}
          onClick={() => goBackToPatientCreation()}
          variant="outlined">{t('back')}</Button>
          </Box>

    </Box>
  );
};

export default ExitProcess;