import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';

// Redux 

import { setDashboardSection } from '../../../../redux/features/general/navigation';
import { setHackers, setInitialRendering, setIsRefreshHackersList } from '../../../../redux/features/hacker/users/hackersAccess';

// Routes 

import { getHackers } from '../../../../routes/hacker/users';

// Components 

import HackerRow from './HackerRow';
import ListHeader from './ListHeader'; 

// Icons 

import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LinearProgress from '@mui/material/LinearProgress';

const HackersList: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const hackers = useAppSelector(state => state.hackersAccess.hackers); 
    const initialRendering = useAppSelector(state => state.hackersAccess.initialRendering); 
    const isRefreshHackersList = useAppSelector(state => state.hackersAccess.isRefreshHackersList); 

    // -- 

    const getHackersList = async () => { 

        const res = await getHackers() as any; 

        if (res.data.status === 200) { 

            dispatch(setHackers(res.data.hackers)); 

        }; 

    }; 

    // -- 

    const changeSection = () => { 

        dispatch(setDashboardSection('createHacker')); 
  
      }; 
  
    // --

    useEffect(() => { 

        if (initialRendering) { 
  
            getHackersList(); 
  
            dispatch(setInitialRendering(false)); 
  
        }; 
  
    },[initialRendering]); 
  
      // --
  
      useEffect(() => { 
  
        if (isRefreshHackersList) { 
  
          getHackersList(); 
  
          dispatch(setIsRefreshHackersList(false)); 
  
        }; 
  
      },[isRefreshHackersList]); 

    // -- 

    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '95vw',
              backgroundColor: '#383A40',
            }}>            
            
                <ListHeader />

                { hackers?.length >= 1 && 
                <Box sx={{ 
                    overflow: 'auto',
                    width: '100%',
                    marginTop: '2vh' }}>
            
                    <List dense>
            
                    {hackers?.map((item, index) => (
            
                        <ListItem button key={index}>

                            <HackerRow 
                                firstName={item.firstName}
                                lastName={item.lastName}
                                email={item.email}
                                main={item.main}
                                createdAt={moment.utc(item.createdAt).format('LL')}
                            />  
        
                        </ListItem>
                    ))}
            
                    </List>
            
                </Box>}

                {hackers?.length === 0 &&         
          
                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly', 
                    height: '100%',
                    width: '80%', 
                }}>

                    <Box
                        sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>

                        <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                        <Box>Retrieving Hackers...</Box>

                    </Box>

                </Box>}

                <AddCircleIcon 
                    onClick={changeSection}
                    sx={{ 
                    width: 70,
                    height: 70, 
                    color: 'rgb(45, 207, 196)', 
                    position: 'absolute', 
                    bottom: 30, 
                    right: 30, 
                    cursor: 'pointer',
                    '&:hover' : { color: 'rgb(65, 227, 216)'}
                    }}
                />
    
            </Box>

        </Box>
    
      );

};

export default HackersList;