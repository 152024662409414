import React, { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Routes

import { getPatientNotes } from '../../../../../../../routes/doctor/patients';


// Components 

import Box from '@mui/material/Box';
import Note from './Note';
import CreateNote from './CreateNote';
import { setDropdownOpened, setIsRefreshNotes } from '../../../../../../../redux/features/doctor/Dashboard/patients/patientNote';

const Notes: React.FC = () => {

    const { t } = useTranslation(); 
    const dispatch = useAppDispatch(); 

    const notesRef = useRef() as any; 

    const patient = useAppSelector(state => state.patient.patient);
    const isRefreshNotes = useAppSelector(state => state.patientNote.isRefreshNotes); 
    const dropwdownOpened = useAppSelector(state => state.patientNote.dropdownOpened); 

    const [notes, setNotes] = useState([]) as any; 

    // -- 

    const handleNotes = async () => { 

        const res = await getPatientNotes(patient._id); 

        if (res?.data.status === 200) { 

            setNotes(res.data.notes); 

        }; 

    }; 

    // --

    const handleDropdown = (e: any) => { 

        if (e.button == 2) { 

            if (dropwdownOpened) { 

                dispatch(setDropdownOpened('')); 

            }; 

        }; 

    }; 

    // -- 

    useEffect(() => { 

        if (patient) { 

            handleNotes(); 

        }; 

    },[]); 

    // -- 

    useEffect(() => {

        if (isRefreshNotes) { 

            handleNotes(); 

            dispatch(setIsRefreshNotes(false)); 

        }; 

    },[isRefreshNotes]); 

    // -- 

    return (

    <Box
        ref={notesRef}
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: '2rem',
            height: '90vh',
            overflow: 'auto',
            alignItems: 'center',
            scrollbarWidth: 'none',
        }}
        onMouseDown={handleDropdown} 
    >
        <CreateNote /> 

        {notes?.map((item, index) => (

            <Note 
                patientNoteId={item._id} 
                doctorId={item.doctorId} 
                description={item.description} 
                updatedAt={item.updatedAt} 
                createdAt={item.createdAt}
            /> 

        ))}

    </Box>

  );
};

export default Notes;