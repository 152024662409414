import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

// Routes
import { createSupportEmail } from '../../../../routes/support/support';

// Redux
import { setResetSupportEmail } from '../../../../redux/features/support/supportEmail';
import { setNotificationMessage } from '../../../../redux/features/general/notification';

const SubmitButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const category = useAppSelector(state => state.supportEmail.category); 
  const description = useAppSelector(state => state.supportEmail.description); 

  const [allowSupportEmailCreation, setSupportEmailCreation] = useState(false); 

  useEffect(() => { 
    if (category && description) { 
      setSupportEmailCreation(true); 
    } else { 
      setSupportEmailCreation(false); 
    }
  }, [category, description]); 

  // --
  const handleCreateSupportEmail = async () => { 
    const res = await createSupportEmail(category, description) as any; 
    if (res.status === 200) { 
      // Redux is reset.
      dispatch(setResetSupportEmail()); 
      dispatch(setNotificationMessage(t('supportSuccess')));
    } 
  }; 

  // --
  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '5vh',
      height: '5vh',
      marginBottom: '3vh',
    }}>
      {allowSupportEmailCreation ? 
        <Button onClick={handleCreateSupportEmail} variant="contained">
          {t('createTicket')}
        </Button> 
        : 
        <Button 
          disabled={true}
          sx={{ 
            backgroundColor: 'transparent',
            borderColor: 'lightblue',
          }}
          variant="outlined">
          {t('createTicket')}
        </Button>
      }
    </Box>
  );
};

export default SubmitButton;