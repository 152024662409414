// Doctor Sidebar menu

import darkTheme from '../../../../../../../styles/themes/darkTheme';
import lightTheme from '../../../../../../../styles/themes/lightTheme';

const savedTheme = localStorage.getItem('theme');

const currentTheme = (savedTheme === 'dark') ? darkTheme : lightTheme as any; 

const inputPropsStyle = {
    style: {fontSize: 15, height: '100%', placeholderColor: currentTheme?.highlightedText, color: currentTheme?.highlightedText },
    disableUnderline: true, 
}

export default inputPropsStyle; 