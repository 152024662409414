import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';

// Icons 

import MoreVertIcon from '@mui/icons-material/MoreVert';
import getStatusColor from '../../../util/style/getStatusColor';

type Props = {
  firstName?: string,
  lastName?: string,
  email: string,
  team?: string,
  createdAt?: string, 
  accountStatus?: string, 
};


const DoctorRow: React.FC<Props> = ({firstName, lastName, accountStatus, email, team, createdAt }) => {

  return (

    <Box style={{ 
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center', 
      width: '100%',
      fontSize: 12, 
      height: 50,
    }}>

            <div style={{ 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                width: '13%',
                marginLeft: '2%',  
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>{firstName} {lastName}</div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden',
            }}>
                <strong style={{ 
                    padding: 5,
                    borderRadius: 7,
                    backgroundColor: getStatusColor(accountStatus), 
                }}>
                    {accountStatus}
                </strong>
            </div>

            <div style={{ 
                width: '20%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{email}</div>

            <div style={{ 
                width: '35%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>{createdAt}</div>

            <div style={{ 
                width: '10%', 
                color: '#C2C5C8', 
                textAlign: 'left',
                overflow: 'hidden', 
            }}>
                <Box
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'row',
                    alignItems: 'center', 
                    justifyContent: 'space-evenly', 
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    '&:hover': { backgroundColor: 'rgba(45, 153, 207, 0.6)'}
                  }}
                >
                  <MoreVertIcon></MoreVertIcon>
                </Box>

            </div>


    </Box>

  );

};

export default DoctorRow;