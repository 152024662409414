import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// Components 


const UpdateTeam: React.FC = () => {

  return (

    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column-reverse',
        alignItems: 'center', 
        width: '95%',
        height: '45%', 
        marginTop: '10%',
    }}>

      <Button 
        variant="contained" 
        sx={{ 
          width: '90%',
          backgroundColor: 'rgba(10, 197, 207, 0.4)',
          height: '5vh',
          marginBottom: 4,
         }}>Update Team</Button>

    </Box>

  );

};

export default UpdateTeam; 