import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useAppSelector, useAppDispatch } from './../../../../../../../redux/hooks';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

// Routes

import { createNote } from './../../../../../../../routes/admin/documents';

// Redux 

import { addTag, removeTag, resetCreateNote, setDescription } from './../../../../../../../redux/features/admin/dashboard/documents/createNote';
import { setNotificationMessage } from './../../../../../../../redux/features/general/notification';
import { refreshNotes } from './../../../../../../../redux/features/admin/dashboard/documents/refreshNotes';

// Icons 

import PersonIcon from '@mui/icons-material/Person';
import RateReviewIcon from '@mui/icons-material/RateReview';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';

const CreateNote: React.FC= () => {

    const dispatch = useAppDispatch(); 


    const note = useAppSelector(state => state.adminCreateNote); 
    const author = useAppSelector(state => state.adminCreateNote.author); 
    const tags = useAppSelector(state => state.adminCreateNote.tags); 
    const description = useAppSelector(state => state.adminCreateNote.description);

    const [allowTeamCreation, setAllowTeamCreation] = useState(false); 
    const [text, setText] = useState(''); 

    const closeNote = () => { 

        dispatch(resetCreateNote()); 

    }; 

    const addTagToNote = (tag: string) => { 

        dispatch(addTag(tag)); 

    };

    const removeTagToNote = (tag: string) => { 

        dispatch(removeTag(tag)); 

    }; 

    const handleCreateNote = async () => { 

        if (tags.length >= 1 || description.length >= 3) { 

            const status = await createNote(note); 

            if (status === 200) { 

                dispatch(setNotificationMessage('Note successfully created')); 
                dispatch(resetCreateNote()); 

                dispatch(refreshNotes(true)); 

            }; 

        }; 

    }; 


    useEffect(() => { 

        dispatch(setDescription(text)); 

    },[text]);

    // -- 

    useEffect(() => { 

        if (tags.length >= 1 || description.length >= 3) { 

            setAllowTeamCreation(true); 

        } else { 

            setAllowTeamCreation(false); 

        }; 

    },[tags, description]); 


    return (

    <div 

    style={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center', 
        justifyContent: 'space-evenly',
        width: '90vw',
        height: '95vh',
        bottom: 0,
        right: 0,
        position: 'absolute', 
        zIndex: 1,
        backgroundColor: 'rgba(56, 58, 64, 0.75)'
    }}>


        <div style={{ 
            display: 'flex', 
            flexDirection: 'column',
            width: '50%',
            height: '50%',
            borderRadius: 10,
            zIndex: 2,
            backgroundColor: '#313338',
            overflow: 'auto',
            scrollbarWidth: 'none',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                marginTop: '3vh',
                marginBottom: '2vh', 
                marginLeft: '1vw', 
            }}>Author</div>

            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#C2C5C8',
                fontSize: 15,
                marginLeft: '1vw', 
            }}>

                <PersonIcon sx={{ color: 'lightblue', marginRight: '1vw' }}/> 

                <div>{author}</div>

            </div>




            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                marginTop: '3vh',
                marginBottom: '2vh', 
                marginLeft: '1vw', 
            }}>Add Tags #</div>


            { (!tags.includes('review') || !tags.includes('revoked')) &&
            
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: '2vh', 
                marginLeft: '1vw', 
            }}>

                {!tags?.includes('review') &&

                <Box onClick={() => addTagToNote('review')}
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#383A40', 
                    borderRadius: 10, 
                    fontSize: 11, 
                    marginRight: '1vw', 
                    cursor: 'pointer',
                    padding: 1,
                    paddingLeft: 2, 
                    paddingRight: 2, 
                    '&:hover': {
                        backgroundColor: '#4D4B59',
                    },
                }}>

                    <RateReviewIcon sx={{ color: 'purple', marginRight: '1vw' }} />

                    <div>Request Review</div>

                </Box>}


                {!tags?.includes('revoked') &&
                <Box onClick={() => addTagToNote('revoked')}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: '#383A40', 
                    borderRadius: 10, 
                    fontSize: 11, 
                    marginRight: '1vw', 
                    cursor: 'pointer',
                    padding: 1,
                    paddingLeft: 2, 
                    paddingRight: 2, 
                    '&:hover': {
                        backgroundColor: '#4D4B59',
                    },
                }}>

                    <RemoveCircleOutlineIcon sx={{ color: 'red', marginRight: '1vw' }} />

                    <div>Revoke Document</div>

                </Box>}

            </div>}


            {tags?.includes('review') &&
            
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#C2C5C8',
                fontSize: 15,
                marginLeft: '1vw', 
                marginTop: '3vh',
                cursor: 'pointer'
            }}>

                <RateReviewIcon sx={{ color: 'purple', marginRight: '1vw' }} />

                <div>Requested Review</div>

                <ClearIcon 
                    onClick={() => removeTagToNote('review')}
                    sx={{ color: 'grey', 
                        borderRadius: '50%',
                        marginLeft: '1vw', 
                        '&:hover': {
                        backgroundColor: 'rgba(212, 212, 212, 0.3)',
                    }, }} /> 

            </div>}

            {tags?.includes('revoked') &&
            
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: '#C2C5C8',
                fontSize: 15,
                marginLeft: '1vw', 
                marginTop: '3vh',
                cursor: 'pointer',
            }}>

                <RemoveCircleOutlineIcon sx={{ color: 'red', marginRight: '1vw' }} />

                <div>Revoked Document</div>

                <ClearIcon 
                    onClick={() => removeTagToNote('revoked')}
                    sx={{ color: 'grey', 
                        borderRadius: '50%',
                        marginLeft: '1vw', 
                        '&:hover': {
                        backgroundColor: 'rgba(212, 212, 212, 0.3)',
                    }, }} /> 

            </div>}

            {tags?.length === 0 &&
            
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                fontSize: 14,
                marginTop: '3vh',
                marginBottom: '3vh', 
                marginLeft: '1vw', 
                fontStyle: 'italic', 
                color: '#C2C5C8',
            }}>No tags yet.</div>}


            {/* <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                color: 'white',
                marginTop: '3vh',
                marginBottom: '2vh', 
                marginLeft: '1vw', 
            }}>Add a description</div> */}

            <TextField
                sx={{
                    marginLeft: '1vw', 
                    width: '90%',
                    marginTop: '3vh',
                    borderRadius: 10, 
                }}
                onChange={(e) => setText(e.target.value)}
                id="outlined-multiline-static"
                label='Add a description'
                multiline
                value={text}
                rows={4}
            />

            <Box sx={{ 
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: '3vh',
                marginLeft: '1vw', 
                height: '5vh',
                marginBottom: '3vh',
            }}>

            { allowTeamCreation ? 
                <Button onClick={() => handleCreateNote()} variant="contained">Create</Button>: 
                <Button 
                disabled={true}
                sx={{ 
                    backgroundColor: 'transparent',
                    borderColor: 'lightblue',
                }}
                variant="outlined">Create</Button>}

            </Box>

        </div>

        
        <div 
            onClick={closeNote}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            left: 0,
        }}></div>

        <div 
            onClick={closeNote}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            top: 0,
        }}></div>

        <div 
            onClick={closeNote}
            style={{ 
            width: '25%',
            height: '100%',
            position: 'absolute', 
            right: 0,
        }}></div>

        <div 
            onClick={closeNote}
            style={{ 
            width: '50%',
            height: '25%',
            position: 'absolute', 
            bottom: 0,
        }}></div>


    </div>

    );

};

export default CreateNote;