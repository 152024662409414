import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';

// Components 

// Icons 

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import LinearProgress from '@mui/material/LinearProgress';
import DataRow from './DataRow';
import Legend from './Legend';

const Table: React.FC = () => {

    const { t } = useTranslation();
    
    const dispatch = useAppDispatch();

    const securityData = useAppSelector((state) => state.hackerData.securityData); 

    return (

        <Box>

            <Box sx={{ 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '95vh',
              marginTop: '5vh',
              width: '95vw',
              backgroundColor: '#383A40',
            }}>

                <Legend />

                { securityData?.length >= 1 ?
                <Box sx={{ 
                    overflow: 'auto',
                    width: '100%',
                    marginTop: '2.5vh',
                    height: '82.5' }}>
            
                    <List dense>
            
                    {securityData?.map((item, index) => (
            
                        <ListItem button key={index}>

                            <DataRow
                                category={item.category}
                                lastDay={item.lastDay}
                                variation={item.variation}
                                average={item.average}
                                total={item.total}
                            /> 
        
                        </ListItem>
                    ))}
            
                    </List>
            
                </Box>: 

                <Box sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly', 
                    height: '100%',
                    width: '80%', 
                }}>

                    <Box
                        sx={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>

                        <LinearProgress sx={{ width: 500, marginBottom: 10 }} /> 

                        <Box>Retrieving the monitoring data...</Box>

                    </Box>



                </Box>}

                



            </Box>


        </Box>
    
      );

};

export default Table;