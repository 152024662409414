import React from 'react';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from './../../../../redux/hooks';
import { useTranslation } from 'react-i18next';

// Components 

import TextField from '@mui/material/TextField';

interface Props { 
    title?: string
}

const SupportTicketHeader: React.FC<Props> = ({title}) => {

  const { t } = useTranslation();

  const colors = useAppSelector(state => state.theme.colors);

  return (

    <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '7.5vh',
        width: '100%',
        paddingLeft: '1%',
        backgroundColor: colors?.supportHistoryHeader,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
        padding: '0 1vw',
    }}>

        <>

        <div style={{ width: '13%', marginLeft: '2%' }}>{t('user')}</div>
        <Box sx={{ width: '15%' }}>{t('ticketId')}</Box>
        <Box sx={{ width: '15%' }}>{t('status')}</Box>
        <Box sx={{ width: '15%' }}>{t('email')}</Box>
        <Box sx={{ width: '15%' }}>{t('category')}</Box>
        <Box sx={{ width: '15%' }}>{t('since')}</Box>
        <Box sx={{ width: '10%', textAlign: 'center' }}>{t('more')}</Box>

        </>


    </Box>

  );

};

export default SupportTicketHeader;